.engineRoot {
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;

  .engineer_proj {
    height: auto;
    background-color: black;
  }
  .strategy {
    height: auto;
    padding-bottom: 79px;
    background-color: black;
  }
  .strategy_light_1 {
    margin: auto;
    background-color: white;
  }
  .strategy_text_chapter {
    padding-top: 112px;
    letter-spacing: 5.4px;
    font-size: 27px;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 600;
    color: #5d5d5d;
    max-width: 346px;
    margin: 0 auto;
    opacity: 0.09;
    text-align: center;
  }
  .strategy_01 {
    padding-top: 12px;
    letter-spacing: 11.6px;
    font-size: 116px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 126px;
    margin: 0 auto 12px 15.6%;
  }
  .strategy_palochka_01 {
    width: 175px;
    height: 8px;
    background-color: black;
    border-radius: 4px;
    margin: 0 auto 0 15.6%;
  }
  .strategy_chapter_01 {
    letter-spacing: 4px;
    font-size: 30px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 175px;
    margin: 29px auto 0 15.6%;
    padding-bottom: 74px;
  }
  .strategy_dark_01 {
    background-color: #101113;
  }
  .strategy_foto_1 {
    width: 100%;
    height: auto;
  }
  .strategy_foto_1_1 {
    display: none;
  }
  .strategy_text_01 {
    padding: 100px 9.7% 74px 9.7%;
    color: white;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
  }
  .strategy_text_01_1 {
    padding: 0px 9.7% 100px 9.7%;
    color: white;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
  }
  .strategy_light_2 {
    position: relative;
    margin: auto;
    background-color: white;
  }
  .strategy_02 {
    letter-spacing: 11.6px;
    font-size: 116px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 126px;
    margin: 0px auto 12px 15.6%;
    padding-top: 51px;
  }
  .strategy_palochka_02 {
    width: 195px;
    height: 8px;
    background-color: black;
    border-radius: 4px;
    margin: 0 auto 0 15.6%;
  }
  .strategy_chapter_02 {
    letter-spacing: 4px;
    font-size: 30px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 195px;
    margin: 29px auto 0 15.6%;
    padding-bottom: 74px;
  }
  .strategy_foto_2 {
    width: 100%;
    height: auto;
  }
  .strategy_text_02 {
    padding: 100px 9.7% 100px 9.7%;
    color: white;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
  }
  .strategy_dark_3 {
    margin: auto;
  }
  .strategy_03 {
    padding-top: 51px;
    letter-spacing: 11.6px;
    font-size: 116px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 126px;
    margin: 0 auto 12px 15.6%;
    color: white;
  }
  .strategy_palochka_03 {
    width: 185px;
    height: 8px;
    background-color: white;
    border-radius: 4px;
    margin: 0 auto 0 15.6%;
  }
  .strategy_chapter_03 {
    letter-spacing: 4px;
    font-size: 30px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 185px;
    margin: 33px auto 0 15.6%;
    color: white;
    padding-bottom: 74px;
  }
  .strategy_light_03 {
    position: relative;
    background-color: white;
  }
  .strategy_foto_3 {
    width: 100%;
    height: auto;
  }
  .strategy_text_03 {
    padding: 100px 9.7% 100px 9.7%;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
  }
  .strategy_text_03_1 {
    padding: 0px 9.7% 100px 9.7%;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
  }
  .strategy_dark_4 {
    margin: auto;
  }
  .strategy_04 {
    padding-top: 51px;
    letter-spacing: 11.6px;
    font-size: 116px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 126px;
    margin: 0 auto 12px 15.6%;
    color: white;
  }
  .strategy_palochka_04 {
    width: 235px;
    height: 8px;
    background-color: white;
    border-radius: 4px;
    margin: 0 auto 0 15.6%;
  }
  .strategy_chapter_04 {
    letter-spacing: 4px;
    font-size: 30px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 235px;
    margin: 33px auto 12px 15.6%;
    color: white;
    padding-bottom: 74px;
  }
  .strategy_foto_4 {
    width: 100%;
    height: auto;
  }
  .strategy_text_04 {
    padding: 100px 9.7% 100px 9.7%;
    color: white;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
  }
  .strategy_text_04_1 {
    padding: 0px 9.7% 100px 9.7%;
    color: white;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
  }
  .strategy_light_5 {
    margin: auto;
    background-color: white;
  }
  .strategy_05 {
    padding-top: 51px;
    letter-spacing: 11.6px;
    font-size: 116px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 126px;
    margin: 0 auto 12px 15.6%;
  }
  .strategy_palochka_05 {
    width: 220px;
    height: 8px;
    background-color: black;
    border-radius: 4px;
    margin: 0 auto 0 15.6%;
  }
  .strategy_chapter_05 {
    letter-spacing: 4px;
    font-size: 30px;
    font-family: "Gilroy-Medium", sans-serif;
    max-width: 220px;
    margin: 33px auto 0 15.6%;
    padding-bottom: 74px;
  }
  .strategy_foto_5 {
    width: 100%;
    height: auto;
  }
  .strategy_text_05 {
    padding: 100px 9.7% 100px 9.7%;
    color: white;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
  }
  .strategy_text_05_1 {
    padding: 100px 9.7% 100px 9.7%;
    color: white;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
  }
  .strategy_text {
    padding-top: 60px;
    padding-bottom: 25px;
    color: white;
    letter-spacing: 2.3px;
    font-size: 23px;
    font-family: "Gilroy-Light", sans-serif;
    font-weight: 300;
    width: 250px;
    margin: 0 auto;
    text-align: center;
  }
  .strategy_light_palochka {
    width: 80%;
    height: 4px;
    border-radius: 2px;
    background-color: white;
    margin: 0 auto;
  }
  .strategy_btn_write_us {
    display: block;
    color: black;
    background-color: white;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Medium", sans-serif;
    font-weight: 600;
    border-radius: 40px;
    border: 0;
    padding: 0;
    width: 61.2%;
    height: 60px;
    margin: 25px auto 0 auto;
    cursor: pointer;
  }

  @media (min-width: 430px) {
    .strategy_palochka_01 {
      width: 225px;
    }
    .strategy_chapter_01 {
      font-size: 40px;
      font-family: "Gilroy-Medium", sans-serif;
      font-weight: 600;
      max-width: 225px;
    }
    .strategy_palochka_02 {
      width: 250px;
    }
    .strategy_chapter_02 {
      font-size: 40px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 250px;
    }
    .strategy_palochka_03 {
      width: 234px;
    }
    .strategy_chapter_03 {
      font-size: 40px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 234px;
    }
    .strategy_palochka_04 {
      width: 300px;
    }
    .strategy_chapter_04 {
      font-size: 40px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 300px;
    }
    .strategy_palochka_05 {
      width: 282px;
    }
    .strategy_chapter_05 {
      font-size: 40px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 282px;
    }
  }

  @media (min-width: 768px) {
    .strategy_text_chapter {
      font-size: 42px;
      max-width: none;
    }

    .strategy_text_01 {
      font-size: 20px;
      letter-spacing: 2.1px;
    }
    .strategy_text_01_1 {
      font-size: 20px;
      letter-spacing: 2.1px;
    }
    .strategy_text_02 {
      letter-spacing: 2.1px;
      font-size: 20px;
    }
    .strategy_text_03 {
      letter-spacing: 2.1px;
      font-size: 20px;
    }
    .strategy_text_03_1 {
      letter-spacing: 2.1px;
      font-size: 20px;
    }

    .strategy_text_04 {
      letter-spacing: 2.1px;
      font-size: 20px;
    }
    .strategy_text_04_1 {
      letter-spacing: 2.1px;
      font-size: 20px;
    }
    .strategy_text_05 {
      letter-spacing: 2.1px;
      font-size: 20px;
    }
    .strategy_text_05_1 {
      letter-spacing: 2.1px;
      font-size: 20px;
    }

    .strategy_foto_1,
    .strategy_foto_2,
    .strategy_foto_3,
    .strategy_foto_4,
    .strategy_foto_5 {
      height: 50vh;
      object-fit: cover;
    }
    .strategy_foto_1 {
      object-position: 0 -280px;
    }
    .strategy_btn_write_us {
      font-size: 20px;
      width: 42vw;
    }
    .strategy_text {
      width: auto;
    }
  }

  @media (min-width: 1280px) {
    .strategy {
      padding-bottom: 1px;
    }
    .strategy_text_chapter {
      padding-top: 122px;
      letter-spacing: 11.4px;
      font-size: 56px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 700px;
      margin: 0 auto 0 7.76%;
    }
    .strategy_01 {
      padding-top: 146px;
      letter-spacing: 15.8px;
      font-size: 128px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 26.25%;
    }
    .strategy_palochka_01 {
      width: 360px;
      height: 12px;
      background-color: black;
      margin: 0 auto 0 26.25%;
    }
    .strategy_chapter_01 {
      letter-spacing: 6.4px;
      font-size: 64px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 360px;
      margin: 43px auto 0 26.5%;
    }
    .strategy_dark_01 {
      background-color: #0f1012;
      height: 100vh;
    }
    .strategy_foto_1 {
      display: none;
    }
    .strategy_foto_1_1 {
      display: block;
      width: 100%;
    }
    .strategy_text_01 {
      position: relative;
      top: -800px;
      padding: 93px 4.375% 130px 35vw;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text_01_1 {
      position: relative;
      top: -800px;
      padding: 100px 4.375% 0px 35vw;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_02 {
      padding-top: 267.5px;
      letter-spacing: 15.8px;
      font-size: 128px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 55.14%;
    }
    .strategy_palochka_02 {
      width: 400px;
      height: 12px;
      margin: 0 auto 0 55.14%;
    }
    .strategy_chapter_02 {
      letter-spacing: 6.4px;
      font-size: 64px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 400px;
      margin: 43px auto 0 55.14%;
    }
    .strategy_foto_2 {
      height: 80.74vh;
      object-fit: cover;
    }
    .strategy_text_02 {
      padding: 230px 13.38% 0px 13.38%;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_03 {
      padding-top: 267.5px;
      letter-spacing: 15.8px;
      font-size: 128px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 22.92%;
    }
    .strategy_palochka_03 {
      width: 380px;
      height: 12px;
      margin: 0 auto 0 22.92%;
    }
    .strategy_chapter_03 {
      letter-spacing: 6.4px;
      font-size: 64px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 380px;
      margin: 43px auto 0 22.92%;
    }
    .strategy_foto_3 {
      height: 82.22vh;
      object-fit: cover;
    }
    .strategy_text_03 {
      padding: 117.5px 41.97% 119px 9.47%;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text_03_1 {
      padding: 0 8.59% 80px 35vw;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_04 {
      padding-top: 267.5px;
      letter-spacing: 15.8px;
      font-size: 128px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 55.14%;
    }
    .strategy_palochka_04 {
      width: 480px;
      height: 12px;
      border-radius: 4px;
      margin: 0 auto 0 55.14%;
    }
    .strategy_chapter_04 {
      letter-spacing: 6.4px;
      font-size: 64px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 480px;
      margin: 43px auto 0 55.14%;
    }
    .strategy_foto_4 {
      height: 79.25vh;
      object-fit: cover;
    }
    .strategy_text_04 {
      padding: 116px 41.97% 116px 9.47%;
      color: white;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text_04_1 {
      padding: 0 8.59% 114px 35vw;
      color: white;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_light_5 {
      background-color: white;
    }
    .strategy_05 {
      padding-top: 267.5px;
      letter-spacing: 15.8px;
      font-size: 128px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 22.92%;
    }
    .strategy_palochka_05 {
      width: 450px;
      height: 12px;
      border-radius: 4px;
      margin: 0 auto 0 22.92%;
    }
    .strategy_chapter_05 {
      letter-spacing: 6.4px;
      font-size: 64px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 450px;
      margin: 43px auto 0 22.92%;
    }
    .strategy_foto_5 {
      height: 82.22vh;
      object-fit: cover;
    }
    .strategy_text_05 {
      padding: 77.5px 5vw 94.5px 35vw;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text_05_1 {
      padding: 0 30vw 94.5px 10vw;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text {
      font-size: 34px;
      font-family: "Gilroy-Light", sans-serif;
      font-weight: 300;
    }
    .strategy_light_palochka {
      width: 60vw;
      height: 8px;
    }
    .strategy_btn_write_us {
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Medium", sans-serif;
      width: 35vw;
      height: 90px;
      margin: 50px auto 120px auto;
    }
  }

  @media (min-width: 1920px) {
    .strategy {
      padding-bottom: 1px;
    }
    .strategy_text_chapter {
      padding-top: 124px;
      letter-spacing: 17.4px;
      font-size: 87px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 1115px;
      margin: 0 auto 0 7.76%;
    }
    .strategy_01 {
      padding-top: 176px;
      letter-spacing: 15.8px;
      font-size: 158px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 26.25%;
    }
    .strategy_palochka_01 {
      width: 405px;
      height: 12px;
      background-color: black;
      margin: 0 auto 0 26.25%;
    }
    .strategy_chapter_01 {
      letter-spacing: 7.2px;
      font-size: 72px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 405px;
      margin: 43px auto 0 26.5%;
    }
    .strategy_dark_01 {
      background-color: #0f1012;
      height: 75vh;
    }
    .strategy_foto_1 {
      display: none;
    }
    .strategy_foto_1_1 {
      display: block;
    }
    .strategy_text_01 {
      position: relative;
      top: -1480px;
      padding: 187px 4.375% 300px 35vw;
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text_01_1 {
      position: relative;
      top: -1580px;
      padding: 0px 4.375% 0px 35vw;
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_02 {
      padding-top: 335px;
      letter-spacing: 15.8px;
      font-size: 158px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 61.14%;
    }
    .strategy_palochka_02 {
      width: 450px;
      height: 12px;
      margin: 0 auto 0 61.14%;
    }
    .strategy_chapter_02 {
      letter-spacing: 7.2px;
      font-size: 72px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 450px;
      margin: 43px auto 0 61.14%;
    }
    .strategy_foto_2 {
      height: 80.74vh;
      object-fit: cover;
    }
    .strategy_text_02 {
      padding: 230px 13.38% 0px 13.38%;
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_03 {
      padding-top: 335px;
      letter-spacing: 15.8px;
      font-size: 158px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 22.92%;
    }
    .strategy_palochka_03 {
      width: 421px;
      height: 12px;
      margin: 0 auto 0 22.92%;
    }
    .strategy_chapter_03 {
      letter-spacing: 7.2px;
      font-size: 72px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 421px;
      margin: 43px auto 0 22.92%;
    }
    .strategy_foto_3 {
      height: 82.22vh;
      object-fit: cover;
    }
    .strategy_text_03 {
      padding: 235px 41.97% 238px 9.47%;
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text_03_1 {
      padding: 0 8.59% 160px 35vw;
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_04 {
      padding-top: 335px;
      letter-spacing: 15.8px;
      font-size: 158px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 61.14%;
    }
    .strategy_palochka_04 {
      width: 541px;
      height: 12px;
      border-radius: 4px;
      margin: 0 auto 0 61.14%;
    }
    .strategy_chapter_04 {
      letter-spacing: 7.2px;
      font-size: 72px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 541px;
      margin: 43px auto 0 61.14%;
    }
    .strategy_foto_4 {
      height: 79.25vh;
      object-fit: cover;
    }
    .strategy_text_04 {
      padding: 232px 41.97% 232px 9.47%;
      color: white;
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text_04_1 {
      padding: 0 9.47% 228px 35vw;
      color: white;
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_light_5 {
      background-color: white;
    }
    .strategy_05 {
      padding-top: 335px;
      letter-spacing: 15.8px;
      font-size: 158px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 171px;
      margin: 0 auto 15px 22.92%;
    }
    .strategy_palochka_05 {
      width: 421px;
      height: 12px;
      border-radius: 4px;
      margin: 0 auto 0 22.92%;
    }
    .strategy_chapter_05 {
      letter-spacing: 7.2px;
      font-size: 72px;
      font-family: "Gilroy-Medium", sans-serif;
      max-width: 421px;
      margin: 43px auto 0 22.92%;
    }
    .strategy_foto_5 {
      height: 82.22vh;
      object-fit: cover;
    }
    .strategy_text_05 {
      padding: 155px 5vw 189px 35vw;
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text_05_1 {
      padding: 0 35vw 189px 10vw;
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .strategy_text {
      font-size: 38px;
      font-family: "Gilroy-Light", sans-serif;
      font-weight: 300;
    }
    .strategy_light_palochka {
      width: 60vw;
      height: 8px;
    }
    .strategy_btn_write_us {
      letter-spacing: 4.2px;
      font-size: 38px;
      font-family: "Gilroy-Medium", sans-serif;
      width: 30vw;
      height: 96px;
      margin: 50px auto 150px auto;
    }
  }
}
