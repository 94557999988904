* {
    margin: 0;
    padding: 0;
  }  
  section {
    margin-top: 72px;
    min-height: calc(100vh - 90px);
    box-sizing: border-box;
    @media (min-width: 768px) {
        margin-top: 94px;
      }
  }
  
.App {
    display: flex;
    overflow-x: hidden;
    min-height: 100%;
    height: auto !important;
}
body {
    min-height: 100vh;
    margin: 0;
    width: calc(100vw - 22px);
  }
  
  body::after {
    content: '';
    display: block;
    height: 90px; 
  }