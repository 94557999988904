.root {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: black;
  opacity: 1;
  overflow: hidden;

  .title {
    height: 36px;
    box-sizing: border-box;
    color: #ffffff;
    opacity: 0.1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 29px;
    letter-spacing: 14.5px;
    font-weight: 600;
    font-family: "Gilroy-Bold", sans-serif;
    position: relative;
    margin: 0 auto;
    margin-top: 58px;

    @media (min-width: 768px) {
      margin-top: 59px;
      height: 30px;
      font-size: 24px;
      letter-spacing: 12px;
      font-weight: 400;
    }

    @media (min-width: 1280px) {
      margin-top: 80px;
      height: 53px;
      font-size: 43px;
      letter-spacing: 21.5px;
    }

    @media (min-width: 1920px) {
      height: 85px;
      font-size: 69px;
      letter-spacing: 34.5px;
    }
  }

  .subTitle {
    height: 43px;
    width: 246px;
    box-sizing: border-box;
    text-transform: uppercase;
    color: #ffffff;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    letter-spacing: 3.2px;
    font-weight: 600;
    font-family: "Gilroy-Medium", sans-serif;
    position: relative;
    margin: 0 auto;
    margin-top: 38px;
    text-align: center;
    line-height: 1.5;

    @media (min-width: 768px) {
      margin-top: 55px;
      width: 371px;
      height: 15px;
      font-size: 12px;
      letter-spacing: 2.4px;
    }

    @media (min-width: 1280px) {
      margin-top: 83px;
      width: 575px;
      height: 24px;
      font-size: 19px;
      letter-spacing: 3.8px;
    }

    @media (min-width: 1920px) {
      margin-top: 117px;
      width: 955px;
      height: 40px;
      font-size: 32px;
      letter-spacing: 6.4px;
    }
  }

  .line {
    width: 378px;
    height: 0px;
    border: 1px solid #ffffff;
    opacity: 1;
    border-radius: 50px;
    margin-top: 55px;

    @media (min-width: 768px) {
      margin-top: 14px;
      width: 426px;
    }
    @media (min-width: 1280px) {
      margin-top: 20px;
      width: 658px;
    }
    @media (min-width: 1920px) {
      margin-top: 27px;
      width: 981px;
    }
  }

  .btnConnect {
    margin-top: 32px;
    margin-bottom: 50px;

    button {
      width: 255px;
      height: 58px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #707070;
      border-radius: 43px;
      opacity: 1;

      text-align: center;
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 600;
      font-family: "Gilroy-Medium", sans-serif;
      letter-spacing: 1.5px;
      color: #000000;
      opacity: 1;

      @media (min-width: 768px) {
        width: 166px;
        height: 40px;
        font-size: 12px;
        letter-spacing: 1.2px;
      }

      @media (min-width: 1280px) {
        width: 256px;
        height: 60px;
        font-size: 16px;
        letter-spacing: 1.6px;
      }
      @media (min-width: 1920px) {
        width: 376px;
        height: 86px;
        font-size: 24px;
        letter-spacing: 2.4px;
      }

      &:hover {
        cursor: pointer;
      }
    }

    .tgContact {
      margin-top: 40px;
      text-align: center;

      @media (min-width: 768px) {
        display: inline;
        margin-top: 0px;
      }
    }
    .tgImg {
      width: 51px;
      height: 43px;
      cursor: pointer;

      @media (min-width: 1280px) {
        width: 71px;
        height: 63px;
      }

      @media (min-width: 1920px) {
        width: 101px;
        height: 93px;
      }
    }

    @media (min-width: 768px) {
      margin-top: 34px;
      margin-bottom: 97px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-content: space-between;
      align-items: center;
      column-gap: 9vw;
    }
    @media (min-width: 1280px) {
      margin-top: 52px;
      margin-bottom: 137px;
      column-gap: 8vw;
    }
    @media (min-width: 1920px) {
      margin-top: 77px;
      margin-bottom: 205px;
      column-gap: 6vw;
    }
  }

  .scrollToTop {
    display: none;

    @media (min-width: 768px) {
      display: block;
      position: absolute;
      bottom: 85px;
      right: 35px;
      cursor: pointer;
      background-color: transparent;
      border: none;

      .arrowTop {
        width: 39px;
        height: 18px;

        @media (min-width: 1280px) {
          width: 42px;
          height: 19px;
        }
        @media (min-width: 1920px) {
          width: 54px;
          height: 24px;
        }
      }
    }
  }
}
