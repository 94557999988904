.root {
  width: 100vw;
  height: auto;
  display: grid;
  grid-template-areas:
    "title"
    "countNumber"
    "textArea"
    "btnNav";
  box-sizing: border-box;
  background-color: white;
  opacity: 1;
  overflow: hidden;

  @media (min-width: 768px) {
    grid-template-areas:
      "title title title title"
      "btnPrev countNumber textArea btnNext";
    grid-auto-rows: min-content;
    grid-auto-columns: 100px min-content min-content 100px;
    justify-content: center;
  }
  @media (min-width: 1280px) {
    grid-auto-columns: 150px min-content min-content 150px;
  }
  @media (min-width: 1920px) {
    grid-auto-columns: 300px min-content min-content 300px;
  }

  .title {
    width: 349px;
    height: 39px;
    box-sizing: border-box;
    color: black;
    border: 4px solid #000000;
    border-radius: 6px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 10px;
    font-family: "Gilroy-Bold", sans-serif;
    position: relative;
    margin: 0 auto;
    margin-top: 31px;
    grid-area: title;

    @media (min-width: 768px) {
      margin-top: 87px;
      width: 460px;
      height: 54px;
      padding: 10px;
      font-size: 32px;
      letter-spacing: 10px;
    }
    @media (min-width: 1280px) {
      margin-top: 101px;
      width: 722px;
      height: 83px;
      padding: 20px;
      font-size: 52px;
      letter-spacing: 15px;
    }
    @media (min-width: 1920px) {
      margin-top: 84px;
      width: 1076px;
      height: 123px;
      font-size: 82px;
      letter-spacing: 20px;
    }
  }

  .counter {
    width: 210px;
    height: 204px;
    text-transform: uppercase;
    font-size: 168px;
    letter-spacing: 16.8px;
    font-family: "Gilroy-Medium", sans-serif;
    margin: 0 auto;
    margin-top: 67px;
    grid-area: countNumber;

    @media (min-width: 768px) {
      width: 92px;
      height: 88px;
      font-size: 72px;
      letter-spacing: 7.2px;
      margin-top: 97px;
      margin-left: 31px;
      font-weight: 600;
    }
    @media (min-width: 1280px) {
      width: 180px;
      height: 171px;
      font-size: 140px;
      letter-spacing: 14px;
      margin-top: 132px;
      font-weight: 600;
    }
    @media (min-width: 1920px) {
      width: 215px;
      height: 206px;
      font-size: 168px;
      letter-spacing: 16.8px;
      margin-top: 129px;
      margin-left: 71px;
    }

    .counter_line {
      margin: 0 auto;
      border: none;
      background-color: black;
      height: 11px;
      border-radius: 3px;

      @media (min-width: 768px) {
        height: 5px;
      }
      @media (min-width: 1280px) {
        height: 8px;
      }
      @media (min-width: 1920px) {
        height: 11px;
      }
    }
  }

  .text {
    width: 233px;
    height: 400px;
    text-align: left;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1.6px;
    font-family: "Gilroy-Medium", sans-serif;
    line-height: 35px;
    font-weight: 700;
    margin: 0 auto;
    margin-top: 75px;
    padding-left: 50px;
    grid-area: textArea;

    @media (min-width: 768px) {
      margin-left: 77px;
      margin-top: 115px;
      padding: 0px;
      width: 264px;
      height: 145px;
      font-size: 12px;
      letter-spacing: 1.2px;
      line-height: 25px;
    }

    @media (min-width: 1280px) {
      margin-left: 77px;
      margin-top: 160px;
      width: 457px;
      height: 237px;
      font-size: 20px;
      letter-spacing: 2px;
      line-height: 35px;
    }
    @media (min-width: 1920px) {
      margin-left: 77px;
      margin-top: 174px;
      width: 534px;
      height: 252px;
      font-size: 24px;
      letter-spacing: 2.4px;
      line-height: 2;
    }
  }

  .btnNav {
    margin-bottom: 80px;
    border: none;
    width: 100%;
    height: 73px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    grid-area: btnNav;

    @media (min-width: 768px) {
      display: none;
    }

    .btnNav_prev {
      width: 73px;
      height: 73px;
      border: 3px solid #000000;
      background-color: transparent;
      border-radius: 50%;
      margin-left: 87px;

      .btnNav_prev__symb {
        font-weight: bold;
        font-size: 28px;
        margin-left: 7px;
      }
      &:hover {
        transform: scale(1.1);
        transition: 300ms linear;
      }
    }

    .btnNav_next {
      width: 73px;
      height: 73px;
      border: 3px solid #000000;
      background-color: transparent;
      border-radius: 50%;
      margin-left: 87px;
      margin-right: 75px;

      .btnNav_next__symb {
        font-weight: bold;
        font-size: 28px;
        margin-left: 7px;
      }

      &:hover {
        transform: scale(1.1);
        transition: 300ms linear;
      }
    }
  }

  .btnPrev {
    display: none;

    @media (min-width: 768px) {
      display: block;
      margin-top: 134px;
      grid-area: btnPrev;
      width: 40px;
      height: 40px;
      border: 3px solid #000000;
      background-color: transparent;
      border-radius: 50%;
    }
    @media (min-width: 1280px) {
      margin-top: 195px;
      width: 64px;
      height: 64px;
    }
    @media (min-width: 1920px) {
      margin-top: 205px;
      width: 95px;
      height: 95px;
    }

    .btnNav_prev__symb {
      font-weight: bold;
      font-size: 20px;
      margin-left: 4px;

      @media (min-width: 1280px) {
        font-size: 28px;
      }
      @media (min-width: 1920px) {
        font-size: 38px;
      }
    }
    &:hover {
      transform: scale(1.1);
      transition: 300ms linear;
    }
  }

  .btnNext {
    display: none;

    @media (min-width: 768px) {
      display: block;
      margin-top: 134px;
      grid-area: btnNext;
      width: 40px;
      height: 40px;
      border: 3px solid #000000;
      background-color: transparent;
      border-radius: 50%;
      margin-left: 50px;
    }
    @media (min-width: 1280px) {
      margin-top: 195px;
      width: 64px;
      height: 64px;
      margin-left: 70px;
    }
    @media (min-width: 1920px) {
      margin-top: 205px;
      width: 95px;
      height: 95px;
      margin-left: 190px;
    }

    .btnNav_next__symb {
      font-weight: bold;
      font-size: 20px;
      margin-left: 4px;

      @media (min-width: 1280px) {
        font-size: 28px;
      }
      @media (min-width: 1920px) {
        font-size: 38px;
      }
    }
    &:hover {
      transform: scale(1.1);
      transition: 300ms linear;
    }
  }

  .noVisible {
    visibility: hidden;
  }

  .marginText {
    @media (min-width: 768px) {
      margin-top: 90px;
    }
    @media (min-width: 1280px) {
      margin-top: 135px;
    }
  }
}

.animationText {
  opacity: 0;
  animation: ani 0.6s forwards;
}

@keyframes ani {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0.35;
  }
  75% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
