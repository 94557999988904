.btn {
  width: 255px;
  height: 56px;
  box-sizing: border-box;
  padding-left: 6px;
  color: white;
  border: 2px solid #ffffff;
  border-radius: 9px;
  opacity: 1;
  background-color: transparent;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.1s ease-out;
  font-size: 16px;
  font-weight: 400;
  filter: brightness(1.1);
  letter-spacing: 1.6px;
  font-family: "Gilroy-Regular", sans-serif;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 8px 0 rgba(34, 60, 80, 0.5);
  margin-bottom: 16px;
  

  @media (min-width: 400px) {
    width: 336px;
  }

  @media (min-width: 768px) {
    width: 256px;
    height: 48px;
    font-size: 12px;
    letter-spacing: 1.2px;
    margin-bottom: 17px;
  }

  @media (min-width: 1280px) {
    width: 384px;
    height: 64px;
    font-size: 16px;
    letter-spacing: 1.6px;
    margin-bottom: 17px;
  }
  @media (min-width: 1920px) {
    width: 568px;
    height: 96px;
    font-size: 24px;
    letter-spacing: 2.4px;
    margin-bottom: 17px;
  }

  &:hover {
    filter: brightness(1);
    color: black;
    background-color: white;
  }
}


