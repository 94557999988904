 .root {
    width: 100vw;
    height: auto;
    align-items: center;
    box-sizing: border-box;
    background-color: black;
    position: absolute;
    top: 0px;
    left: 0px;
    opacity: 1;

    @media (min-width: 768px) {
      display: grid;
      grid-template-areas: "title subTitle backButton"
      "footer footer footer";
      align-items: baseline;
      padding: 5px;
      column-gap: 5vw;
    }

    .title {
      text-align: center;
      margin: 0 auto;
      margin-top: 233px;
      text-align: center;
      text-transform: uppercase;
      font-size: 32px;
      font-weight: 600;
      font-family: "Gilroy-Light", sans-serif;
      letter-spacing: 3.2px;
      color: white;
      opacity: 1;

      @media (min-width: 768px) {
        margin-left: 32px;
        grid-area: title;
      }
      @media (min-width: 1280px) {
        margin-left: 40px;
        font-size: 56px;
        letter-spacing: 5.6px;
      }
    }
    .subTitle {
      text-align: center;
      margin-top: 120px;
      text-transform: uppercase;
      font-size: 24px;
      font-weight: 600;
      font-family: "Gilroy-Light", sans-serif;
      letter-spacing: 2.4px;
      color: white;
      opacity: 1;
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 139px;

      @media (min-width: 768px) {
        font-size: 16px;
        letter-spacing: 1.6px;
        padding-left: 70px;
        padding-right: 57px;
        grid-area: subTitle;
        padding: 0px;
      }
      @media (min-width: 1280px) {
        font-size: 24px;
        letter-spacing: 2.4px;
        padding-left: 5vw;
        padding-right: 5vw;
        justify-self: stretch;
      }
      @media (min-width: 1920px) {
        font-size: 32px;
        letter-spacing: 3.2px;
      }
    }

    .path {
      color: red;
      font-size: 32px;
    }

    button {
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      font-family: "Gilroy-Light", sans-serif;
      letter-spacing: 1.6px;
      color: white;
      background-color: transparent;
      margin-bottom: 293px;
      margin-left: 16vw;

      @media (min-width: 550px) {
        margin-left: 27vw;
      }
      @media (min-width: 768px) {
        font-size: 12px;
        letter-spacing: 1.2px;
        margin-left: 0px;
        grid-area: backButton;
        justify-self: right;
        padding: 0px;
        margin-right: 20px;
      }
      @media (min-width: 1280px) {
        font-size: 16px;
        letter-spacing: 1.6px;
        margin-left: 0px;
        margin-right: 40px;
        margin-bottom: 297px;
      }
      @media (min-width: 1920px) {
        font-size: 24px;
        letter-spacing: 2.4px;
      }

      .backArrow {
        width: 20px;
        height: 20px;
        padding-right: 5px;
      }
    }

    .footer {
        @media (min-width: 768px) {
            grid-area: footer;
          } 
    }
  }
