.root {
  display: flex;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  font-family: sans-serif;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
}

.background {
  width: 100vw;
  height: 100%;
  background: rgb(61 67 78 / 60%);
  position: absolute;
  top: 0;
  left: 0;
}

.content {
  width: 100vw;
  height: 924px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: initial;
  box-sizing: border-box;
  background: #000000;
  opacity: 0.99;
  margin-top: 0;
  margin-left: 0;
  z-index: 95;
  overflow-x: hidden;
  overflow-x: hidden;

  @media (min-width: 768px) {
   //height: 568px;
   height: auto;
    display: grid;
    grid-template-areas:
      "closeWrapper nameWrapper"
      "closeWrapper buttonContainer";
    grid-template-columns: 12vw 70vw;
    grid-template-rows: 30px auto;
  }

  @media (min-width: 1280px) {
    //height: 684px;
    height: auto;
  }

  @media (min-width: 1920px) {
    height: 1019px;
    display: grid;
    grid-template-areas: "closeWrapper nameWrapper buttonContainer socialIconsContainer";
    grid-template-columns: 157px 682px 844px 35px;
  }
}

.closeWrapper {
  display: grid;
  box-sizing: border-box;
  z-index: 200;
  grid-area: closeWrapper;
  position: absolute;
  top: 20px;
  left: 29px;
  width: 40px;
  height: 40px;
  cursor: pointer;

  @media (min-width: 768px) {
    top: 29px;
    left: 41px;
    width: 40px;
    height: 50px;
  }

  .lineFirst {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 0px;
    height: 32px;
    border: 3px solid #ffffff;
    opacity: 1;
    z-index: 1;
    border-radius: 6px;

    @media (min-width: 768px) {
      width: 0px;
      height: 36px;
    }
  }

  .lineSecond {
    position: absolute;
    top: 0px;
    left: 13px;
    width: 0px;
    height: 32px;
    border: 3px solid #ffffff;
    opacity: 1;
    z-index: 1;
    border-radius: 6px;

    @media (min-width: 768px) {
      top: 0px;
      left: 11px;
      width: 0px;
      height: 36px;
    }
  }

  .lineThird {
    position: absolute;
    top: 0px;
    left: 26px;
    width: 0px;
    height: 32px;
    border: 3px solid #ffffff;
    opacity: 1;
    z-index: 1;
    border-radius: 6px;

    @media (min-width: 768px) {
      top: 0px;
      left: 22px;
      width: 0px;
      height: 36px;
    }
  }
}

.menuLanguage {
  position: absolute;
  top: 24px;
  right: 31px;
  width: 33px;
  height: 29px;
  text-align: left;
  font-size: 24px;
  font-family: "Gilroy-Regular", sans-serif;
  letter-spacing: 2.4px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  cursor: pointer;

  @media (min-width: 768px) {
    top: 45px;
    font-size: 16px;
    letter-spacing: 1.6px;
    width: 22px;
    right: 40px;
  }

  @media (min-width: 1280px) {
    right: 50px;
  }
  @media (min-width: 1920px) {
    top: 45px;
    font-size: 24px;
    letter-spacing: 2.4px;
    width: 35px;
    right: 90px;
  }
}

.nameWrapper {
  padding-top: 101px;
  padding-left: 23px;
  padding-right: 23px;
  display: flex;
  width: 255px;
  height: 67px;
  letter-spacing: 35px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 0.08;
  text-align: left;
  font-size: 56px;
  font-family: "Gilroy-Regular", sans-serif;
  margin-bottom: 26px;

  @media (min-width: 768px) {
    width: 291px;
    height: 77px;
    padding: 0px;
    margin-top: 570px;
    margin-left: 0px;
    grid-area: nameWrapper;
    font-size: 64px;
    letter-spacing: 40px;
    opacity: 0.08;
  }

  @media (min-width: 1280px) {
    width: 445px;
    height: 117px;
    font-size: 100px;
    letter-spacing: 61.25px;
    opacity: 0.08;
    margin-top: 670px;
  }

  @media (min-width: 1920px) {
    width: 682px;
    height: 180px;
    font-size: 150px;
    letter-spacing: 93.75px;
    opacity: 0.08;
    margin-top: 1050px;
  }
}

.buttonContainer {
  margin-right: 27px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (min-width: 768px) {
    margin-top: 68px;
    margin-bottom: 68px;
    margin-left: 50vw;
    grid-area: buttonContainer;
  }
  @media (min-width: 1920px) {
    margin-top: 1050px;
    margin-bottom: 68px;
    margin-left: 50px;
  }
}

.socialIconsContainer {
  display: flex;
  padding-top: 152px;
  padding-left: 55px;
  padding-bottom: 30px;
  flex-direction: row;

  @media (min-width: 768px) {
    display: block;
    margin-left: 153px;
    padding: 0px;
  }

  .firstImg {
    padding-right: 64px;
    padding-left: 55px;
    width: 40px;
    height: 40px;
    background: transparent;

    @media (min-width: 768px) {
      width: 24px;
      height: 24px;
      position: absolute;
      padding: 0px;
      top: 110px;
      right: 40px;
    }

    @media (min-width: 1280px) {
      right: 50px;
    }
    @media (min-width: 1920px) {
      right: 90px;
    }
  }

  .secondImg {
    padding-right: 64px;
    width: 40px;
    height: 40px;
    background: transparent;

    @media (min-width: 768px) {
      width: 24px;
      height: 24px;
      position: absolute;
      padding: 0;
      top: 174px;
      right: 40px;
    }

    @media (min-width: 1280px) {
      right: 50px;
    }
    @media (min-width: 1920px) {
      right: 90px;
    }
  }

  .thirdImg {
    padding-right: 105px;
    width: 40px;
    height: 40px;
    background: transparent;

    @media (min-width: 768px) {
      width: 24px;
      height: 24px;
      position: absolute;
      padding: 0;
      top: 240px;
      right: 40px;
    }

    @media (min-width: 1280px) {
      right: 50px;
    }
    @media (min-width: 1920px) {
      right: 90px;
    }
  }
}

.btn {
  width: 36px !important;
  height: 36px !important;
  min-width: unset !important;
  padding: unset !important;
}
