.engineRoot {
  position: absolute;
  top: 0;
  left: 0;

  .engineer_proj {
    height: auto;
    padding-bottom: 79px;
    background-color: black;
  }

  .all_prj {
    height: auto;
    background-color: white;
    padding-top: 80px;
  }
  .all_prj_light_1 {
    position: relative;
    margin: auto;
    background-color: white;
    padding-bottom: 138px;
  }
  .all_prj_palochka {
    vertical-align: top;
    position: relative;
    top: 36px;
    left: 47px;
    width: 3px;
    height: 32px;
    background-color: black;
    border-radius: 4px;
  }
  .all_prj_text_chapter {
    position: relative;
    vertical-align: top;
    top: 4px;
    left: 66px;
    max-width: 400px;
    margin: 0px;
    letter-spacing: 2.4px;
    font-size: 24px;
    font-family: "Gilroy-Bold", sans-serif;
  }
  .all_prj_foto_1 {
    padding-top: 35px;
  }
  .all_prj_foto_1,
  .all_prj_foto_2,
  .all_prj_foto_3,
  .all_prj_foto_4,
  .all_prj_foto_5,
  .all_prj_foto_6,
  .all_prj_foto_7,
  .all_prj_foto_8,
  .all_prj_foto_9,
  .all_prj_foto_10, 
  .all_prj_foto_11,
  .all_prj_foto_12,
  .all_prj_foto_13,
  .all_prj_foto_14,
  .all_prj_foto_15 {
    max-width: 80%;
    display: block;
    margin: 0 auto;
    padding-bottom: 4px;
  }
  .all_prj_dark_palochka {
    width: 80%;
    height: 3px;
    border-radius: 4px;
    background-color: black;
    margin: 73px auto 28px;
  }
  .all_prj_text {
    max-width: 57.21%;
    margin: 0 auto;
    letter-spacing: 3.2px;
    font-size: 16px;
    font-family: "Gilroy-Medium", sans-serif;
    text-align: center;
  }
  .btn_write_us {
    width: 61.4%;
    height: 56px;
    display: block;
    margin: 48px auto 0;
    background-color: black;
    color: white;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    border: 0;
    border-radius: 43px;
    padding: 0;
    cursor: pointer;
  }

  @media (min-width: 430px) {
    .all_prj_palochka {
      left: 107px;
    }
    .all_prj_text_chapter {
      left: 126px;
    }
  }

  @media (min-width: 768px) {
    .all_prj_light_1 {
      padding-bottom: 94px;
    }
    .all_prj_palochka {
      top: 36px;
      left: 65px;
      width: 4px;
    }
    .all_prj_text_chapter {
      left: 84px;
      letter-spacing: 2.4px;
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .all_prj_grid_foto {
      display: grid;
      grid-template-columns: auto[column1] 226px [column1_end] 2px [column2] 226px [column2_end] 2px [column3] 226px [column3_end] auto;
      grid-template-rows: 59px [row1] 127px [row1_end] 2px [row2] 127px [row2_end] 2px [row3] 127px [row3_end] 2px [row4] 127px [row4_end] 2px [row5] 127px [row5_end];
    }
    .all_prj_foto_1,
    .all_prj_foto_2,
    .all_prj_foto_3,
    .all_prj_foto_4,
    .all_prj_foto_5,
    .all_prj_foto_6,
    .all_prj_foto_7,
    .all_prj_foto_8,
    .all_prj_foto_9,
    .all_prj_foto_10,
    .all_prj_foto_11,
    .all_prj_foto_12,
    .all_prj_foto_13,
    .all_prj_foto_14,
    .all_prj_foto_15 {
      max-width: 100%;
      padding: 0;
    }
    .all_prj_foto_1,
    .all_prj_foto_4,
    .all_prj_foto_7,
    .all_prj_foto_10,
    .all_prj_foto_13 {
      grid-column: column1/column1_end;
    }
    .all_prj_foto_2,
    .all_prj_foto_5,
    .all_prj_foto_8,
    .all_prj_foto_11,
    .all_prj_foto_14 {
      grid-column: column2/column2_end;
    }
    .all_prj_foto_3,
    .all_prj_foto_6,
    .all_prj_foto_9,
    .all_prj_foto_12,
    .all_prj_foto_15 {
      grid-column: column3/column3_end;
    }
    .all_prj_foto_1,
    .all_prj_foto_2,
    .all_prj_foto_3 {
      grid-row: row1/row1_end;
    }
    .all_prj_foto_4,
    .all_prj_foto_5,
    .all_prj_foto_6 {
      grid-row: row2/row2_end;
    }
    .all_prj_foto_7,
    .all_prj_foto_8,
    .all_prj_foto_9 {
      grid-row: row3/row3_end;
    }
    .all_prj_foto_10,
    .all_prj_foto_11,
    .all_prj_foto_12 {
      grid-row: row4/row4_end;
    }
    .all_prj_foto_13,
    .all_prj_foto_14,
    .all_prj_foto_15 {
      grid-row: row5/row5_end;
    }
    .all_prj_dark_palochka {
      margin: 157px auto 0px;
      width: 77.5%;
      height: 2px;
    }
    .all_prj_text {
      max-width: 68%;
      letter-spacing: 4px;
      font-size: 20px;
      font-family: "Gilroy-Medium", sans-serif;
      margin: -64px auto 0px;
    }
    .btn_write_us {
      width: 19.5%;
      height: 36px;
      font-size: 8px;
      font-family: "Gilroy-Regular", sans-serif;
      margin-top: 7vw;
    }
  }

  @media (min-width: 1280px) {
    .all_prj_light_1 {
      padding-bottom: 131px;
    }
    .all_prj_palochka {
      top: 63px;
      left: 105px;
      width: 6px;
      height: 48px;
    }
    .all_prj_text_chapter {
      top: 15px;
      left: 136px;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .all_prj_grid_foto {
      display: grid;
      grid-template-columns: auto[column1] 363px [column1_end] 3px [column2] 363px [column2_end] 3px [column3] 363px [column3_end] auto;
      grid-template-rows: 95px [row1] 203px [row1_end] 3px [row2] 203px [row2_end] 3px [row3] 203px [row3_end] 3px [row4] 203px [row4_end] 3px [row5] 203px [row5_end];
    }
    .all_prj_dark_palochka {
      margin: 254px auto 0px;
      width: 77.5%;
      height: 4px;
    }
    .all_prj_text {
      letter-spacing: 6.4px;
      font-size: 32px;
      font-family: "Gilroy-Medium", sans-serif;
      margin: -84px auto 0px;
    }
    .btn_write_us {
      height: 57px;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
      margin-top: 72px;
    }
  }

  @media (min-width: 1600px) {
    .all_prj_palochka {
      top: 63px;
      left: 132px;
      width: 6px;
      height: 48px;
    }
    .all_prj_text_chapter {
      top: 15px;
      left: 170px;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .all_prj_grid_foto {
      display: grid;
      grid-template-columns: auto[column1] 453px [column1_end] 3px [column2] 453px [column2_end] 3px [column3] 453px [column3_end] auto;
      grid-template-rows: 95px [row1] 253px [row1_end] 3px [row2] 253px [row2_end] 3px [row3] 253px [row3_end] 3px [row4] 253px [row4_end] 3px [row5] 253px [row5_end];
    }
    .all_prj_dark_palochka {
      margin: 374px auto 0px;
      width: 77.5%;
      height: 4px;
    }
    .all_prj_text {
      letter-spacing: 6.4px;
      font-size: 32px;
      font-family: "Gilroy-Medium", sans-serif;
      margin: -84px auto 0px;
    }
    .btn_write_us {
      height: 71px;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
      margin-top: 72px;
    }
  }

  @media (min-width: 1920px) {
    .all_prj_light_1 {
      padding-bottom: 196px;
    }
    .all_prj_palochka {
      top: 98px;
      left: 158px;
      width: 8px;
      height: 64px;
    }
    .all_prj_text_chapter {
      top: 33px;
      left: 204px;
      letter-spacing: 4.8px;
      font-size: 46px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .all_prj_grid_foto {
      display: grid;
      grid-template-columns: auto [column1] 544px [column1_end] 4px [column2] 544px [column2_end] 4px [column3] 544px [column3_end] auto;
      grid-template-rows: 148px [row1] 304px [row1_end] 4px [row2] 304px [row2_end] 4px [row3] 304px [row3_end] 4px [row4] 304px [row4_end] 4px [row5] 304px [row5_end];
    }
    .all_prj_dark_palochka {
      margin: 495px auto 0px;
      width: 77.5%;
      height: 8px;
    }
    .all_prj_text {
      letter-spacing: 9.6px;
      font-size: 48px;
      font-family: "Gilroy-Medium", sans-serif;
      margin: -148px auto 0px;
    }
    .btn_write_us {
      height: 86px;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      margin-top: 7vw;
    }
  }
}
