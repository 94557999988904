.automationRoot {
  position: absolute;
  top: 0;
  left: 0;

  .automation {
    height: auto;
    background-color: black;
    @media (min-width: 1280px) {
      height: auto;
    }
    @media (min-width: 1920px) {
      height: auto;
    }
  }
  .automation_dark_1 {
    display: list-item;
    position: relative;
    margin: auto;
  }
  .automation_palochka {
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: 180px;
    left: 17px;
    width: 3px;
    height: 80px;
    background-color: white;
    border-radius: 4px;
  }
  .automation_text_chapter {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    top: 188px;
    left: 34px;
    max-width: 388px;
    margin: 0px;
    color: white;
    letter-spacing: 8.5px;
    font-size: 22px;
    font-family: "Gilroy-Bold", sans-serif;
  }
  .automation_describe_chapter {
    padding-top: 386px;
    color: white;
    line-height: 2;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Light", sans-serif;
    max-width: 72%;
    display: block;
    margin: 0 auto;
    border: none;
    padding-bottom: 214px;
  }
  .automation_light_1 {
    display: list-item;
    position: relative;
    min-height: 320px;
    background-color: white;
    margin: auto;
  }
  .automation_foto_1 {
    display: list-item;
    position: relative;
    display: block;
    max-width: 100%;
    height: auto;
  }
  .automation_paragraph_1 {
    padding-top: 63px;
    padding-bottom: 40px;
    max-width: 70%;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Medium", sans-serif;
    display: block;
    margin: 0 auto;
    line-height: 3;

    @media (min-width: 768px) {
      text-transform: uppercase;
      font-family: "Gilroy-Medium", sans-serif;
    }
  }
  .automation_dark_2 {
    display: list-item;
    position: relative;
  }
  .automation_foto_2 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .automation_light_palochka_1 {
    position: relative;
    max-width: 82%;
    height: 3px;
    background-color: white;
    margin: 0 auto;
    margin-top: 74px;
    border-radius: 4px;
  }
  .automation_head_paragraph_2 {
    position: relative;
    max-width: 85%;
    color: white;
    letter-spacing: 2.1px;
    text-align: center;
    font-size: 21px;
    font-family: "Gilroy-Light", sans-serif;
    display: block;
    margin: 0 auto;
    border: none;
    padding-top: 22px;
    padding-bottom: 17.5px;
  }
  .automation_numeric_1,
  .automation_numeric_2,
  .automation_numeric_3,
  .automation_numeric_4,
  .automation_numeric_5 {
    display: table-cell;
    color: white;
    letter-spacing: 8.5px;
    text-align: left;
    font-size: 17px;
    font-family: "Gilroy-Bold", sans-serif;
    padding-left: 26px;
    vertical-align: middle;
  }
  .automation_paragraph_2_1,
  .automation_paragraph_2_2,
  .automation_paragraph_2_3,
  .automation_paragraph_2_4,
  .automation_paragraph_2_5 {
    display: table-cell;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Light", sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }
  .automation_dark_3 {
    display: table;
    position: relative;
    padding-bottom: 39px;
  }
  .automation_foto_3 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
    padding-top: 27.5px;
    padding-bottom: 40px;
  }
  .automation_numeric_6,
  .automation_numeric_7,
  .automation_numeric_8 {
    display: table-cell;
    color: white;
    letter-spacing: 8.5px;
    text-align: left;
    font-size: 17px;
    font-family: "Gilroy-Bold", sans-serif;
    padding-left: 26px;
    vertical-align: middle;
  }
  .automation_paragraph_3_1,
  .automation_paragraph_3_2,
  .automation_paragraph_3_3 {
    display: table-cell;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Light", sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }
  .automation_paragraph_3_3 {
    padding-left: 25.5px;
  }
  .automation_dark_4 {
    display: table;
    position: relative;
    padding-bottom: 138px;
  }
  .automation_foto_4 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
    padding-bottom: 38.5px;
  }
  .automation_numeric_9,
  .automation_numeric_10,
  .automation_numeric_11,
  .automation_numeric_12 {
    display: table-cell;
    color: white;
    letter-spacing: 8.5px;
    text-align: left;
    font-size: 17px;
    font-family: "Gilroy-Bold", sans-serif;
    padding-left: 26px;
    vertical-align: middle;
  }
  .automation_paragraph_4_1,
  .automation_paragraph_4_2,
  .automation_paragraph_4_3,
  .automation_paragraph_4_4 {
    display: table-cell;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Light", sans-serif;
    padding-left: 30px;
    padding-right: 25px;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
  }
  .automation_dark_5 {
    position: relative;
  }
  .automation_paragraph_5 {
    display: block;
    max-width: 80%;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Light", sans-serif;
    margin: 0 auto;
    line-height: 2;
    padding-bottom: 123px;
  }

  @media (min-width: 430px) {
    .automation_palochka {
      left: 27px;
    }
    .automation_text_chapter {
      top: 180px;
      left: 54px;
      font-size: 25px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .automation_paragraph_1 {
      font-size: 16px;
      font-family: "Gilroy-Medium", sans-serif;
    }
    .automation_paragraph_4_1,
    .automation_paragraph_4_2,
    .automation_paragraph_4_3,
    .automation_paragraph_4_4 {
      padding-right: 30px;
    }
  }

  @media (min-width: 768px) {
    .automation_dark_1 {
      height: 600px;
    }
    .automation_palochka {
      top: 120px;
      left: 84px;
      width: 5px;
      height: 100px;
    }
    .automation_text_chapter {
      top: 122px;
      left: 125px;
      letter-spacing: 13.5px;
      font-size: 27px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .automation_describe_chapter {
      padding-top: 290px;
      max-width: 85.5%;
      padding-bottom: 0px;
    }
    .automation_light_1 {
      display: grid;
      grid-template-columns: [line1] 49.375% [line2] 50.625% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .automation_foto_1 {
      width: 586px;
      height: 410px;
      padding-top: 0px;
      grid-column: line2 / line_end;
      align-self: center;
      object-fit: cover;
      object-position: 0px;
      transform: scaleX(-1);
    }
    .automation_paragraph_1 {
      grid-column: line1 / line2;
      grid-row: dark1 / dark2;
      padding: 0px;
      align-self: center;
      padding-left: 70px;
      padding-right: 20px;
      max-width: 100%;
      font-size: 16px;
      font-family: "Gilroy-Medium", sans-serif;
    }
    .automation_dark_2 {
      display: grid;
      grid-template-columns: [line1] 41.375% [line2] 58.625% [line_end];
      grid-template-rows: [row_start] 70px [row_start1] 54px [row1] 48px [row1_end] 30px [row2] 48px [row2_end] 30px [row3] 72px [row3_end] 30px [row4] 96px [row4_end] 30px [row5] 48px [row5_end] 44px [row_end];
    }
    .automation_foto_2 {
      width: 643px;
      height: 431px;
      grid-column: line1 / line2;
      grid-row: row1 / row5_end;
      padding: 0px;
      object-fit: cover;
      justify-self: center;
      align-self: center;
    }
    .automation_light_palochka_1 {
      opacity: 0;
    }
    .automation_head_paragraph_2 {
      grid-column: line1 / line_end;
      grid-row: row_start / row_start1;
      padding: 48px 0px 0px 0px;
      height: 22px;
      letter-spacing: 3.6px;
      margin: 0px;
      justify-self: center;
      font-size: 18px;
      font-family: "Gilroy-Medium", sans-serif;
    }
    .automation_row_1 {
      grid-column: line2 / line_end;
      grid-row: row1 / row1_end;
      align-self: center;
    }
    .automation_row_2 {
      grid-column: line2 / line_end;
      grid-row: row2 / row2_end;
      align-self: center;
    }
    .automation_row_3 {
      grid-column: line2 / line_end;
      grid-row: row3 / row3_end;
      align-self: center;
    }
    .automation_row_4 {
      grid-column: line2 / line_end;
      grid-row: row4 / row4_end;
      align-self: center;
    }
    .automation_row_5 {
      grid-column: line2 / line_end;
      grid-row: row5 / row5_end;
      align-self: center;
    }
    .automation_numeric_1,
    .automation_numeric_2,
    .automation_numeric_3,
    .automation_numeric_4,
    .automation_numeric_5 {
      padding-left: 33px;
    }
    .automation_paragraph_2_1,
    .automation_paragraph_2_2,
    .automation_paragraph_2_3,
    .automation_paragraph_2_4,
    .automation_paragraph_2_5 {
      padding: 0px;
      vertical-align: middle;
      max-width: 100%;
      padding-left: 25px;
      padding-right: 25px;
    }
    .automation_dark_3 {
      display: grid;
      grid-template-columns: [line1] 64.375% [line2] 35.625% [line_end];
      grid-template-rows: [row_start] 94px [row1] 40px [row1_end] 30px [row2] 120px [row2_end] 30px [row3] 192px [row3_end] 94px [row_end];
      padding-bottom: 0px;
    }
    .automation_foto_3 {
      width: 455px;
      height: 368px;
      padding: 0px;
      grid-column: line2 / line_end;
      grid-row: row1 / row3_end;
      align-self: center;
      object-fit: cover;
      object-position: 0px;
    }
    .automation_row_6 {
      grid-column: line1 / line2;
      grid-row: row1 / row1_end;
      align-self: center;
    }
    .automation_row_7 {
      grid-column: line1 / line2;
      grid-row: row2 / row2_end;
      align-self: center;
    }
    .automation_row_8 {
      grid-column: line1 / line2;
      grid-row: row3 / row3_end;
      align-self: center;
    }
    .automation_numeric_6,
    .automation_numeric_7,
    .automation_numeric_8 {
      padding-left: 51px;
    }
    .automation_paragraph_3_1,
    .automation_paragraph_3_2,
    .automation_paragraph_3_3 {
      padding-top: 0px;
      padding-bottom: 0px;
      vertical-align: middle;
    }
    .automation_dark_4 {
      display: grid;
      grid-template-columns: [line1] 40.875% [line2] 59.125% [line_end];
      grid-template-rows: [row_start] 51px [row1] 96px [row1_end] 30px [row2] 96px [row2_end] 30px [row3] 96px [row3_end] 30px [row4] 120px [row4_end] 51px [row_end];
      padding-bottom: 0px;
    }
    .automation_foto_4 {
      width: 620px;
      height: 413px;
      padding: 0px;
      grid-column: line1 / line2;
      grid-row: row_start / row_end;
      align-self: center;
      object-fit: cover;
      transform: scaleX(-1);
    }
    .automation_row_9 {
      grid-column: line2 / line_end;
      grid-row: row1 / row1_end;
      align-self: center;
    }
    .automation_row_10 {
      grid-column: line2 / line_end;
      grid-row: row2 / row2_end;
      align-self: center;
    }
    .automation_row_11 {
      grid-column: line2 / line_end;
      grid-row: row3 / row3_end;
      align-self: center;
    }
    .automation_row_12 {
      grid-column: line2 / line_end;
      grid-row: row4 / row4_end;
      align-self: center;
    }
    .automation_numeric_9,
    .automation_numeric_10,
    .automation_numeric_11,
    .automation_numeric_12 {
      padding-left: 32px;
    }
    .automation_paragraph_4_1,
    .automation_paragraph_4_2,
    .automation_paragraph_4_3,
    .automation_paragraph_4_3,
    .automation_paragraph_4_4 {
      padding-top: 0px;
      padding-bottom: 0px;
      vertical-align: middle;
    }
    .automation_dark_5 {
      height: 600px;
      @media (min-width: 768px) {
        height: auto;
        padding-bottom: 200px;
      }

    }
    .automation_light_palochka_2 {
      display: inline-block;
      position: relative;
      vertical-align: top;
      top: 64px;
      left: 60px;
      width: 5px;
      height: 190px;
      background-color: white;
      border-radius: 4px;
    }
    .automation_paragraph_5 {
      display: inline-block;
      position: absolute;
      vertical-align: top;
      top: 68px;
      left: 97px;
    }
  }

  @media (min-width: 947px) {
    .automation_light_palochka_2 {
      height: 136px;
    }
  }

  @media (min-width: 1024px) {
    .automation_dark_2 {
      grid-template-rows: [row_start] 98px [row_start1] 54px [row1] 48px [row1_end] 30px [row2] 40px [row2_end] 30px [row3] 48px [row3_end] 30px [row4] 72px [row4_end] 30px [row5] 48px [row5_end] 72px [row_end];
    }
    .automation_dark_3 {
      grid-template-rows: [row_start] 142px [row1] 40px [row1_end] 30px [row2] 72px [row2_end] 30px [row3] 144px [row3_end] 142px [row_end];
    }
    .automation_dark_4 {
      grid-template-rows: [row_start] 111px [row1] 72px [row1_end] 30px [row2] 72px [row2_end] 30px [row3] 72px [row3_end] 30px [row4] 72px [row4_end] 111px [row_end];
    }
  }

  @media (min-width: 1272px) {
    .automation_light_palochka_2 {
      height: 104px;
    }
  }

  @media (min-width: 1280px) {
    .automation_dark_1 {
      height: 800px;
    }
    .automation_palochka {
      top: 214px;
      left: 180px;
      height: 138px;
    }
    .automation_text_chapter {
      top: 222px;
      left: 225px;
      letter-spacing: 20px;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .automation_describe_chapter {
      padding-top: 462px;
      font-size: 16px;
      font-family: "Gilroy-Light", sans-serif;
      max-width: 81%;
    }
    .automation_light_1 {
      grid-template-columns: [line1] 45.625% [line2] 54.375% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .automation_foto_1 {
      width: 744px;
      height: 504px;
      justify-self: center;
    }
    .automation_paragraph_1 {
      padding-left: 85px;
      padding-right: 85px;
      font-size: 18px;
      font-family: "Gilroy-Medium", sans-serif;
    }
    .automation_dark_2 {
      grid-template-columns: [line1] 48.125% [line2] 51.875% [line_end];
      grid-template-rows: [row_start] 162px [row_start1] 54px [row1] 60px [row1_end] 30px [row2] 58px [row2_end] 30px [row3] 60px [row3_end] 30px [row4] 90px [row4_end] 30px [row5] 60px [row5_end] 136px [row_end];
    }
    .automation_foto_2 {
      width: 762px;
      height: 511px;
      justify-self: center;
    }
    .automation_head_paragraph_2 {
      padding-top: 67px;
      font-size: 24px;
      font-family: "Gilroy-Medium", sans-serif;
    }
    .automation_numeric_1,
    .automation_numeric_2,
    .automation_numeric_3,
    .automation_numeric_4,
    .automation_numeric_5 {
      padding-left: 41px;
      letter-spacing: 12.5px;
      font-size: 25px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .automation_paragraph_2_1,
    .automation_paragraph_2_2,
    .automation_paragraph_2_3,
    .automation_paragraph_2_4,
    .automation_paragraph_2_5 {
      padding-left: 30px;
      padding-right: 55px;
      font-size: 16px;
      font-family: "Gilroy-Light", sans-serif;
    }
    .automation_dark_3 {
      grid-template-columns: [line1] 50% [line2] 50% [line_end];
      grid-template-rows: [row_start] 176px [row1] 58px [row1_end] 30px [row2] 120px [row2_end] 30px [row3] 210px [row3_end] 176px [row_end];
    }
    .automation_foto_3 {
      width: 640px;
      height: 520px;
      justify-self: center;
    }
    .automation_numeric_6,
    .automation_numeric_7,
    .automation_numeric_8 {
      padding-left: 56px;
      letter-spacing: 12.5px;
      font-size: 25px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .automation_paragraph_3_1,
    .automation_paragraph_3_2,
    .automation_paragraph_3_3,
    .automation_paragraph_3_4 {
      padding-left: 28px;
      padding-right: 80px;
      font-size: 16px;
      font-family: "Gilroy-Light", sans-serif;
    }
    .automation_dark_4 {
      grid-template-columns: [line1] 48.125% [line2] 51.875% [line_end];
      grid-template-rows: [row_start] 190px [row1] 60px [row1_end] 30px [row2] 90px [row2_end] 30px [row3] 90px [row3_end] 30px [row4] 90px [row4_end] 190px [row_end];
    }
    .automation_foto_4 {
      width: 620px;
      height: 414px;
      justify-self: center;
    }
    .automation_numeric_9,
    .automation_numeric_10,
    .automation_numeric_11,
    .automation_numeric_12 {
      padding-left: 36px;
      letter-spacing: 12.5px;
      font-size: 25px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .automation_paragraph_4_1,
    .automation_paragraph_4_2,
    .automation_paragraph_4_3,
    .automation_paragraph_4_4 {
      padding-left: 27px;
      padding-right: 40px;
      font-size: 16px;
      font-family: "Gilroy-Light", sans-serif;
    }
    .automation_paragraph_5 {
      max-width: 90%;
      padding-bottom: 200px;
    }
  }

  @media (min-width: 1763px) {
    .automation_light_palochka_2 {
      height: 72px;
    }
  }
}
