.btn {
  width: 100vw;
  height: 129px;
  box-sizing: border-box;
  color: white;
  border: none;
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.05);
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease-out;
  font-size: 20px;
  filter: brightness(1.1);
  letter-spacing: 2px;
  font-family: "Gilroy-Medium", sans-serif;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 8px 0 rgba(34, 60, 80, 0.5);
  margin-bottom: 1px;

  @media (min-width: 768px) {
    width: 400px;
    height: 87px;
    font-size: 14px;
    letter-spacing: 1px;
  }
  @media (min-width: 1100px) {
    width: 50vw;
  }

  @media (min-width: 1280px) {
    width: 640px;
    height: 134px;
    font-size: 20px;
    letter-spacing: 2px;
  }
  @media (min-width: 1500px) {
    width: 50vw;
  }
  @media (min-width: 1920px) {
    width: 960px;
    height: 204px;
    font-size: 32px;
    letter-spacing: 3.2px;
  }
  @media (min-width: 2000px) {
    width: 50vw;
  }


  &:hover {
    filter: brightness(1);
    background-color: rgba(255, 255, 255, 0.2);
  }
}
