.root {
  width: 100vw;
  height: 950px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  opacity: 1;
  overflow-x: hidden;

  @media (min-width: 768px) {
    height: 485px;
  }
  @media (min-width: 1280px) {
    height: 695px;
  }
  @media (min-width: 1920px) {
    height: 755px;
  }


  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
