.engineRoot {
  position: absolute;
  top: 0;
  left: 0;

  .engineer_proj {
    height: auto;
    background-color: black;
  }
  .engineer_proj_dark_1 {
    display: list-item;
    position: relative;
    margin: auto;
  }
  .engineer_proj_palochka {
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: 180px;
    left: 10vw;
    width: 3px;
    height: 80px;
    background-color: white;
    border-radius: 4px;
  }
  .engineer_proj_text_chapter {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    top: 180px;
    left: 18vw;
    max-width: 388px;
    margin: 0px;
    color: white;
    letter-spacing: 8.5px;
    font-size: 25px;
    font-family: "Gilroy-Bold", sans-serif;
  }
  .engineer_proj_describe_chapter {
    padding-top: 376px;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Light", sans-serif;
    max-width: 72%;
    display: block;
    margin: 0 auto;
    border: none;
    padding-bottom: 145px;
    line-height: 2;

    @media (min-width: 768px) {
      max-width: 85%;
    }
  }
  .engineer_proj_dark_2 {
    display: list-item;
    position: relative;
  }
  .engineer_proj_foto_1 {
    display: list-item;
    position: relative;
    display: block;
    max-width: 100%;
    height: auto;
  }
  .engineer_proj_paragraph_1 {
    letter-spacing: 1.6px;
    text-align: left;
    color: white;
    font-size: 16px;
    font-family: "Gilroy-Light", sans-serif;
    padding: 45px 0px 73px 0px;
    max-width: 80.5%;
    margin: 0 auto;
    line-height: 2;
  }
  .engineer_proj_dark_3 {
    display: list-item;
    position: relative;
  }
  .engineer_proj_foto_2 {
    display: list-item;
    position: relative;
    max-width: 100%;
    min-height: 412px;
    object-fit: cover;
  }
  .engineer_proj_paragraph_2 {
    text-align: left;
    color: white;
    font-size: 16px;
    font-family: "Gilroy-Light", sans-serif;
    letter-spacing: 1.6px;
    max-width: 80.5%;
    margin: 0 auto;
    padding: 73px 0 78px 0;
    line-height: 2;
  }
  .engineer_proj_dark_4 {
    display: list-item;
    position: relative;
    padding-bottom: 79px;
  }
  .engineer_proj_light_palochka_1 {
    margin: 0 auto;
    max-width: 88.6%;
    height: 3px;
    background-color: white;
    border-radius: 4px;
  }
  .engineer_proj_paragraph_3 {
    padding-top: 21px;
    padding-bottom: 36px;
    font-size: 21px;
    font-family: "Gilroy-Medium", sans-serif;
    letter-spacing: 2.1px;
    text-align: center;
    color: white;
  }
  .engineer_proj_btn_1 {
    display: block;
    width: 280px;
    height: 64px;
    background-color: white;
    border-radius: 43px;
    margin: 0 auto;
    font-weight: 600;
    font-size: 20px;
    font-family: "Gilroy-Regular", sans-serif;
    letter-spacing: 2px;
    text-align: center;
    border: 0;
    cursor: pointer;
  }

  @media (min-width: 320px) {
    .engineer_proj_palochka {
      left: 14vw;
    }
    .engineer_proj_text_chapter {
      left: 20vw;
    }
  }

  @media (min-width: 768px) {
    .engineer_proj {
      overflow: hidden;
    }
    .engineer_proj_dark_1 {
      height: 600px;
    }
    .engineer_proj_palochka {
      top: 120px;
      left: 84px;
      width: 5px;
      height: 100px;
    }
    .engineer_proj_text_chapter {
      top: 122px;
      left: 125px;
      letter-spacing: 13.5px;
      font-size: 27px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .engineer_proj_describe_chapter {
      padding-top: 306px;
      max-width: 85.5%;
      padding-bottom: 0px;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
    }
    .engineer_proj_dark_2 {
      display: grid;
      grid-template-columns: [line1] 62.5% [line2] 37.5% [line_end];
      grid-template-rows: [dark1] 500px [dark2];
    }
    .engineer_proj_foto_1 {
      width: 451px;
      height: 430px;
      top: 0px;
      padding-top: 0px;
      grid-column: line2 / line_end;
      grid-row: dark1 / dark2;
      align-self: center;
      object-fit: cover;
    }
    .engineer_proj_paragraph_1 {
      grid-column: line1 / line2;
      grid-row: dark1 / dark2;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
      padding: 0 40px 0 48px;
      align-self: baseline;
      max-width: 100%;
      padding-top: 7vw;
    }
    .engineer_proj_dark_3 {
      display: grid;
      grid-template-columns: [line1] 50% [line2] 50% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .engineer_proj_foto_2 {
      width: 500px;
      height: 375px;
      grid-column: line1 / line2;
      padding: 0px;
      object-fit: cover;
      justify-self: center;
      align-self: center;
    }
    .engineer_proj_paragraph_2 {
      grid-column: line2 / line_end;
      padding: 0 48px 0 40px;
      max-width: 100%;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
      align-self: center;
    }
    .engineer_proj_dark_4 {
      height: 245px;
      padding-bottom: 130px;
    }
    .engineer_proj_light_palochka_1 {
      position: relative;
      top: 65px;
      max-width: 87%;
      height: 4px;
    }
    .engineer_proj_paragraph_3 {
      padding-top: 80px;
      padding-bottom: 0px;
      align-self: center;
      font-size: 25px;
      font-family: "Gilroy-Medium", sans-serif;
      letter-spacing: 3.6px;
    }
    .engineer_proj_btn_1 {
      position: relative;
      top: 68px;
      left: 2vw;
      width: 256px;
      font-size: 16px;
      font-family: "Gilroy-Medium", sans-serif;
      letter-spacing: 1.6px;
      cursor: pointer;
    }
  }

  @media (min-width: 1280px) {
    .engineer_proj_dark_1 {
      height: 800px;
    }
    .engineer_proj_palochka {
      top: 214px;
      left: 180px;
      height: 138px;
      width: 5px;
    }
    .engineer_proj_text_chapter {
      top: 222px;
      left: 225px;
      letter-spacing: 20px;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .engineer_proj_describe_chapter {
      padding-top: 480px;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
      max-width: 85.5%;
    }
    .engineer_proj_dark_2 {
      display: grid;
      grid-template-columns: [line1] 65% [line2] 35% [line_end];
      grid-template-rows: [dark1] 500px [dark2];
    }
    .engineer_proj_foto_1 {
      width: 447px;
      height: 460px;
    }
    .engineer_proj_paragraph_1 {
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
      padding: 50px 48px 0 80px;
    }
    .engineer_proj_dark_3 {
      display: grid;
      grid-template-columns: [line1] 50% [line2] 50% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .engineer_proj_foto_2 {
      width: 964px;
      height: 482px;
    }
    .engineer_proj_paragraph_2 {
      padding: 0 72px 0 107px;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
    }
    .engineer_proj_dark_4 {
      height: auto;
      padding-bottom: 120px;
    }
    .engineer_proj_light_palochka_1 {
      position: static;
      margin-top: 109px;
      max-width: 61%;
      height: 6px;
    }
    .engineer_proj_paragraph_3 {
      padding-top: 20px;
      padding-bottom: 49px;
      font-weight: 600;
      font-size: 25px;
      font-family: "Gilroy-Medium", sans-serif;
      letter-spacing: 5px;
    }
    .engineer_proj_btn_1 {
      position: static;
      width: 250px;
      height: 58px;
      font-size: 14px;
      font-family: "Gilroy-Medium", sans-serif;
      letter-spacing: 1.4px;
      cursor: pointer;
    }
  }

  @media (min-width: 1920px) {
    .engineer_proj_dark_1 {
      height: 1080px;
    }
    .engineer_proj_palochka {
      top: 322px;
      left: 253px;
      height: 206px;
      width: 8px;
    }
    .engineer_proj_text_chapter {
      top: 330px;
      left: 323px;
      letter-spacing: 32px;
      font-size: 64px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .engineer_proj_describe_chapter {
      padding-top: 614px;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      max-width: 85.5%;
    }
    .engineer_proj_dark_2 {
      display: grid;
      grid-template-columns: [line1] 65% [line2] 35% [line_end];
      grid-template-rows: [dark1] 700px [dark2];
    }
    .engineer_proj_foto_1 {
      width: 670px;
      height: 680px;
    }
    .engineer_proj_paragraph_1 {
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      padding: 40px 77px 0 115px;
    }
    .engineer_proj_dark_3 {
      display: grid;
      grid-template-columns: [line1] 58% [line2] 42% [line_end];
      grid-template-rows: [dark1] 1080px [dark2];
    }
    .engineer_proj_foto_2 {
      width: 1151px;
      height: 575px;
    }
    .engineer_proj_paragraph_2 {
      padding: 0 72px 0 133px;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .engineer_proj_dark_4 {
      height: auto;
      padding-bottom: 150px;
    }
    .engineer_proj_light_palochka_1 {
      position: static;
      margin-top: 109px;
      max-width: 61%;
      height: 8px;
    }
    .engineer_proj_paragraph_3 {
      padding-top: 30px;
      padding-bottom: 69px;
      font-size: 40px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 8px;
    }
    .engineer_proj_btn_1 {
      position: static;
      width: 376px;
      height: 86px;
      font-size: 24px;
      font-family: "Gilroy-Medium", sans-serif;
      letter-spacing: 2.4px;
      cursor: pointer;
    }
  }
}
