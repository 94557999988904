.root {
  width: 100vw;
  height: auto;
  background-color: black;
  opacity: 1;
  overflow: hidden;

  @media (min-width: 768px) {
    width: calc(100vw - 17px);
  }

  .title {
    height: 50px;
    box-sizing: border-box;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 10px;
    font-weight: 600;
    font-family: "Gilroy-Medium", sans-serif;
    position: relative;
    margin: 0 auto;
    margin-top: 58px;

    @media (min-width: 768px) {
      margin-top: 137px;
      height: 20px;
      font-size: 24px;
      letter-spacing: 8px;
      font-weight: 600;
    }

    @media (min-width: 1280px) {
      margin-top: 158px;
      height: 30px;
      font-size: 32px;
      letter-spacing: 12px;
      margin-bottom: 100px;
    }

    @media (min-width: 1920px) {
      margin-top: 156px;
      height: 49px;
      font-size: 40px;
      letter-spacing: 20px;
      font-family: "Gilroy-Bold", sans-serif;
      font-weight: 400;
    }
  }
  .brands_img {
    background-image: url("../../img/d8_brands_link_320.png");
    height: 160px;
    margin-top: 24px;
    background-size: cover;
    background-position: center;

    @media (min-width: 350px) {
      background-image: url("../../img/d8_brands_link_430.png");
      height: 220px;
    }
    @media (min-width: 768px) {
      background-image: url("../../img/d8_brands_link_768.png");
      height: 100px;
      cursor: pointer;
    }
    @media (min-width: 1280px) {
      background-image: url("../../img/d8_brands_link_1280.png");
    }
    @media (min-width: 1920px) {
      background-image: url("../../img/d8_brands_link_1920.png");
      height: 150px;
    }
  }
}
