.engineRoot {
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: hidden;

  @media (min-width: 768px) {
    width: calc(100vw - 22px);
  }

  .engineer_proj {
    height: auto;
    padding-top: 80px;
  }

  .cur_prj {
    height: auto;
    background-color: black;
  }
  .cur_prj_light_1 {
    position: relative;
    margin: auto;
    background-color: white;
    padding-bottom: 84px;
  }
  .cur_prj_palochka {
    vertical-align: top;
    position: relative;
    top: 36px;
    left: 57px;
    width: 3px;
    height: 32px;
    background-color: black;
    border-radius: 4px;
  }
  .cur_prj_palochka_horizont {
    display: none;
  }
  .cur_prj_text_chapter {
    position: relative;
    vertical-align: top;
    top: 4px;
    left: 76px;
    margin: 0px;
    letter-spacing: 2.4px;
    font-size: 24px;
    font-family: "Gilroy-Bold", sans-serif;
    text-transform: uppercase;
    width: 270px;
  }
  .cur_prj_btnlist {
    padding-top: 50px;
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  .cur_prj_prevprj,
  .cur_prj_allprj,
  .cur_prj_nextprj {
    background-color: transparent;
    border: 0;
    opacity: 0.5;
    letter-spacing: 1.2px;
    font-size: 10px;
    font-family: "Gilroy-Light", sans-serif;

    &:hover {
      cursor: pointer;
    }
  }
  .cur_prj_arrow_left,
  .cur_prj_arrow_right {
    border: solid black;
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(135deg);
  }
  .cur_prj_arrow_right {
    transform: rotate(-45deg);
  }
  .cur_prj_photolist {
    max-width: 79.53%;
    margin: 0 auto;
    height: auto;
    position: relative;
    display: grid;
  }
  .cur_prj_foto_1 {
    grid-column: line1/line2_end;
    grid-row: row2/row3;
    max-width: 100%;
    height: auto;
  }

  .cur_prj_prevphoto {
    grid-column: line1 / line1_end;
    grid-row: row2/row3;
    height: 2px;
    width: 2px;
    border: solid white;
    border-width: 0 2px 2px 0;
    padding: 2px;
    transform: rotate(135deg);
    justify-self: center;
    align-self: center;
    margin-left: 4px;
  }

  .cur_prj_nextphoto {
    grid-column: line2 / line2_end;
    grid-row: row2/row3;
    height: 2px;
    width: 2px;
    border: solid white;
    border-width: 0 2px 2px 0;
    padding: 2px;
    transform: rotate(-45deg);
    justify-self: center;
    align-self: center;
    margin-right: 4px;
  }
  .cur_prj_btn_prev_photo {
    grid-column: line1 / line1_end;
    grid-row: row2/row3;
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .cur_prj_btn_next_photo {
    grid-column: line2 / line2_end;
    grid-row: row2/row3;
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 0;
  }
  .cur_prj_textprj {
    opacity: 0.5;
    letter-spacing: 2.4px;
    font-size: 24px;
    font-family: "Gilroy-Regular", sans-serif;
    text-align: center;
    margin: 0;
    padding-top: 36px;
  }
  .cur_prj_architectorname,
  .cur_prj_systemname,
  .cur_prj_photoprj {
    opacity: 0.5;
    letter-spacing: 1.6px;
    font-size: 12px;
    font-family: "Gilroy-Regular", sans-serif;
    padding: 58px 0 8px 0px;
    margin: 0 auto;
    max-width: 79.53%;
  }
  .cur_prj_grey_palochka_1,
  .cur_prj_grey_palochka_2,
  .cur_prj_grey_palochka_3 {
    background-color: black;
    opacity: 0.5;
    height: 2px;
    border-radius: 4px;
    width: 79.53%;
    margin: 0 auto;
  }

  @media (min-width: 430px) {
    .cur_prj_palochka {
      left: 106px;
    }
    .cur_prj_text_chapter {
      left: 126px;
      width: 290px;
    }
    .cur_prj_btnlist {
      max-width: 79.53%;
    }
    .cur_prj_prevprj,
    .cur_prj_allprj,
    .cur_prj_nextprj {
      font-size: 12px;
      font-family: "Gilroy-Light", sans-serif;
    }
    .cur_prj_prevphoto {
      margin-right: 2px;
    }
    .cur_prj_nextphoto {
      margin-right: 2px;
    }
    .cur_prj_architectorname,
    .cur_prj_systemname,
    .cur_prj_photoprj {
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
    }
  }
  @media (min-width: 514px) {
  .cur_prj_text_chapter {
    width: auto;
  }
}
  @media (min-width: 768px) {
    .cur_prj_div_palochka {
      max-width: 82%;
      margin: 0 auto;
    }
    .cur_prj_palochka {
      display: none;
    }
    .cur_prj_palochka_horizont {
      display: block;
      top: 57px;
      left: 0px;
      width: 48vw;
      height: 4px;
      background-color: black;
      border-radius: 4px;
      position: relative;
    }
    .cur_prj_text_chapter {
      top: 18px;
      left: 0px;
      max-width: 82%;
      margin: 0 auto;
    }
    .cur_prj_for800h {
      position: relative;
      top: 20px;
      display: grid;
      grid-template-rows: [row1] 97% [row2] 50px [row3];
      grid-template-columns: 100%;
    }
    .cur_prj_btnlist {
      padding-top: 0px;
      max-width: 46.5%;
      grid-row: row2/row3;
      display: grid;
      grid-template-areas: "prevAr allAr nextAr";
      column-gap: 5vw;
    }
    .cur_prj_prevprj {
      grid-area: prevAr;
    }
    .cur_prj_allprj {
      grid-area: allAr;
    }
    .cur_prj_nextprj {
      grid-area: nextAr;
    }

    .cur_prj_photolist {
      max-width: 82%;
      position: relative;
      top: 9px;
    }
    .cur_prj_foto_1 {
      max-width: 100%;
      height: auto;
    }
    .cur_prj_textprj {
      margin-top: 76px;
      padding-top: 0px;
    }
  }

  @media (min-width: 1280px) {
    .cur_prj_light_1 {
      display: grid;
      grid-template-columns: 4.62% [line1] 61.32% [line2] 34.06% [line3];
      grid-template-rows: [row1] 125px [row2] 64% [row_end];
      padding-bottom: 0px;
    }
    .cur_prj_div_palochka {
      grid-column: line1/line3;
      grid-row: row1/row2;
      margin-left: 0px;
    }
    .cur_prj_palochka_horizont {
      top: 87px;
      height: 8px;
      width: 30vw;
    }
    .cur_prj_text_chapter {
      grid-column: line1/line3;
      grid-row: row1/row2;
      margin-left: 0px;
      top: 47px;
    }
    .cur_prj_for800h {
      grid-column: line1/line2;
      grid-row: row2/row3;
      grid-template-rows: [row1] 85% [row2] auto [row3];
      top: 0px;
    }
    .cur_prj_photolist {
      max-width: 100%;
      grid-template-rows: [row1] 0px [row2] 90% [row3] 0px [row4];
      top: 0px;
    }
    .cur_prj_prevphoto {
      padding: 4px;
      border-width: 0 4px 4px 0;
      margin-right: 0px;
    }
    .cur_prj_nextphoto {
      padding: 4px;
      border-width: 0 4px 4px 0;
      margin-right: 4px;
    }
    .cur_prj_btnlist {
      max-width: 62.67%;
      gap: 40px;
    }
    .cur_prj_prevprj,
    .cur_prj_allprj,
    .cur_prj_nextprj {
      margin-top: 28px;
      align-self: start;
      letter-spacing: 1.6px;
      font-size: 16px;
      font-family: "Gilroy-Light", sans-serif;
      margin-bottom: 120px;
    }
    .cur_prj_textprj,
    .cur_prj_architectorname,
    .cur_prj_grey_palochka_1,
    .cur_prj_systemname,
    .cur_prj_grey_palochka_2,
    .cur_prj_photoprj,
    .cur_prj_grey_palochka_3 {
      grid-column: line2/line3;
      grid-row: row2/row3;
    }
    .cur_prj_textprj {
      padding: 0;
      margin-top: 0px;
    }
    .cur_prj_architectorname {
      padding: 71px 0 0 40px;
      margin: 0;
      max-width: 100%;
    }
    .cur_prj_grey_palochka_1 {
      max-width: 78.89%;
      margin: 95px 0 0 40px;
      height: 1px;
    }
    .cur_prj_systemname {
      padding: 149px 0 0 40px;
      margin: 0;
      max-width: 100%;
    }
    .cur_prj_grey_palochka_2 {
      max-width: 78.89%;
      margin: 193px 0 0 40px;
      height: 1px;
    }
    .cur_prj_photoprj {
      padding: 260px 0 21vw 40px;
      margin: 0;
      max-width: 100%;
    }
    .cur_prj_grey_palochka_3 {
      max-width: 78.89%;
      margin: 284px 0 0 40px;
      height: 1px;
    }
  }
  @media (min-width: 1550px) {
    .cur_prj_photoprj {
      padding: 260px 0 23vw 40px;
    }
  }
  @media (min-width: 1710px) {
    .cur_prj_photoprj {
      padding: 260px 0 26vw 40px;
    }
  }

  @media (min-width: 1920px) {
    .cur_prj_light_1 {
      display: grid;
      grid-template-columns: 9.94% [line1] 59.375% [line2] 30.685% [line3];
      grid-template-rows: [row1] 217px [row2] auto [row_end];
    }
    .cur_prj_palochka_horizont {
      top: 160px;
      width: 26vw;
    }
    .cur_prj_text_chapter {
      top: 110px;
      letter-spacing: 3.2px;
      font-size: 32px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .cur_prj_prevphoto {
      padding: 10px;
      border-width: 0 6px 6px 0;
      margin-left: 15px;
    }
    .cur_prj_nextphoto {
      padding: 10px;
      border-width: 0 6px 6px 0;
      margin-right: 15px;
    }
    .cur_prj_btnlist {
      gap: 136px;
    }
    .cur_prj_prevprj,
    .cur_prj_allprj,
    .cur_prj_nextprj {
      margin-top: 48px;
      margin-bottom: 182px;
    }
    .cur_prj_architectorname {
      padding: 101px 0 0 53px;
      margin: 0;
      max-width: 100%;
    }
    .cur_prj_grey_palochka_1 {
      max-width: 82.85%;
      margin: 125px 0 0 53px;
      height: 1px;
    }
    .cur_prj_systemname {
      padding: 193px 0 0 53px;
      margin: 0;
      max-width: 100%;
    }
    .cur_prj_grey_palochka_2 {
      max-width: 82.85%;
      margin: 237px 0 0 53px;
      height: 1px;
    }
    .cur_prj_photoprj {
      padding: 325px 0 23vw 53px;
      margin: 0;
      max-width: 100%;
    }
    .cur_prj_grey_palochka_3 {
      max-width: 82.85%;
      margin: 349px 0 0 53px;
      height: 1px;
    }
  }

  .additDiv {
    height: 80px;
    width: 100%;
    background-color: black;

    @media (min-width: 768px) {
      height: 40px;
    }
  }

  .carousel {
    margin-top: 39px;
    width: 83vw;
    height: auto;

    @media (min-width: 768px) {
      margin-top: 55px;
    }

    @media (min-width: 1280px) {
      margin-top: 0px;
    }

    @media (min-width: 1920px) {
      margin-top: 0px;
    }

    .cur_prj_zone_prev {
      background-color: black;
      opacity: 0.5;
      width: 18px;
      height: 45vw;
      z-index: 2;
      position: absolute;

      @media (min-width: 768px) {
        height: 46.2vw;
        width: 24px;
      }
      @media (min-width: 1280px) {
        width: 30px;
        top: 0px;
        height: 35vw;
      }
      @media (min-width: 1920px) {
        height: 33.7vw;
      }
    }

    .cur_prj_zone_next {
      background-color: black;
      opacity: 0.5;
      width: 18px;
      height: 46.5vw;
      z-index: 2;
      position: absolute;
      top: 40px;
      right: -3.5vw;

      @media (min-width: 768px) {
        top: 56px;
        //right: -1vw;
        right: -26px;
        height: 46.7vw;
        width: 24px;
      }
      @media (min-width: 1280px) {
        width: 30px;
        top: 0px;
        height: 35vw;
      }
      @media (min-width: 1920px) {
        height: 33.7vw;
        //right: -0.4vw;
        right: -22px;
      }
    }
  }

  .cur_prj_describe {
    opacity: 0.5;
    letter-spacing: 1.6px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    padding: 38px 0 8px 0px;
    margin: 0 auto;
    max-width: 79.53%;

    @media (min-width: 1280px) {
      grid-column: line2/line3;
      grid-row: row2/row3;
      padding: 61px 0 0 40px;
      margin: 0;
      max-width: 76%;
      height: 550px;
      font-size: 14px;
    }
    @media (min-width: 1470px) {
      height: 650px;
    }
    @media (min-width: 1700px) {
      height: 740px;
    }

    @media (min-width: 1920px) {
      padding: 71px 0 0 53px;
      margin: 0;
      max-width: 76%;
      height: 750px;
      font-size: 20px;
    }
    @media (min-width: 2150px) {
      height: 850px;
    }
    @media (min-width: 2400px) {
      height: 1050px;
      font-size: 20px;
    }
    @media (min-width: 2900px) {
      height: 1250px;
      font-size: 25px;
    }
  }
}
