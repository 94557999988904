.root {
  width: 100vw;
  height: auto;
  background-color: black;
  opacity: 1;
  overflow: hidden;


  .title {
    height: 50px;
    box-sizing: border-box;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 24px;
    letter-spacing: 10px;
    font-weight: 600;
    font-family: "Gilroy-Bold", sans-serif;
    position: relative;
    margin: 0 auto;
    margin-top: 58px;

    @media (min-width: 768px) {
      margin-top: 137px;
      height: 20px;
      font-size: 24px;
      letter-spacing: 8px;
      font-weight: 400;
    }

    @media (min-width: 1280px) {
      margin-top: 158px;
      height: 30px;
      font-size: 32px;
      letter-spacing: 12px;
      margin-bottom: 100px;
    }

    @media (min-width: 1920px) {
      margin-top: 156px;
      height: 49px;
      font-size: 40px;
      letter-spacing: 20px;
    }
  }



  .partner_grid {
    display: grid;
    grid-template-columns: 11.63% [column_1] 38.37% [column_2] 38.37% [column_3] 11.63%;
    grid-template-rows: [row_1] 165px [row_2] 165px [row_3] 165px [row_4] 165px [row_5] 165px [row_6] 165px [row_7] 165px [row_8] 165px [row_9];
  }
  .partner_foto_1 {
    grid-column: column_1/column_2;
    grid-row: row_1/row_2;
    width: 69px;
    height: 92px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_2 {
    grid-column: column_2/column_3;
    grid-row: row_1/row_2;
    width: 140px;
    height: 62px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_3 {
    grid-column: column_1/column_2;
    grid-row: row_2/row_3;
    width: 92px;
    height: 92px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_4 {
    grid-column: column_2/column_3;
    grid-row: row_2/row_3;
    width: 97px;
    height: 97px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_5 {
    grid-column: column_1/column_2;
    grid-row: row_3/row_4;
    width: 92px;
    height: 92px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_6 {
    grid-column: column_2/column_3;
    grid-row: row_3/row_4;
    width: 111px;
    height: 81px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_7 {
    grid-column: column_1/column_2;
    grid-row: row_4/row_5;
    width: 102px;
    height: 102px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_8 {
    grid-column: column_2/column_3;
    grid-row: row_4/row_5;
    width: 134px;
    height: 73px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_8_1 {
    display: none;
    grid-column: column_2/column_3;
    grid-row: row_4/row_5;
    width: 134px;
    height: 73px;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_9 {
    grid-column: column_1/column_2;
    grid-row: row_5/row_6;
    width: 134px;
    height: 47px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_9_1 {
    display: none;
    grid-column: column_1/column_2;
    grid-row: row_5/row_6;
    width: 134px;
    height: 47px;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_10 {
    grid-column: column_2/column_3;
    grid-row: row_5/row_6;
    width: 152px;
    height: 45px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_10_1 {
    display: none;
    grid-column: column_2/column_3;
    grid-row: row_5/row_6;
    width: 122px;
    height: 122px;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_11 {
    grid-column: column_1/column_2;
    grid-row: row_6/row_7;
    width: 151px;
    height: 81px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_12 {
    grid-column: column_2/column_3;
    grid-row: row_6/row_7;
    width: 92px;
    height: 92px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_14 {
    grid-column: column_1/column_2;
    grid-row: row_7/row_8;
    width: 108px;
    height: 108px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_15 {
    grid-column: column_2/column_3;
    grid-row: row_7/row_8;
    width: 89px;
    height: 111px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_16 {
    grid-column: column_1/column_2;
    grid-row: row_8/row_9;
    width: 122px;
    height: 109px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  .partner_foto_17 {
    grid-column: column_2/column_3;
    grid-row: row_8/row_9;
    width: 71px;
    height: 71px;
    display: flex;
    align-self: center;
    justify-self: center;
  }
  
  
  
  
  @media (min-width: 768px) {
    .partner_grid {
      grid-template-columns: 6.875% [column_1] 21.5625% [column_2] 21.5625% [column_3] 21.5625% [column_4] 21.5625% [column_5] 6.875%;
      grid-template-rows: [row_1] 200px [row_2] 200px [row_3] 200px [row_4] 200px [row_5];
    }
    .partner_foto_1,.partner_foto_5,.partner_foto_9,.partner_foto_9_1, .partner_foto_14 {
      grid-column: column_1/column_2;
    }
    .partner_foto_2,.partner_foto_6,.partner_foto_10,.partner_foto_10_1, .partner_foto_15 {
      grid-column: column_2/column_3;
    }
    .partner_foto_3,.partner_foto_7,.partner_foto_11, .partner_foto_16 {
      grid-column: column_3/column_4;
    }
    .partner_foto_4,.partner_foto_8,.partner_foto_8_1,.partner_foto_12, .partner_foto_17 {
      grid-column: column_4/column_5;
    }
  
    .partner_foto_1,.partner_foto_2,.partner_foto_3,.partner_foto_4 {
      grid-row: row_1/row_2;
    }
    .partner_foto_5,.partner_foto_6,.partner_foto_7,.partner_foto_8,.partner_foto_8_1 {
      grid-row: row_2/row_3;
    }
    .partner_foto_9,.partner_foto_9_1,.partner_foto_10,.partner_foto_10_1,.partner_foto_11,.partner_foto_12 {
      grid-row: row_3/row_4;
    }
    .partner_foto_14,.partner_foto_15,.partner_foto_16,.partner_foto_17 {
      grid-row: row_4/row_5;
    }
  }
  
  @media (min-width: 1280px) {
    .partner_grid {
      grid-template-columns: 5.625% [column_1] 22.1875% [column_2] 22.1875% [column_3] 22.1875% [column_4] 22.1875% [column_5] 5.625%;
      grid-template-rows: [row_1] 200px [row_2] 200px [row_3] 200px [row_4] 200px [row_5];
    }
    .partner_foto_1 {
      width: 114px;
      height: 150px;
    }
    .partner_foto_2 {
      width: 230px;
      height: 103px;
    }
    .partner_foto_3 {
      width: 150px;
      height: 150px;
    }
    .partner_foto_4,.partner_foto_7 {
      width: 160px;
      height: 160px;
    }
    .partner_foto_5{
      width: 152px;
      height: 152px;
    }
    .partner_foto_6 {
      width: 170px;
      height: 129px;
    }
    .partner_foto_8,.partner_foto_8_1 {
      width: 221px;
      height: 121px;
    }
    .partner_foto_9,.partner_foto_9_1 {
      width: 220px;
      height: 77px;
    }
    .partner_foto_10,.partner_foto_10_1 {
      width: 242px;
      height: 85px;
    }
    .partner_foto_11 {
      width: 191px;
      height: 121px;
    }
    .partner_foto_12 {
      width: 152px;
      height: 152px;
    }
    .partner_foto_14 {
      width: 158px;
      height: 158px;
    }
    .partner_foto_15 {
      width: 129px;
      height: 141px;
    }
    .partner_foto_16 {
      width: 152px;
      height: 120px;
    }
    .partner_foto_17 {
      width: 120px;
      height: 120px;
    }
  }
  
  @media (min-width: 1920px) {
    .partner_grid {
      grid-template-columns: 8.48% [column_1] 16.608% [column_2] 16.608% [column_3] 16.608%[column_4] 16.608% [column_5] 16.608% [column_6] 8.48%;
      grid-template-rows: [row_1] 250px [row_2] 250px [row_3] 250px [row_4];
    }
    .partner_foto_1 {
      grid-column: column_1/column_2;
      grid-row: row_1/row_2;
      width: 128px;
      height: 169px;
    }
    .partner_foto_2 {
      grid-column: column_2/column_3;
      grid-row: row_1/row_2;
      width: 257px;
      height: 115px;
    }
    .partner_foto_3 {
      grid-column: column_3/column_4;
      grid-row: row_1/row_2;
      width: 169px;
      height: 169px;
    }
    .partner_foto_4 {
      grid-column: column_4/column_5;
      grid-row: row_1/row_2;
      width: 179px;
      height: 179px;
    }
    .partner_foto_5 {
      grid-column: column_5/column_6;
      grid-row: row_1/row_2;
      width: 168px;
      height: 168px;
    }
    .partner_foto_6 {
      grid-column: column_1/column_2;
      grid-row: row_2/row_3;
      width: 187px;
      height: 129px;
    }
    .partner_foto_7 {
      grid-column: column_2/column_3;
      grid-row: row_2/row_3;
      width: 178px;
      height: 178px;
    }
    .partner_foto_8 {
      grid-column: column_3/column_4;
      grid-row: row_2/row_3;
      width: 247px;
      height: 135px;
    }
    .partner_foto_8_1 {
      grid-column: column_3/column_4;
      grid-row: row_2/row_3;
      width: 247px;
      height: 135px;
    }
    .partner_foto_9 {
      grid-column: column_4/column_5;
      grid-row: row_2/row_3;
      width: 247px;
      height: 86px;
    }
    .partner_foto_9_1 {
      grid-column: column_4/column_5;
      grid-row: row_2/row_3;
      width: 247px;
      height: 86px;
    }
    .partner_foto_10 {
      grid-column: column_5/column_6;
      grid-row: row_2/row_3;
      width: 202px;
      height: 95px;
    }
    .partner_foto_10_1 {
      grid-column: column_5/column_6;
      grid-row: row_2/row_3;
      width: 223px;
      height: 223px;
    }
    .partner_foto_11 {
      grid-column: column_1/column_2;
      grid-row: row_3/row_4;
      width: 191px;
      height: 121px;
    }
    .partner_foto_12 {
      grid-column: column_2/column_3;
      grid-row: row_3/row_4;
      width: 168px;
      height: 168px;
    }
    .partner_foto_14 {
      grid-column: column_3/column_4;
      grid-row: row_3/row_4;
      width: 158px;
      height: 158px;
    }
    .partner_foto_15 {
      grid-column: column_4/column_5;
      grid-row: row_3/row_4;
      width: 129px;
      height: 141px;
    }
    .partner_foto_16 {
      grid-column: column_5/column_6;
      grid-row: row_3/row_4;
      width: 172px;
      height: 140px;
    }
    .partner_foto_17 {
     display: none;
    }
  }

 
}