.root {
  width: 100vw;
  position: absolute;
  top: 868px;
  left: 0px;
  display: block;
  align-items: center;
  box-sizing: border-box;
  background-color: black;
  opacity: 1;
  overflow-x: hidden;
  height: auto;

  @media (min-width: 768px) {
    top: 686px;
    width: calc(100vw - 17px);
  }

  @media (min-width: 1280px) {
    top: 718px;
  }

  @media (min-width: 1920px) {
    top: 1064px;
  }

  .title {
    display: block;
    text-align: left;
    font-size: 20px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 8px;
    color: #ffffff;
    margin-top: 81px;
    margin-left: 47px;

    @media (min-width: 768px) {
      font-size: 14px;
      letter-spacing: 6px;
      margin-top: 90px;
      margin-left: 83px;
      margin-bottom: 30px;
    }

    @media (min-width: 1280px) {
      font-size: 24px;
      letter-spacing: 12px;
      margin-top: 110px;
      margin-left: 115px;
      margin-bottom: 50px;
    }
    @media (min-width: 1920px) {
      font-size: 40px;
      letter-spacing: 20px;
      margin-top: 344px;
      margin-left: 170px;
      margin-bottom: 80px;
    }
  }
  .firstTitle {
    margin-bottom: 10px;
    font-size: 20px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 8px;
    color: #ffffff;
    text-transform: uppercase;
    
  }
  .secondTitle {
    margin-right: 38px;
    font-size: 20px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 8px;
    color: #ffffff;
    text-transform: uppercase;

    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
  }

  .mainText {
    display: grid;
    grid-template-areas:
      "mainText_first"
      "mainText_second";
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    letter-spacing: 1.6px;
    color: #ffffff;
    line-height: 2;

    @media (min-width: 768px) {
      grid-template-areas: "mainText_first mainText_second";
      font-size: 12px;
      letter-spacing: 1.2px;
      line-height: 2.5;
    }
    @media (min-width: 1280px) {
      font-size: 16px;
      letter-spacing: 1.6px;
    }
    @media (min-width: 1920px) {
      font-size: 24px;
      letter-spacing: 2.4px;
      line-height: 2;
    }

    .mainText_first {
      margin: 0px 38px 71px 47px;
      grid-area: mainText_first;

      @media (min-width: 768px) {
        margin-left: 83px;
        margin-bottom: 53px;
      }
      @media (min-width: 1280px) {
        margin-left: 115px;
        margin-bottom: 33px;
        margin-right: 65px;
      }
      @media (min-width: 1920px) {
        margin-left: 170px;
        margin-bottom: 77px;
        margin-right: 71px;
      }
    }
    .mainText_second {
      margin: 0px 38px 51px 47px;
      grid-area: mainText_second;

      @media (min-width: 768px) {
        margin-right: 35px;
        margin-left: 31px;
      }
      @media (min-width: 1280px) {
        margin-right: 79px;
      }
      @media (min-width: 1920px) {
        margin-right: 177px;
        margin-left: 0px;
      }
    }
  }

  .btnOurVis {
    a {
      text-decoration: none;
    }

    button {
      width: 229px;
      height: 63px;
      box-sizing: border-box;
      color: black;
      border: none;
      border-radius: 100px;
      opacity: 1;
      background-color: white;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.1s ease-out;
      font-size: 18px;
      font-weight: 400;
      filter: brightness(1.1);
      letter-spacing: 1.7px;
      font-family: "Gilroy-Bold", sans-serif;
      position: relative;
      overflow: hidden;
      box-shadow: 0 3px 8px 0 rgba(174, 177, 179, 0.5);
      margin: 0 auto;
      margin-bottom: 208px;

      @media (min-width: 768px) {
        width: 204px;
        height: 48px;
        margin-left: 83px;
        margin-bottom: 94px;
        border-radius: 43px;
        font-size: 16px;
        letter-spacing: 1.6px;
      }
      @media (min-width: 1280px) {
        width: 208px;
        height: 64px;
        margin-left: 115px;
        margin-bottom: 152px;
        font-size: 14px;
        letter-spacing: 1.4px;
      }
      @media (min-width: 1920px) {
        width: 312px;
        height: 86px;
        margin-left: 170px;
        margin-bottom: 239px;
        font-size: 24px;
        letter-spacing: 2.4px;
      }
    }
  }
  .titleQuote {
    height: 79px;
    padding: 40px 10vw 48px 0px;
    text-align: left;
    font-size: 14px;
    font-family: "Gilroy-Regular", sans-serif;
    letter-spacing: 1.4px;
    color: #ffffff;

    @media (max-width: 768px) {
      display: block;
    }
    @media (min-width: 768px) {
      padding: 0px;
      width: 80vw;
    }

    @media (min-width: 1280px) {
      padding: 0px;
      width: auto;
      font-size: 16px;
      margin-left: 0px;
      height: auto;
    }

    @media (min-width: 1920px) {
      font-size: 20px;
      letter-spacing: 2px;
      margin-right: 19vw;
    }

    .titleQuote_Line {
      display: none;
      @media (min-width: 1280px) {
        display: inline-block;
        margin-left: 10px;
      }
    
    
    }

    .titleQuote_Author {
      width: 100px;
      height: 14px;
      text-align: left;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.4px;
      color: #ffffff;
      opacity: 0.6;
      margin-top: 10px;

      @media (min-width: 1280px) {
        margin-top: 0px;
        display: inline;
        margin-left: 10px;
        font-size: 16px;
      }
      @media (min-width: 1920px) {
        font-size: 20px;
        letter-spacing: 2px;
      }
    }
  }


}
