.root {
  width: 100vw;
  height: auto;
  position: static;
  align-items: center;
  background-color: black;
  opacity: 1;
  overflow-x: hidden;

  .slider_main {
    display: block;
    height: auto;
    @media (min-width: 768px) {
      height: auto;
    }
    @media (min-width: 1280px) {
      height: auto;
    }
    @media (min-width: 1920px) {
      height: auto;
    }
  }

  .cur_foto {
    max-width: 100%;
    height: auto;
  }
}
