.engineRoot {
  position: absolute;
  top: 0;
  left: 0;

  .engineer_proj {
    height: auto;
    background-color: black;
  }

  .business {
    height: auto;
    background-color: black;
  }
  .business_dark_1 {
    display: list-item;
    position: relative;
    margin: auto;
    padding-bottom: 300px;
  }
  .business_palochka {
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: 180px;
    left: 28px;
    width: 3px;
    height: 120px;
    background-color: white;
    border-radius: 4px;
  }
  .business_text_chapter {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    top: 180px;
    left: 56px;
    max-width: 388px;
    margin: 0px;
    color: white;
    letter-spacing: 12.5px;
    font-size: 25px;
    font-family: "Gilroy-Bold", sans-serif;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .business_describe_chapter {
    padding-top: 456px;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    max-width: 72%;
    display: block;
    margin: 0 auto;
    border: none;
  }
  .business_light_1 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .business_foto_1 {
    display: list-item;
    position: relative;
    display: block;
    max-width: 100%;
    height: auto;
  }
  .business_paragraph_1 {
    padding-top: 45px;
    padding-bottom: 105px;
    max-width: 85%;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    display: block;
    margin: 0 auto;
  }
  .business_light_2 {
    display: list-item;
    position: relative;
    background-color: white;
    margin: auto;
  }
  .business_foto_2 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .business_paragraph_2 {
    padding-top: 105px;
    padding-bottom: 90px;
    display: block;
    max-width: 85%;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    margin: 0 auto;
  }
  .business_dark_3 {
    display: table;
    position: relative;
  }
  .business_foto_3 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .business_paragraph_3 {
    display: block;
    margin: 0 auto;
    max-width: 86%;
    letter-spacing: 1.6px;
    text-align: left;
    color: white;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    padding-top: 73px;
    padding-bottom: 71px;
  }
  .business_dark_4 {
    display: table;
    position: relative;
  }
  .business_foto_4 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .business_paragraph_4 {
    display: block;
    margin: 0 auto;
    max-width: 86%;
    letter-spacing: 1.6px;
    text-align: left;
    color: white;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    padding-top: 48px;
  }

  @media (min-width: 430px) {
    .business_palochka {
      left: 38px;
      height: 80px;
    }
    .business_text_chapter {
      left: 65px;
    }
  }

  @media (min-width: 768px) {
    .business {
      overflow: hidden;
    }
    .business_dark_1 {
      height: 600px;
      padding: 0px;
    }
    .business_palochka {
      top: 120px;
      left: 81px;
      width: 5px;
      height: 100px;
    }
    .business_text_chapter {
      top: 122px;
      left: 125px;
      letter-spacing: 13.5px;
      font-size: 27px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .business_describe_chapter {
      padding-top: 290px;
      max-width: 85.5%;
    }
    .business_light_1 {
      display: grid;
      grid-template-columns: [line1] 46.625% [line2] 53.375% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .business_foto_1 {
      width: 702px;
      height: 432px;
      padding-top: 0px;
      grid-column: line1 / line2;
      align-self: center;
      object-fit: cover;
      object-position: -80px;
    }
    .business_paragraph_1 {
      grid-column: line2 / line_end;
      grid-row: dark1 / dark2;
      padding: 0px;
      align-self: center;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      padding-left: 45px;
      padding-right: 32px;
    }
    .business_light_2 {
      display: grid;
      grid-template-columns: [line1] 46.625% [line2] 53.375% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .business_foto_2 {
      width: 406px;
      height: 278px;
      grid-column: line2 / line_end;
      grid-row: dark1 / dark2;
      padding: 0px;
      justify-self: center;
      align-self: center;
    }
    .business_paragraph_2 {
      grid-column: line1 / line2;
      padding: 0px;
      align-self: center;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 100%;
      padding-left: 43px;
      padding-right: 25px;
    }
    .business_dark_3 {
      display: grid;
      grid-template-columns: [line1] 46.625% [line2] 53.375% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .business_foto_3 {
      width: 723px;
      height: 391px;
      padding-bottom: 0px;
      grid-column: line1 / line2;
      align-self: center;
      object-fit: cover;
      object-position: -90px;
    }
    .business_paragraph_3 {
      grid-column: line2 / line_end;
      grid-row: dark1 / dark2;
      padding: 0px;
      align-self: center;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 100%;
      padding-left: 44px;
      padding-right: 46px;
    }
    .business_dark_4 {
      display: grid;
      grid-template-columns: [line1] 60.75% [line2] 39.25% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .business_foto_4 {
      width: 586px;
      height: 420px;
      padding: 0px;
      grid-column: line2 / line_end;
      align-self: center;
      object-fit: cover;
    }
    .business_paragraph_4 {
      grid-column: line1 / line2;
      grid-row: dark1 / dark2;
      padding: 0px;
      align-self: center;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 100%;
      padding-left: 54px;
      padding-right: 52px;
    }
  }

  @media (min-width: 1280px) {
    .business_dark_1 {
      height: 800px;
    }
    .business_palochka {
      top: 214px;
      left: 180px;
      height: 138px;
    }
    .business_text_chapter {
      top: 222px;
      left: 225px;
      max-width: 467px;
      letter-spacing: 20px;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .business_describe_chapter {
      padding-top: 516px;
      font-size: 22px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 80.5%;
    }
    .business_light_1 {
      grid-template-columns: [line1] 48.6% [line2] 51.4% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .business_foto_1 {
      width: 1013px;
      height: 623px;
      justify-self: center;
    }
    .business_paragraph_1 {
      padding-left: 56px;
      padding-right: 83px;
      font-size: 22px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .business_light_2 {
      grid-template-columns: [line1] 46.25% [line2] 53.75% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .business_foto_2 {
      width: 643px;
      height: 440px;
      justify-self: center;
    }
    .business_paragraph_2 {
      padding-left: 72px;
      padding-right: 40px;
      font-size: 22px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .business_dark_3 {
      grid-template-columns: [line1] 45.55% [line2] 54.45% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .business_foto_3 {
      width: 918px;
      height: 496px;
    }
    .business_paragraph_3 {
      padding-left: 65px;
      padding-right: 88px;
      font-size: 22px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .business_dark_4 {
      grid-template-columns: [line1] 54% [line2] 46% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .business_foto_4 {
      width: 690px;
      height: 496px;
      object-position: 0px;
      justify-self: center;
    }
    .business_paragraph_4 {
      padding-left: 72px;
      padding-right: 88px;
      font-size: 22px;
      font-family: "Gilroy-Regular", sans-serif;
    }
  }

  @media (min-width: 1920px) {
    .business_dark_1 {
      height: 1080px;
    }
    .business_palochka {
      top: 322px;
      left: 253px;
      height: 206px;
      width: 8px;
    }
    .business_text_chapter {
      top: 330px;
      left: 323px;
      letter-spacing: 32px;
      font-size: 64px;
      font-family: "Gilroy-Bold", sans-serif;
      max-width: 747px;
    }
    .business_describe_chapter {
      padding-top: 582px;
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      max-width: 80.5%;
    }
    .business_light_1 {
      grid-template-columns: [line1] 50% [line2] 50% [line_end];
      grid-template-rows: [dark1] 980px [dark2];
    }
    .business_foto_1 {
      width: 1070px;
      height: 659px;
    }
    .business_paragraph_1 {
      padding-left: 127px;
      padding-right: 101px;
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .business_light_2 {
      grid-template-columns: [line1] 50% [line2] 50% [line_end];
      grid-template-rows: [dark1] 645px [dark2] 155px;
    }
    .business_foto_2 {
      width: 945px;
      height: 645px;
    }
    .business_paragraph_2 {
      padding-left: 194px;
      padding-right: 35px;
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .business_dark_3 {
      grid-template-columns: [line1] 51.05% [line2] 49.95% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .business_foto_3 {
      width: 1109px;
      height: 600px;
    }
    .business_paragraph_3 {
      padding-left: 122px;
      padding-right: 81px;
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .business_dark_4 {
      grid-template-columns: [line1] 52.3% [line2] 47.7% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .business_foto_4 {
      width: 916px;
      height: 657px;
    }
    .business_paragraph_4 {
      padding-left: 177px;
      padding-right: 95px;
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
  }
}
