.content {
  width: 100vw;
  height: 932px;
  position: absolute;
  top: -64px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background: #000000;
  opacity: 1;
  overflow-x: hidden;

  @media (min-width: 768px) {
    height: 750px;
    width: calc(100vw - 17px);
  
  }

  @media (min-width: 1280px) {
    height: 782px;
  }

  @media (min-width: 1920px) {
    height: 1128px;
  }

  .imgContainer {
    margin-top: 222px;
    margin-left: 60px;

    @media (min-width: 430px) {
      margin-left: 90px;
    }
    @media (min-width: 768px) {
      margin-left: 0px;
      display: grid;
    }
    @media (min-width: 1280px) {
      margin-right: 350px;
    }

    @media (min-width: 1920px) {
      margin-right: 450px;
      margin-top: 333px;
    }

    .Img_D {
      width: 112px;
      height: 130px;
      margin-right: 19px;
      background-image: url("../../img/D_sign_line.png");
      background-size: cover;
      display: inline-block;

      @media (min-width: 768px) {
        width: 144px;
        height: 167px;
        background-image: url("../../img/DL_800x600.png");
        grid-area: ImgD;
      }

      @media (min-width: 1280px) {
        width: 155px;
        height: 180px;
        background-image: url("../../img/DL_1280x800.png");
      }

      @media (min-width: 1280px) {
        width: 155px;
        height: 180px;
        background-image: url("../../img/DL_1280x800.png");
      }

      @media (min-width: 1920px) {
        width: 232px;
        height: 268px;
        background-image: url("../../img/DL_1920x1080.png");
      }
    }

    .Img_8 {
      width: 81px;
      height: 130px;
      background-image: url("../../img/8_sign_line.png");
      background-size: cover;
      display: inline-block;

      @media (min-width: 768px) {
        width: 104px;
        height: 167px;
        margin-left: 164px;
        background-image: url("../../img/8L_800x600.png");
        grid-area: Img8;
        z-index: 5;
      }

      @media (min-width: 1280px) {
        width: 112px;
        height: 180px;
        margin-left: 180px;
        background-image: url("../../img/8L_1280x800.png");
      }

      @media (min-width: 1920px) {
        width: 168px;
        height: 268px;
        margin-left: 270px;
        background-image: url("../../img/8L_1920x1080.png");
      }
    }

    .bannerNameCompany800 {
      visibility: hidden;

      @media (min-width: 768px) {
        visibility: visible;
        width: 301px;
        height: 29px;
        text-align: left;
        font-family: "Gilroy-Bold", sans-serif;
        font-size: 26px;
        letter-spacing: 15px;
        color: #ffffff;
        text-transform: uppercase;
        grid-area: ImgName;
        margin-left: 263px;
        padding-top: 96px;
        z-index: 0;
      }

      @media (min-width: 1280px) {
        width: 401px;
        height: 38px;
        font-size: 32px;
        letter-spacing: 20px;
        margin-left: 282px;
        padding-top: 99px;
      }

      @media (min-width: 1920px) {
        width: 677px;
        height: 64px;
        font-size: 54px;
        letter-spacing: 33.75px;
        margin-left: 425px;
        padding-top: 144px;
      }
    }
  }

  .bannerE {
    visibility: hidden;

    @media (min-width: 768px) {
      visibility: visible;
      top: 270px;
      width: 13px;
      height: 29px;
      text-align: left;
      font-family: "Gilroy-Bold", sans-serif;
      font-size: 24px;
      letter-spacing: 15px;
      color: white;
      text-transform: capitalize;
      opacity: 1;
    }
  }

  .bannerNameCompany {
    padding: 70px 84px 255px 30px;
    width: 263px;
    height: 25px;
    text-align: left;
    font-family: "Gilroy-Bold", sans-serif;
    font-size: 21px;
    letter-spacing: 13.13px;
    color: #ffffff;
    text-transform: uppercase;
    opacity: 1;
    margin-left: 70px;

    @media (min-width: 430px) {
      padding: 70px 84px 255px 45px;
    }

    @media (min-width: 768px) {
      visibility: hidden;
      padding: 0px;
    }
  }

  .bannerText {
    width: 290px;
    height: 16px;
    padding: 0px 59px 65px 65px;
    text-align: left;
    font-size: 12px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 2.8px;
    color: #ffffff;
    text-transform: uppercase;

    @media (min-width: 430px) {
      display: none;
      width: 330px;
      padding: 0px 30px 65px 80px;
      font-size: 14px;
      text-align: center;
    }

    @media (max-width: 768px) {
      display: block;
    }
    @media (min-width: 768px) {
      width: 300px;
      height: 14px;
      font-size: 12px;
      letter-spacing: 1.88px;
      margin-left: 410px;
      padding: 0px;
      white-space: pre-wrap;

      // animation: 0.3s ease-in-out 1.8s alternate enterText;

      // @keyframes enterText {
      //   from {
      //     transform: translateX(-300px);
      //     opacity: 0;
      //   }
      //   to {
      //     transform: translateX(0);
      //     opacity: 1;
      //   }
      // }
    }

    @media (min-width: 1280px) {
      width: 385px;
      height: 16px;
      font-size: 16px;
      letter-spacing: 2.63px;
      margin-left: 832px;
      margin-right: 40px;
    }

    @media (min-width: 1920px) {
      width: 710px;
      height: 29px;
      font-size: 26px;
      letter-spacing: 4.8px;
      margin-left: 1230px;
      margin-right: 235px;
      font-family: "Gilroy-Regular", sans-serif;
    }
  }
  .bannerCreative {
    display: none;
    margin-left: 610px;
    margin-top: 30px;
    padding-bottom: 5px;
    font-size: 12px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 2.6px;
    color: #ffffff;
    text-transform: uppercase;
    white-space: pre-wrap;

    @media (min-width: 768px) {
      // animation: 0.3s ease-in-out 0s alternate enter;

      // @keyframes enter {
      //   from {
      //     transform: translateX(-300px);
      //     opacity: 0;
      //   }
      //   to {
      //     transform: translateX(0);
      //     opacity: 1;
      //   }
      // }
    }

    @media (min-width: 1280px) {
      margin-left: 1065px;
      font-size: 16px;
    }
    @media (min-width: 1920px) {
      font-size: 26px;
      letter-spacing: 4.8px;
      font-family: "Gilroy-Regular", sans-serif;
      margin-left: 1450px;
      padding-bottom: 15px;
    }
  }
  .bannerEngineer {
    display: none;
    margin-left: 595px;
    padding-bottom: 5px;
    font-size: 12px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 2.6px;
    color: #ffffff;
    text-transform: uppercase;
    white-space: pre-wrap;

    @media (min-width: 768px) {
      // animation: 0.3s ease-out 0.4s alternate enter2;

      // @keyframes enter2 {
      //   from {
      //     transform: translateX(-300px);
      //     opacity: 0;
      //   }
      //   to {
      //     transform: translateX(0);
      //     opacity: 1;
      //   }
      // }
    }

    @media (min-width: 1280px) {
      margin-left: 1047px;
      font-size: 16px;
    }
    @media (min-width: 1920px) {
      font-size: 26px;
      letter-spacing: 4.8px;
      font-family: "Gilroy-Regular", sans-serif;
      margin-left: 1420px;
      padding-bottom: 15px;
    }
  }
  .bannerTechnology {
    display: none;
    margin-left: 585px;
    padding-bottom: 5px;
    font-size: 12px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 2.6px;
    color: #ffffff;
    text-transform: uppercase;

    @media (min-width: 768px) {
      // animation: 0.3s ease-in-out 0.8s alternate enter3;

      // @keyframes enter3 {
      //   from {
      //     transform: translateX(-300px);
      //     opacity: 0;
      //   }
      //   to {
      //     transform: translateX(0);
      //     opacity: 1;
      //   }
      // }
    }
    @media (min-width: 1280px) {
      margin-left: 1038px;
      font-size: 16px;
    }
    @media (min-width: 1920px) {
      font-size: 26px;
      letter-spacing: 4.8px;
      font-family: "Gilroy-Regular", sans-serif;
      margin-left: 1400px;
      padding-bottom: 15px;
    }
  }

  .bannerEfficient {
    display: none;
    padding-bottom: 5px;
    margin-left: 567px;
    font-size: 12px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 2.6px;
    color: #ffffff;
    text-transform: uppercase;
    white-space: pre-wrap;

    @media (min-width: 768px) {
      // animation: 0.3s ease-in-out 1.1s alternate enter4;

      // @keyframes enter4 {
      //   from {
      //     transform: translateX(-300px);
      //     opacity: 0;
      //   }
      //   to {
      //     transform: translateX(0);
      //     opacity: 1;
      //   }
      // }
    }
    @media (min-width: 1280px) {
      margin-left: 1015px;
      font-size: 16px;
    }
    @media (min-width: 1920px) {
      font-size: 26px;
      letter-spacing: 4.8px;
      font-family: "Gilroy-Regular", sans-serif;
      margin-left: 1357px;
      padding-bottom: 15px;
    }
  }

  .bannerFullService {
    display: none;
    font-size: 12px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 2.6px;
    color: #ffffff;
    text-transform: uppercase;
    white-space: pre-wrap;
    padding-bottom: 5px;
    margin-left: 459px;

    @media (min-width: 768px) {
      // animation: 0.3s ease-in-out 1.5s alternate enter5;

      // @keyframes enter5 {
      //   from {
      //     transform: translateX(-300px);
      //     opacity: 0;
      //   }
      //   to {
      //     transform: translateX(0);
      //     opacity: 1;
      //   }
      // }
    }
    @media (min-width: 1280px) {
      margin-left: 880px;
      font-size: 16px;
    }
    @media (min-width: 1920px) {
      font-size: 26px;
      letter-spacing: 4.8px;
      font-family: "Gilroy-Regular", sans-serif;
      margin-left: 1135px;
      padding-bottom: 15px;
    }
  }

  .visibleBlock {
    @media (min-width: 768px) {
      display: block;
      opacity: 0;
    }
  }
  .visibleBlock_1 {
    @media (min-width: 768px) {
      display: block;
      opacity: 1;
    }
  }
  .visibleAnimation {
    animation: 1s out6;
    @keyframes out6 {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }
  .visibleD8 {
    @media (min-width: 768px) {
      display: inline-block;
      opacity: 0;
    }
  }
  .visibleD8_2 {
    @media (min-width: 768px) {
      display: inline-block;
      opacity: 1;

      animation: 0.5s ease-in-out 0s alternate enterD8s;

      @keyframes enterD8s {
        from {
          transform: translateY(-400px);
          opacity: 0;
        }
        to {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  .visibleD8_3 {
    @media (min-width: 768px) {
      display: inline-block;
      opacity: 1;

      animation: 0.5s ease-in-out 0s alternate enterCmp;

      @keyframes enterCmp {
        from {
          transform: translateX(-400px);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }

  .visibleBlock_2 {
    @media (min-width: 768px) {
      display: block;
      opacity: 1;
      animation: 0.3s ease-in-out 0s alternate enterEx;

      @keyframes enterEx {
        from {
          transform: translateX(-300px);
          opacity: 0;
        }
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }
}
