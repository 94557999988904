.engineRoot {
  position: absolute;
  top: 0;
  left: 0;

  .engineer_proj {
    height: auto;
    background-color: black;
  }
  
  .music {
    height: auto;
    padding-bottom: 79px;
    background-color: black;
  }
  .music_dark_1 {
    display: list-item;
    position: relative;
    margin: auto;
  }
  .music_palochka {
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: 180px;
    left: 18px;
    width: 3px;
    height: 80px;
    background-color: white;
    border-radius: 4px;
  }
  .music_text_chapter {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    top: 180px;
    left: 45px; 
    max-width: 500px;
    margin: 0px;
    color: white;
    letter-spacing: 12.5px;
    font-size: 25px;
    font-family: "Gilroy-Bold", sans-serif;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .music_describe_chapter {
    padding-top: 420px;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    max-width: 72%;
    display: block;
    margin: 0 auto;
    border: none;
    line-height: 2;
  }
  .music_dark_2 {
    display: list-item;
    position: relative;
    margin: auto;
  }
  .music_foto_1 {
    display: list-item;
    position: relative;
    display: block;
    max-width: 100%;
    height: auto;
    padding-top: 175px;
  }
  .music_paragraph_1 {
    padding-top: 95px;
    padding-bottom: 95px;  
    max-width: 87%;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    display: block;
    margin: 0 auto;
    line-height: 2;
  }
  .music_light_1 {
    display: list-item;
    position: relative;
    min-height: 320px;
    background-color: white;
    margin: auto;
  }
  .music_foto_2 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
    padding-top: 50px;
  }
  .music_paragraph_2 {
    padding-top: 75px;
    padding-bottom: 57px;
    display: block;
    max-width: 85%;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    margin: 0 auto;
    line-height: 2;
  }
  .music_dark_3 {
    display: table;
    position: relative;
    padding-bottom: 82.5px;
  }
  .music_foto_3 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
    padding-bottom: 40px;
  }
  .music_light_palochka_1 {
    position: relative;
    max-width: 88.1%;
    height: 3px;
    background-color: white;
    margin: 0 auto;
    border-radius: 4px;
  }
  .music_head_paragraph_2 {
    position: relative;
    top: 7px;
    max-width: 85%;
    color: white;
    letter-spacing: 1.6px;
    text-align: center;
    font-size: 21px;
    font-family: "Gilroy-Regular", sans-serif;
    display: block;
    margin: 0 auto;
    border: none;
    padding-bottom: 17.5px;
  }
  .music_numeric_1,.music_numeric_2,.music_numeric_3,.music_numeric_4 {
    display: table-cell;
    color: white;
    letter-spacing: 8.5px;
    text-align: left;
    font-size: 17px;
    font-family: "Gilroy-Bold", sans-serif;
    padding-left: 22px;
    vertical-align: middle;
  }
  .music_paragraph_3_1,.music_paragraph_3_2,.music_paragraph_3_3,.music_paragraph_3_4 {
    display: table-cell;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
    line-height: 2;
  }
  .music_dark_4 {
    display: table;
    position: relative;
    padding-bottom: 117.5px;
  }
  .music_foto_4 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
    padding-bottom: 17.5px;
  }
  .music_numeric_5,.music_numeric_6,.music_numeric_7 {
    display: table-cell;
    color: white;
    letter-spacing: 8.5px;
    text-align: left;
    font-size: 17px;
    font-family: "Gilroy-Bold", sans-serif;
    padding-left: 22px;
    vertical-align: middle;
  }
  .music_paragraph_4_1,.music_paragraph_4_2,.music_paragraph_4_3 {
    display: table-cell;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 17.5px;
    padding-bottom: 17.5px;
    line-height: 2;
  }
  .music_dark_5 {
    position: relative;
  }
  .music_paragraph_5 {
    display: block;
    max-width: 85%;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    margin: 0 auto;
    line-height: 2;
  }
  
  @media(min-width: 430px) {
    .music_palochka {
      left: 38px;
    }
    .music_text_chapter {
      left: 65px;
    }
  }
  
  @media(min-width: 768px) {
    .music {
      overflow: hidden;
      padding-bottom: 79px;
    }
    .music_dark_1 {
      height: 600px;
    }
    .music_palochka {
      top: 120px;
      left: 84px;
      width: 5px;
      height: 100px;
    }
    .music_text_chapter {
      top: 122px;
      left: 125px;
      letter-spacing: 13.5px;
      font-size: 27px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .music_describe_chapter {
      padding-top: 290px;
      max-width: 85.5%;
    }
    .music_dark_2 {
      display: grid;
      grid-template-columns: [line1] 62.5% [line2] 37.5% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .music_foto_1 {
      width: 580px;
      height: 410px;
      padding-top: 0px;
      grid-column: line2 / line_end;
      align-self: center;
      object-fit: cover;
      object-position: 0px;
    }
    .music_paragraph_1 {
      grid-column: line1 / line2;
      grid-row: dark1 / dark2;
      padding: 0px;
      align-self: center;
      padding-left: 45px;
      padding-right: 45px;
    }
    .music_light_1 {
      display: grid;
      grid-template-columns: [line1] 48.125% [line2] 51.875% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .music_foto_2 {
      width: 340px;
      height: 208px;
      grid-column: line1 / line2;
      padding: 0px;
      justify-self: center;
      align-self: center;
    }
    .music_paragraph_2 {
      grid-column: line2 / line_end;
      padding: 0px;
      align-self: center;
      max-width: 100%;
      padding-left: 25px;
      padding-right: 25px;
    }
    .music_dark_3 {
      display: grid;
      grid-template-columns: [line1] 64.375% [line2] 35.625% [line_end];
      grid-template-rows: [row1] 102px [row1_end] 55px [row2] 48px [row2_end] 32px [row3] 40px [row3_end] 32px [row4] 144px [row4_end] 32px [row5] 120px [row5_end] 75px [row6];
      padding-bottom: 0px;
    }
    .music_foto_3 {
      width: 417px;
      height: 368px;
      padding-bottom: 0px;
      grid-column: line2 / line_end;
      grid-row: row2 / row5_end;
      align-self: center;
      object-fit: cover;
      object-position: 0px;
    }
    .music_light_palochka_1 {
      grid-column: line1 / line2;
      grid-row: row1 / row2;
      max-width: 100%;
      width: 45vw;
      height: 4px;
      margin: 0px;
      margin-top: 110px;
      justify-self: center;
    }
    .music_head_paragraph_2 {
      grid-column: line1 / line2;
      grid-row: row1 / row2;
      padding: 52px 0px 0px 0px;
      height: 30px;
      letter-spacing: 3.6px;
      margin: 0px;
      justify-self: center;
      font-size: 21px;
      font-family: "Gilroy-Medium", sans-serif;
    }
    .music_row_1 {
      grid-column: line1 / line2;
      grid-row: row2 / row2_end;
      align-self: center;
    }
    .music_numeric_1,.music_numeric_2,.music_numeric_3,.music_numeric_4 {
      padding-left: 40px;
    }
    .music_paragraph_3_1,.music_paragraph_3_2,.music_paragraph_3_3,.music_paragraph_3_4{
      padding-top: 0px;
      padding-bottom: 0px;
      vertical-align: middle;
    }
    .music_row_2 {
      grid-column: line1 / line2;
      grid-row: row3 / row3_end;
      align-self: center;
    }
    .music_row_3 {
      grid-column: line1 / line2;
      grid-row: row4 / row4_end;
      align-self: center;
    }
    .music_row_4 {
      grid-column: line1 / line2;
      grid-row: row5 / row5_end;
      align-self: center;
    }
    .music_dark_4 {
      display: grid;
      grid-template-columns: [line1] 46.375% [line2] 53.625% [line_end];
      grid-template-rows: [row1_start] 100px [row1] 72px [row1_end] 32px [row2] 120px [row2_end] 32px [row3] 144px [row3_end] 100px [row4];
      padding-bottom: 0px;
    }
    .music_foto_4 {
      width: 608px;
      height: 417px;
      padding: 0px;
      grid-column: line1 / line2;
      grid-row: row1_start / row4;
      align-self: center;
      object-fit: cover;
      object-position: -236px;
    }
    .music_row_5 {
      grid-column: line2 / line_end;
      grid-row: row1 / row1_end;
      align-self: center;
    }
    .music_numeric_5,.music_numeric_6,.music_numeric_7 {
      padding-left: 40px;
    }
    .music_paragraph_4_1,.music_paragraph_4_2,.music_paragraph_4_3{
      padding-top: 0px;
      padding-bottom: 0px;
      vertical-align: middle;
    }
    .music_row_6 {
      grid-column: line2 / line_end;
      grid-row: row2 / row2_end;
      align-self: center;
    }
    .music_row_7 {
      grid-column: line2 / line_end;
      grid-row: row3 / row3_end;
      align-self: center;
    }
    .music_paragraph_5 {
      padding-top: 56px;
    }
  }
  
  @media(min-width: 1024px) {
    .music_foto_4 {
      object-position: -133px;
    }  
  }
  
  @media(min-width: 1152px) {
    .music_foto_4 {
      object-position: -73px;
    }  
  }
  
  @media(min-width: 1280px) {
    .music_dark_1{
      height: 800px;
    }
    .music_palochka {
      top: 214px;
      left: 180px;
      height: 138px;
    }
    .music_text_chapter {
      top: 222px;
      left: 225px;
      letter-spacing: 20px;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .music_describe_chapter {
      padding-top: 494px;
      font-size: 20px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 81.5%;
    }
    .music_dark_2 {
      grid-template-columns: [line1] 46% [line2] 54% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .music_foto_1 {
      width: 859px;
      height: 676px;
      justify-self: center;
    }
    .music_paragraph_1 {
      padding-left: 85px;
      padding-right: 85px;
      font-size: 20px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .music_light_1 {
      grid-template-columns: [line1] 53.33% [line2] 46.67% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .music_foto_2 {
      width: 640px;
      height: 391px;
      justify-self: center;
    }
    .music_paragraph_2 {
      padding-left: 45px;
      padding-right: 72px;
      font-size: 20px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .music_dark_3 {
      grid-template-columns: [line1] 54.67% [line2] 45.33% [line_end];
      grid-template-rows: [row1] 117px [row1_end] 122px [row2] 60px [row2_end] 36px [row3] 48px [row3_end] 36px [row4] 230px [row4_end] 36px [row5] 180px [row5_end] 75px [row6]; 
      padding-bottom: 0px;
    }
    .music_foto_3 {
      width: 544px;
      justify-self: center;
    }
    .music_light_palochka_1 {
      margin-top: 113px;
      height: 4px;
    }
    .music_head_paragraph_2 {
      padding-top: 67px;
      font-size: 24px;
      font-family: "Gilroy-Medium", sans-serif;
    }
    .music_numeric_1,.music_numeric_2,.music_numeric_3,.music_numeric_4 {
      padding-left: 76px;
      letter-spacing: 12.5px;
      font-size: 25px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .music_paragraph_3_1,.music_paragraph_3_2,.music_paragraph_3_3,.music_paragraph_3_4 {
      padding-right: 60px;
      font-size: 20px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .music_dark_4{
      grid-template-columns: [line1] 54% [line2] 46% [line_end];
      grid-template-rows: [row1_start] 196px [row1] 100px [row1_end] 36px [row2] 200px [row2_end] 36px [row3] 214px [row3_end] 196px [row4];
    }
    .music_foto_4 {
      width: 708px;
      height: 571px;
      object-position: 0px;
    }
    .music_numeric_5,.music_numeric_6,.music_numeric_7 {
      padding-left: 48px;
      letter-spacing: 12.5px;
      font-size: 25px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .music_paragraph_4_1,.music_paragraph_4_2,.music_paragraph_4_3 {
      padding-right: 80px;
      font-size: 20px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .music_paragraph_5 {
      max-width: 90%;
      font-size: 20px;
      font-family: "Gilroy-Regular", sans-serif;

    }
  }
  
  @media(min-width: 1920px) {
    .music {
      padding-bottom: 270px;
    }
    .music_dark_1{
      height: 1080px;
    }
    .music_palochka {
      top: 322px;
      left: 253px;
      height: 206px;
      width: 8px;
    }
    .music_text_chapter {
      top: 330px;
      left: 323px;
      letter-spacing: 32px;
      font-size: 64px;
      font-family: "Gilroy-Bold", sans-serif;
      max-width: 721px;
    }
    .music_describe_chapter {
      padding-top: 582px;
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      max-width: 83.5%;
    }
    .music_dark_2 {
      grid-template-columns: [line1] 50% [line2] 50% [line_end];
      grid-template-rows: [dark1] 1080px [dark2];
    }
    .music_foto_1 {
      width: 960px;
      height: 759px;
      justify-self: center;
    }
    .music_paragraph_1 {
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      padding: 0 112px 0 126px;
    }
    .music_light_1 {
      grid-template-columns: [line1] 49.42% [line2] 50.58% [line_end];
      grid-template-rows: [dark1] 1080px [dark2];
    }
    .music_foto_2 {
      width: 861px;
      height: 527px;
    }
    .music_paragraph_2 {
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      padding: 0 98px 0 151px;
    }
    .music_dark_3 {
      grid-template-columns: [line1] 55.73% [line2] 44.27% [line_end];
      grid-template-rows: [row1] 187px [row1_end] 122px [row2] 96px [row2_end] 36px [row3] 106px [row3_end] 36px [row4] 310px [row4_end] 36px [row5] 290px [row5_end] 191px [row6];
    }
    .music_foto_3 {
      width: 762px;
      height: 691px;
    }
    .music_light_palochka_1 {
      margin-top: 195px;
      height: 6px;
      width: 717px;
    }
    .music_head_paragraph_2 {
      padding-top: 95px;
      font-size: 40px;
      font-family: "Gilroy-Medium", sans-serif;
      letter-spacing: 8px;
    }
    .music_numeric_1,.music_numeric_2,.music_numeric_3,.music_numeric_4 {
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 20px;
    }
    .music_paragraph_3_1,.music_paragraph_3_2,.music_paragraph_3_3,.music_paragraph_3_4 {
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2px;
    }
    .music_dark_4{
      grid-template-columns: [line1] 49.05% [line2] 50.95% [line_end];
      grid-template-rows: [row1_start] 196px [row1] 96px [row1_end] 36px [row2] 180px [row2_end] 36px [row3] 220px [row3_end] 196px [row4];
    }
    .music_foto_4 {
      width: 884px;
      height: 640px;
    }
    .music_numeric_5,.music_numeric_6,.music_numeric_7 {
      padding-left: 48px;
      letter-spacing: 20px;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .music_paragraph_4_1,.music_paragraph_4_2,.music_paragraph_4_3 {
      padding-right: 80px;
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2px;
    }
    .music_dark_5 {
      height: auto;
      padding-bottom: 0px;
    }
    .music_paragraph_5 {
      max-width: 85%;
      font-size: 30px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2px;
    }
  }
}
