.engineRoot {
  position: absolute;
  top: 0;
  left: 0;

  .engineer_proj {
    height: auto;
    background-color: black;
  }

  .energy {
    height: auto;
    background-color: black;
  }
  .energy_dark_1 {
    display: list-item;
    position: relative;
    margin: auto;
  }
  .energy_palochka {
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: 180px;
    left: 12px;
    width: 3px;
    height: 80px;
    background-color: white;
    border-radius: 4px;
  }
  .energy_text_chapter {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    top: 184px;
    left: 28px;
    max-width: 388px;
    margin: 0px;
    color: white;
    letter-spacing: 10px;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
  }
  .energy_describe_chapter {
    padding-top: 320px;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    max-width: 71.5%;
    display: block;
    margin: 0 auto;
    border: none;
    padding-bottom: 221px;
    line-height: 2;
  }
  .energy_light_1 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .energy_foto_1 {
    display: block;
    max-width: 100%;
    height: auto;
  }
  .energy_dark_palochka_1 {
    max-width: 81%;
    height: 3px;
    background-color: black;
    margin: 0 auto;
    margin-top: 48px;
    border-radius: 4px;
  }
  .energy_article_1 {
    text-align: center;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 1.6px;
    padding-top: 16px;
  }
  .energy_paragraph_1 {
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    padding: 31px 0px 48px 0px;
    max-width: 81%;
    margin: 0 auto;
    line-height: 2;
  }
  .energy_light_2 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .energy_foto_2 {
    display: list-item;
    position: relative;
    max-width: 100%;
  }
  .energy_dark_palochka_2 {
    max-width: 81%;
    height: 3px;
    background-color: black;
    margin: 0 auto;
    margin-top: 48px;
    border-radius: 4px;
  }
  .energy_article_2 {
    text-align: center;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 1.6px;
    padding-top: 16px;
    max-width: 65%;
    margin: 0 auto;
  }
  .energy_paragraph_2 {
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    letter-spacing: 1.6px;
    max-width: 81%;
    margin: 0 auto;
    padding: 48px 0 48px 0;
    line-height: 2;
  }
  .energy_foto_4 {
    display: list-item;
    position: relative;
    max-width: 100%;
  }
  .energy_foto_5 {
    display: list-item;
    position: relative;
    max-width: 100%;
  }
  .energy_light_3 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .energy_foto_3 {
    display: list-item;
    position: relative;
    max-width: 100%;
  }
  .energy_dark_palochka_3 {
    max-width: 81%;
    height: 3px;
    background-color: black;
    margin: 0 auto;
    margin-top: 48px;
    border-radius: 4px;
  }
  .energy_article_3 {
    text-align: center;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 1.6px;
    padding-top: 16px;
    max-width: 51.5%;
    margin: 0 auto;
  }
  .energy_paragraph_3 {
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    letter-spacing: 1.6px;
    max-width: 81%;
    margin: 0 auto;
    padding: 48px 0 36px 0;
    line-height: 2;
  }
  .energy_light_4 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .energy_foto_4 {
    display: list-item;
    position: relative;
    max-width: 100%;
  }
  .energy_dark_palochka_4 {
    max-width: 81%;
    height: 3px;
    background-color: black;
    margin: 0 auto;
    margin-top: 48px;
    border-radius: 4px;
  }
  .energy_article_4 {
    text-align: center;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 1.6px;
    padding-top: 16px;
    max-width: 61.5%;
    margin: 0 auto;
  }
  .energy_paragraph_4 {
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    letter-spacing: 1.6px;
    max-width: 81%;
    margin: 0 auto;
    padding: 48px 0 80px 0;
    line-height: 2;
  }
  .energy_dark_5 {
    display: list-item;
    position: relative;
    padding-bottom: 79px;
  }
  .energy_light_palochka_5 {
    max-width: 81%;
    height: 3px;
    background-color: white;
    margin: 0 auto;
    margin-top: 40px;
    border-radius: 4px;
  }
  .energy_article_5 {
    text-align: center;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 1.6px;
    padding-top: 16px;
    max-width: 66%;
    margin: 0 auto;
    color: white;
  }
  .energy_paragraph_5 {
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    letter-spacing: 2.1px;
    text-align: left;
    color: white;
    max-width: 81%;
    margin: 0 auto;
    padding: 48px 0 48px 0;
    line-height: 2;
  }

  @media (min-width: 430px) {
    .energy_palochka {
      left: 38px;
    }
    .energy_text_chapter {
      left: 65px;
      font-size: 20px;
    }
  }

  @media (min-width: 768px) {
    .energy {
      overflow: hidden;
    }
    .energy_dark_1 {
      height: 600px;
    }
    .energy_palochka {
      top: 120px;
      left: 84px;
      width: 5px;
      height: 100px;
    }
    .energy_text_chapter {
      top: 122px;
      left: 125px;
      letter-spacing: 13.5px;
      font-size: 27px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .energy_describe_chapter {
      padding-top: 263px;
      max-width: 83.5%;
      padding-bottom: 0px;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
    }
    .energy_light_1 {
      display: grid;
      grid-template-columns: [line1] 46.375% [line2] 53.625% [line_end];
      grid-template-rows: 70px [row_start] 13px [row1] 335px [row2];
    }
    .energy_foto_1 {
      width: 371px;
      height: 348px;
      padding-top: 0px;
      grid-column: line1 / line2;
      grid-row: row_start / row2;
      object-fit: cover;
      justify-self: center;
      align-self: center;
    }
    .energy_dark_palochka_1 {
      grid-column: line2 / line_end;
      grid-row: row1 / row2;
      max-width: 100%;
      height: 4px;
      margin: 0 58px 0 69px;
    }
    .energy_article_1 {
      grid-column: line2 / line_end;
      grid-row: row1 / row2;
      font-size: 18px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
      padding-top: 20px;
    }
    .energy_paragraph_1 {
      grid-column: line2 / line_end;
      grid-row: row1 / row2;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
      padding: 55px 58px 0 69px;
      align-self: center;
      max-width: 100%;
    }
    .energy_light_2 {
      display: grid;
      grid-template-columns: [line1] 55.75% [line2] 44.25% [line_end];
      grid-template-rows: 106px [row_start] 15px [row1] 380px [row2];
    }
    .energy_foto_2 {
      width: 354px;
      height: 385px;
      grid-column: line2 / line_end;
      grid-row: row_start / row2;
      padding: 0px;
      object-fit: cover;
      justify-self: center;
      align-self: center;
    }
    .energy_dark_palochka_2 {
      grid-column: line1 / line2;
      grid-row: row1 / row2;
      width: 75%;
      height: 4px;
      margin: 0 0px 0 35px;
    }
    .energy_article_2 {
      grid-column: line1 / line2;
      grid-row: row1 / row2;
      font-size: 18px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
      max-width: 100%;
      padding: 20px 79px 0 43px;
    }
    .energy_paragraph_2 {
      grid-column: line1 / line2;
      grid-row: row1 / row2;
      padding: 93px 90px 0 54px;
      max-width: 100%;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
      align-self: center;
    }
    .energy_light_3 {
      display: grid;
      grid-template-columns: [line1] 46.375% [line2] 53.625% [line_end];
      grid-template-rows: 98px [row_start] 58px [row1] 363px [row2];
    }
    .energy_foto_3 {
      width: 371px;
      height: 421px;
      padding-top: 0px;
      grid-column: line1 / line2;
      grid-row: row_start / row2;
      object-fit: cover;
      justify-self: center;
      align-self: center;
    }
    .energy_dark_palochka_3 {
      grid-column: line2 / line_end;
      grid-row: row1 / row2;
      max-width: 100%;
      height: 4px;
      margin: 0 58px 0 69px;
    }
    .energy_article_3 {
      grid-column: line2 / line_end;
      grid-row: row1 / row2;
      font-size: 18px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
      padding-top: 20px;
    }
    .energy_paragraph_3 {
      grid-column: line2 / line_end;
      grid-row: row1 / row2;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
      padding: 55px 58px 0 69px;
      align-self: center;
      max-width: 100%;
    }
    .energy_light_4 {
      display: grid;
      grid-template-columns: [line1] 55.75% [line2] 44.25% [line_end];
      grid-template-rows: 83px [row_start] 26px [row1] 359px [row2] 96px;
    }
    .energy_foto_4 {
      width: 354px;
      height: 385px;
      grid-column: line2 / line_end;
      grid-row: row_start / row2;
      padding: 0px;
      object-fit: cover;
      justify-self: center;
      align-self: center;
    }
    .energy_dark_palochka_4 {
      grid-column: line1 / line2;
      grid-row: row1 / row2;
      max-width: 100%;
      height: 4px;
      margin: 0 90px 0 54px;
    }
    .energy_article_4 {
      grid-column: line1 / line2;
      grid-row: row1 / row2;
      font-size: 18px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
      max-width: 100%;
      padding: 20px 79px 0 43px;
    }
    .energy_paragraph_4 {
      grid-column: line1 / line2;
      grid-row: row1 / row2;
      padding: 93px 90px 0 54px;
      max-width: 100%;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
      align-self: center;
    }
    .energy_light_palochka_5 {
      margin-top: 54px;
      max-width: 84%;
    }
    .energy_article_5 {
      max-width: 83.5%;
      font-size: 18px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
    }
    .energy_paragraph_5 {
      text-align: center;
      font-size: 12px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
      padding: 25px 0 36px 0;
    }
    .energy_foto_5 {
      margin: 0 auto;
      object-fit: cover;
      max-width: 100%;
    }
  }

  @media (min-width: 1280px) {
    .energy_dark_1 {
      height: 800px;
    }
    .energy_palochka {
      top: 214px;
      left: 180px;
      height: 138px;
      width: 5px;
    }
    .energy_text_chapter {
      top: 222px;
      left: 225px;
      letter-spacing: 20px;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .energy_describe_chapter {
      padding-top: 417px;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
      max-width: 85.5%;
    }
    .energy_light_1 {
      grid-template-columns: [line1] 49% [line2] 51% [line_end];
      grid-template-rows: 69px [row_start] 36px [row1] 430px [row2];
    }
    .energy_foto_1 {
      width: 627px;
      height: 419px;
    }
    .energy_dark_palochka_1 {
      height: 5px;
      margin: 0 72px 0 98px;
    }
    .energy_article_1 {
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 8.4px;
      padding: 10px 62px 0px 87px;
    }
    .energy_paragraph_1 {
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
      padding: 20px 95px 0 121px;
    }
    .energy_light_2 {
      grid-template-columns: [line1] 54.140625% [line2] 44.859375% [line_end];
      grid-template-rows: 105px [row_start] 21px [row1] 429px [row2];
    }
    .energy_foto_2 {
      width: 587px;
      height: 448px;
    }
    .energy_dark_palochka_2 {
      height: 5px;
      margin: 0 122px 0 88px;
    }
    .energy_article_2 {
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 8.4px;
      padding: 20px 100px 0 67px;
    }
    .energy_paragraph_2 {
      padding: 99px 122px 0 134px;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
    }
    .energy_light_3 {
      grid-template-columns: [line1] 49% [line2] 51% [line_end];
      grid-template-rows: 165px [row_start] 37px [row1] 383px [row2];
    }
    .energy_foto_3 {
      width: 627px;
      height: 418px;
    }
    .energy_dark_palochka_3 {
      height: 5px;
      margin: 0px 72px 0 98px;
    }
    .energy_article_3 {
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 8.4px;
      padding: 20px 62px 0 87px;
      max-width: 100%;
    }
    .energy_paragraph_3 {
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
      padding: 35px 95px 0 121px;
    }
    .energy_light_4 {
      grid-template-columns: [line1] 54.140625% [line2] 44.859375% [line_end];
      grid-template-rows: 94px [row_start] 19px [row1] 372px [row2] 138px;
    }
    .energy_foto_4 {
      width: 587px;
      height: 391px;
    }
    .energy_dark_palochka_4 {
      height: 5px;
      margin: 0 122px 0 88px;
    }
    .energy_article_4 {
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 8.4px;
      padding: 20px 100px 0 67px;
    }
    .energy_paragraph_4 {
      padding: 125px 122px 0 134px;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
    }
    .energy_light_palochka_5 {
      margin-top: 66px;
      max-width: 79%;
    }
    .energy_article_5 {
      max-width: 83.5%;
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 8.4px;
    }
    .energy_paragraph_5 {
      text-align: center;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.8px;
      padding: 40px 0 41px 0;
      max-width: 79%;
    }
    .energy_foto_5 {
      margin: 0 auto;
      object-fit: cover;
      // max-width: 48.625%;
      width: 100%;
    }
  }

  @media (min-width: 1920px) {
    .energy_dark_1 {
      height: 1080px;
    }
    .energy_palochka {
      top: 322px;
      left: 253px;
      height: 206px;
      width: 8px;
    }
    .energy_text_chapter {
      top: 330px;
      left: 323px;
      letter-spacing: 32px;
      font-size: 64px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .energy_describe_chapter {
      padding-top: 614px;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      max-width: 83.5%;
    }
    .energy_light_1 {
      grid-template-columns: [line1] 49% [line2] 51% [line_end];
      grid-template-rows: 147px [row_start] 88px [row1] 545px [row2];
    }
    .energy_foto_1 {
      width: 941px;
      height: 627px;
    }
    .energy_dark_palochka_1 {
      height: 8px;
      margin: 0 81px 0 121px;
    }
    .energy_article_1 {
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 14px;
      padding: 20px 91px 0px 131px;
    }
    .energy_paragraph_1 {
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      padding: 20px 63px 0 147px;
    }
    .energy_light_2 {
      grid-template-columns: [line1] 54.11% [line2] 45.89% [line_end];
      grid-template-rows: 258px [row_start] 86px [row1] 596px [row2];
    }
    .energy_foto_2 {
      width: 881px;
      height: 672px;
    }
    .energy_dark_palochka_2 {
      height: 8px;
      margin: 0 150px 0 100px;
    }
    .energy_article_2 {
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 14px;
      padding: 30px 150px 0 100px;
    }
    .energy_paragraph_2 {
      padding: 99px 182px 0 132px;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .energy_light_3 {
      grid-template-columns: [line1] 49% [line2] 51% [line_end];
      grid-template-rows: 185px [row_start] 125px [row1] 530px [row2];
    }
    .energy_foto_3 {
      width: 941px;
      height: 627px;
    }
    .energy_dark_palochka_3 {
      height: 8px;
      margin: 0 81px 0 121px;
    }
    .energy_article_3 {
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 14px;
      padding: 30px 91px 0px 131px;
    }
    .energy_paragraph_3 {
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      padding: 15px 63px 0 147px;
    }
    .energy_light_4 {
      grid-template-columns: [line1] 54.11% [line2] 45.89% [line_end];
      grid-template-rows: 169px [row_start] 58px [row1] 520px [row2] 174px;
    }
    .energy_foto_4 {
      width: 866px;
      height: 578px;
    }
    .energy_dark_palochka_4 {
      height: 8px;
      margin: 0 150px 0 100px;
    }
    .energy_article_4 {
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 14px;
      padding: 30px 150px 0 100px;
    }
    .energy_paragraph_4 {
      padding: 119px 182px 0 132px;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .energy_light_palochka_5 {
      margin-top: 136px;
      max-width: 79%;
      height: 8px;
    }
    .energy_article_5 {
      max-width: 83.5%;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 14px;
      padding-top: 26px;
    }
    .energy_paragraph_5 {
      text-align: center;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      padding: 60px 0 97px 0;
      max-width: 79%;
    }
    .energy_foto_5 {
      margin: 0 auto;
      object-fit: cover;
      width: 100%;
    }
  }
}
