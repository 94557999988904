.engineRoot {
  position: absolute;
  top: 0;
  left: 0;

  .engineer_proj {
    height: auto;
    background-color: black;
  }

  .education {
    height: auto;
    padding-bottom: 79px;
    background-color: black;
  }
  .education_dark_1 {
    display: list-item;
    position: relative;
    margin: auto;
    padding-bottom: 280px;
  }
  .education_palochka {
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: 140px;
    left: 18px;
    width: 3px;
    height: 118px;
    background-color: white;
    border-radius: 4px;
  }
  .education_text_chapter {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    top: 145px;
    left: 45px;
    max-width: 388px;
    margin: 0px;
    color: white;
    letter-spacing: 12.5px;
    font-size: 25px;
    font-family: "Gilroy-Bold", sans-serif;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .education_describe_chapter {
    padding-top: 437px;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 20px;
    font-family: "Gilroy-Regular", sans-serif;
    max-width: 72%;
    display: block;
    margin: 0 auto;
    border: none;
    line-height: 2;
  }
  .education_light_1 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .education_foto_1 {
    display: list-item;
    position: relative;
    display: block;
    max-width: 100%;
    height: auto;
  }
  .education_paragraph_1 {
    padding-top: 36px;
    padding-bottom: 36px;
    max-width: 87%;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    display: block;
    margin: 0 auto;
    line-height: 2;
  }
  .education_dark_2 {
    display: list-item;
    position: relative;
  }
  .education_foto_2 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .education_paragraph_2 {
    padding-top: 54px;
    padding-bottom: 50px;
    display: block;
    max-width: 87%;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    margin: 0 auto;
    line-height: 2;
  }
  .education_dark_3 {
    display: table;
    position: relative;
  }
  .education_foto_3 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .education_paragraph_3 {
    display: block;
    margin: 0 auto;
    max-width: 87%;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    padding-top: 54px;
    padding-bottom: 53px;
    line-height: 2;
  }
  .education_dark_4 {
    display: list-item;
    position: relative;
  }
  .education_foto_4 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .education_paragraph_4 {
    padding-top: 54px;
    padding-bottom: 36px;
    display: block;
    margin: 0 auto;
    max-width: 87%;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    line-height: 2;
  }
  .education_dark_5 {
    display: list-item;
    position: relative;
  }
  .education_foto_5 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .education_paragraph_5 {
    padding-top: 53px;
    display: block;
    margin: 0 auto;
    max-width: 87%;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    line-height: 2;
  }
  .education_dark_6 {
    display: list-item;
    position: relative;
  }
  .education_paragraph_6 {
    padding-top: 40px;
    display: block;
    margin: 0 auto;
    max-width: 87%;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    line-height: 2;
  }

  @media (min-width: 347px) {
    .education_palochka {
      height: 82px;
    }
  }

  @media (min-width: 430px) {
    .education_palochka {
      left: 38px;
    }
    .education_text_chapter {
      left: 66px;
    }
  }

  @media (min-width: 768px) {
    .education {
      overflow: hidden;
    }
    .education_dark_1 {
      height: 600px;
      padding: 0px;
    }
    .education_palochka {
      top: 120px;
      left: 84px;
      width: 5px;
      height: 100px;
    }
    .education_text_chapter {
      top: 122px;
      left: 125px;
      letter-spacing: 13.5px;
      font-size: 27px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .education_describe_chapter {
      padding-top: 306px;
      max-width: 85.5%;
    }
    .education_light_1 {
      display: grid;
      grid-template-columns: [line1] 59.75% [line2] 40.25% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .education_foto_1 {
      width: 690px;
      height: 530px;
      padding-top: 0px;
      grid-column: line2 / line_end;
      align-self: center;
      object-fit: cover;
    }
    .education_paragraph_1 {
      grid-column: line1 / line2;
      grid-row: dark1 / dark2;
      padding: 0px;
      align-self: center;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      padding-left: 48px;
      padding-right: 48px;
    }
    .education_dark_2 {
      display: grid;
      grid-template-columns: [line1] 42.625% [line2] 57.375% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .education_foto_2 {
      width: 613px;
      height: 500px;
      grid-column: line1 / line2;
      padding: 0px;
      align-self: center;
      object-fit: cover;
    }
    .education_paragraph_2 {
      grid-column: line2 / line_end;
      padding: 0px;
      align-self: center;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 100%;
      padding-left: 54px;
      padding-right: 40px;
    }
    .education_dark_3 {
      display: grid;
      grid-template-columns: [line1] 60.125% [line2] 39.875% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .education_foto_3 {
      width: 617px;
      height: 500px;
      padding-bottom: 0px;
      grid-column: line2 / line_end;
      align-self: center;
      object-fit: cover;
    }
    .education_paragraph_3 {
      grid-column: line1 / line2;
      grid-row: dark1 / dark2;
      padding: 0px;
      align-self: center;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 100%;
      padding-left: 60px;
      padding-right: 59px;
    }
    .education_dark_4 {
      display: grid;
      grid-template-columns: [line1] 42.375% [line2] 57.625% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .education_foto_4 {
      width: 617px;
      height: 500px;
      padding-bottom: 0px;
      grid-column: line1 / line2;
      align-self: center;
      object-fit: cover;
    }
    .education_paragraph_4 {
      grid-column: line2 / line_end;
      padding: 0px;
      align-self: center;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 100%;
      padding-left: 48px;
      padding-right: 48px;
    }
    .education_dark_5 {
      display: grid;
      grid-template-columns: [line1] 60.125% [line2] 39.875% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .education_foto_5 {
      width: 378px;
      height: 510px;
      padding-bottom: 0px;
      grid-column: line2 / line2_end;
      align-self: center;
      object-fit: cover;
    }
    .education_paragraph_5 {
      grid-column: line1 / line2;
      grid-row: dark1 / dark2;
      padding: 0px;
      align-self: center;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 100%;
      padding-left: 60px;
      padding-right: 56px;
    }
    .education_paragraph_6 {
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 86.5%;
      padding-top: 40px;
    }
  }

  @media (min-width: 1280px) {
    .education_dark_1 {
      height: 800px;
    }
    .education_palochka {
      top: 214px;
      left: 180px;
      height: 138px;
    }
    .education_text_chapter {
      top: 220px;
      left: 225px;
      max-width: 517px;
      letter-spacing: 20px;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .education_describe_chapter {
      padding-top: 516px;
      font-size: 22px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 87%;
    }
    .education_light_1,
    .education_dark_2,
    .education_dark_3,
    .education_dark_4,
    .education_dark_5 {
      grid-template-columns: [line1] 52.5% [line2] 47.5% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .education_foto_1 {
      width: 923px;
      height: 616px;
    }
    .education_paragraph_1 {
      padding-left: 64px;
      padding-right: 51px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .education_foto_2 {
      width: 884px;
      height: 591px;
    }
    .education_paragraph_2 {
      padding-left: 80px;
      padding-right: 72px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .education_foto_3 {
      width: 672px;
      height: 576px;
    }
    .education_paragraph_3 {
      padding-left: 112px;
      padding-right: 64px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .education_foto_4 {
      width: 920px;
      height: 530px;
    }
    .education_paragraph_4 {
      padding-left: 80px;
      padding-right: 72px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .education_foto_5 {
      width: 717px;
      height: 552px;
    }
    .education_paragraph_5 {
      padding-left: 56px;
      padding-right: 56px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .education_paragraph_6 {
      max-width: 85%;
      padding: 0px;
      text-align: center;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
    }
  }

  @media (min-width: 1920px) {
    .education_dark_1 {
      height: 1080px;
    }
    .education_palochka {
      top: 322px;
      left: 253px;
      height: 206px;
      width: 8px;
    }
    .education_text_chapter {
      top: 332px;
      left: 306px;
      letter-spacing: 32px;
      font-size: 64px;
      font-family: "Gilroy-Bold", sans-serif;
      max-width: 828px;
    }
    .education_describe_chapter {
      padding-top: 607px;
      font-size: 28px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      max-width: 86%;
    }
    .education_light_1,
    .education_dark_2,
    .education_dark_4 {
      grid-template-columns: [line1] 44.85% [line2] 55.15% [line_end];
    }
    .education_dark_3,
    .education_dark_5 {
      grid-template-columns: [line1] 52.4% [line2] 47.6% [line_end];
    }
    .education_light_1 {
      grid-template-rows: [dark1] 980px [dark2];
    }
    .education_dark_2 {
      grid-template-rows: [dark1] 900px [dark2];
    }
    .education_foto_1 {
      width: 1060px;
      height: 916px;
    }
    .education_paragraph_1 {
      padding: 0 82px 0;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .education_foto_2 {
      width: 864px;
      height: 600px;
    }
    .education_paragraph_2 {
      padding: 0 66px 0 142px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .education_foto_3 {
      width: 914px;
      height: 700px;
    }
    .education_paragraph_3 {
      padding: 0 142px 0 82px;
      padding-left: 112px;
      padding-right: 104px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .education_foto_4 {
      width: 864px;
      height: 550px;
    }
    .education_paragraph_4 {
      padding: 0 83px 0 191px;
      padding-left: 80px;
      padding-right: 72px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .education_foto_5 {
      width: 914px;
      height: 806px;
    }
    .education_paragraph_5 {
      padding: 0 142px 0 82px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .education_paragraph_6 {
      max-width: 85%;
      padding-top: 170px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      padding-bottom: 50px;
    }
  }
}
