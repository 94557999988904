.root {
  justify-content: center;
  width: 100vw;
  display: flex;

  textarea {
    width: 81vw;
    height: 231px;
    background-color: black;
    border: 2px solid #ffffff;
    border-radius: 7px;
    margin: 0 auto;
    margin-bottom: 32px;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    letter-spacing: 1.6px;
    color: #ffffff;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;

    @media (min-width: 768px) {
      font-size: 12px;
      letter-spacing: 1.2px;
      width: 40vw;
      margin-left: 2.1vw;
    }
    @media (min-width: 1280px) {
      font-size: 16px;
      letter-spacing: 1.6px;
      width: 43.7vw;
      height: 280px;
      margin-left: 2.3vw;
      margin-bottom: 24px;
    }
    @media (min-width: 1800px) {
      width: 44vw;
    }
    @media (min-width: 1920px) {
      font-size: 24px;
      letter-spacing: 2.4px;
      height: 357px;
      margin-left: 2.5vw;
      margin-bottom: 40px;
    }
  }
}
