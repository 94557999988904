.root {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: black;
  opacity: 1;
  overflow-x: hidden;

  @media (min-width: 768px) {
    height: 70px;
    display: grid;
    grid-template-areas:
      "footerLogo footerMenu info1"
      "footerLogo rights1 info2"
      "footerLogo rights1 info3"
      "footerLogo rights2 info3";
    position: relative;
    grid-template-rows: 25px 12px 12px 14px;
    grid-template-columns: 233px 416px 139px;
    justify-content: space-between;
    width: calc(100vw - 17px);
  }

  @media (min-width: 1280px) {
    height: 75px;
    grid-template-columns: 405px 625px 209px;
  }

  @media (min-width: 1920px) {
    grid-template-columns: 512px 946px 276px;
  }

  .address {
    height: 17px;
    text-transform: uppercase;
    color: #ffffff;
    font-size: 15px;
    letter-spacing: 1.5px;
    font-weight: 600;
    font-family: "Gilroy-Light", sans-serif;
    margin: 0 auto;

    @media (min-width: 768px) {
      grid-area: info1;
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 6px;
      letter-spacing: 0.6px;
      font-weight: 400;
    }

    @media (min-width: 1280px) {
      font-size: 8px;
      letter-spacing: 0.8px;
    }

    @media (min-width: 1920px) {
    }
  }

  .phoneNumber {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 600;
    font-family: "Gilroy-Light", sans-serif;
    margin: 0 auto;
    margin-top: 54px;
    text-decoration: none;

    @media (min-width: 768px) {
      grid-area: info2;
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 6px;
      letter-spacing: 0.6px;
      margin-top: 0px;
      font-weight: 400;
    }
    @media (min-width: 1280px) {
      font-size: 8px;
      letter-spacing: 0.8px;
    }
  }
  .mail {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 600;
    font-family: "Gilroy-Light", sans-serif;
    margin: 0 auto;
    margin-top: 54px;
    text-decoration: none;

    @media (min-width: 768px) {
      grid-area: info3;
      grid-row: 2/4;
      position: absolute;
      top: 0px;
      left: 0px;
      font-size: 6px;
      letter-spacing: 0.6px;
      margin-top: 0px;
      font-weight: 400;
      grid-row-start: 4;
    }
    @media (min-width: 1280px) {
      font-size: 8px;
      letter-spacing: 0.8px;
    }
  }
  .authorRights {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 8px;
    letter-spacing: 1px;
    font-family: "Gilroy-Light", sans-serif;
    margin: 0 auto;
    margin-top: 177px;
    margin-bottom: 10px;
    white-space: pre-wrap;

    @media (min-width: 768px) {
      grid-area: rights1;
      position: absolute;
      left: 105px;
      top: 5px;
      margin: 0;
      font-size: 4px;
      letter-spacing: 0.4px;
      font-weight: 400;
      grid-row: 2/4;
      grid-row-start: 3;
    }
    @media (min-width: 1280px) {
      font-size: 6px;
      letter-spacing: 0.3px;
      left: 140px;
    }
    @media (min-width: 1920px) {
      font-size: 8px;
      letter-spacing: 0.8px;
      left: 250px;
    }
  }

  .authorCondition {
    text-transform: uppercase;
    color: #ffffff;
    font-size: 8px;
    letter-spacing: 0.8px;
    font-family: "Gilroy-Light", sans-serif;
    margin: 0 auto;
    margin-bottom: 42px;

    @media (min-width: 768px) {
      grid-area: rights2;
      position: absolute;
      left: 135px;
      top: 5px;
      margin: 0;
      font-size: 4px;
      letter-spacing: 0.4px;
      font-weight: 400;
    }
    @media (min-width: 1280px) {
      font-size: 6px;
      letter-spacing: 0.3px;
      left: 180px;
    }
    @media (min-width: 1920px) {
      font-size: 8px;
      letter-spacing: 0.8px;
      left: 310px;
    }
  }

  .footerLogo {
    display: none;
    @media (min-width: 768px) {
      display: block;
      grid-area: foooterLogo;
      grid-row: 4 / 4;
      background-image: url("../../img/d8_logo.png");
      width: 38px;
      height: 21px;
      position: absolute;
      left: 32px;
      bottom: 25px;
    }
  }

  .footerMenu {
    display: none;

    @media (min-width: 768px) {
      display: block;
      grid-area: foooterMenu;
      margin-top: 97px;
      text-align: center;
      font-size: 6px;
      letter-spacing: 0.72px;
      font-family: "Gilroy-Light", sans-serif;
      position: absolute;
      left: 0px;
      top: 0px;
      margin: 0;
      grid-column-start: 2;
      grid-column-end: 3;
      white-space: pre-wrap;

      :first-child {
        margin: 0;
      }
    }
    @media (min-width: 1280px) {
      font-size: 8px;
      letter-spacing: 0.96px;
    }
    @media (min-width: 1920px) {
      font-size: 14px;
      letter-spacing: 1.68px;
    }

    .menuItem {
      margin-left: 16px;
      @media (min-width: 768px) {
        text-align: center;
        text-transform: uppercase;
        font-size: 6px;
        letter-spacing: 0.72px;
        color: white;
        font-family: "Gilroy-Light", sans-serif;
        background-color: transparent;
        border: none;
      }
      @media (min-width: 1280px) {
        font-size: 8px;
        letter-spacing: 0.96px;
      }
      @media (min-width: 1920px) {
        margin-left: 24px;
        font-size: 14px;
        letter-spacing: 1.68px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }

  .scrollToTop {
    position: absolute;
    bottom: 45px;
    right: 35px;
    cursor: pointer;
    background-color: transparent;
    border: none;

    .arrowTop {
      width: 39px;
      height: 18px;
    }
    @media (min-width: 768px) {
      display: none;
    }
  }
}
