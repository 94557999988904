.btn {
  width: fit-content;
  box-sizing: border-box;
  padding: 13px;
  min-width: 140px;
  color: white;
  border: unset;
  outline: none;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .1s ease-out;
  font-size: 16px;
  font-weight: 500;
  filter: brightness(1.1);
  letter-spacing: .6px;
  font-family: sans-serif;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 8px 0 rgba(34, 60, 80, 0.5);

  &:hover {
    filter: brightness(1);
  }
}

.blue {
  background: #1870CB;
}

.red {
  background: #EB0014;
}

.pressed {
  filter: brightness(.95) !important;
  box-shadow: 0 1px 8px 0 rgba(34, 60, 80, 0.3) !important;
}