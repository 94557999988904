.engineRoot {
  position: absolute;
  top: 0;
  left: 0;

  .engineer_proj {
    height: auto;
    background-color: black;
  }

  .power {
    height: auto;
    padding-bottom: 79px;
    background-color: black;
  }
  .power_dark_1 {
    display: list-item;
    position: relative;
    margin: auto;
  }
  .power_palochka {
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: 180px;
    left: 28px;
    width: 3px;
    height: 80px;
    background-color: white;
    border-radius: 4px;
  }
  .power_text_chapter {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    top: 180px;
    left: 56px;
    max-width: 388px;
    margin: 0px;
    color: white;
    letter-spacing: 8.5px;
    font-size: 25px;
    font-family: "Gilroy-Bold", sans-serif;
  }
  .power_describe_chapter {
    padding-top: 439px;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    max-width: 72%;
    display: block;
    margin: 0 auto;
    border: none;
    padding-bottom: 192px;
    line-height: 2;
  }
  .power_light_1 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .power_dark_palochka_1 {
    position: relative;
    top: 23px;
    margin: 0 auto;
    max-width: 88.6%;
    height: 3px;
    background-color: black;
    border-radius: 4px;
  }
  .power_article_1 {
    position: relative;
    top: 23px;
    text-align: center;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
    margin: 0 auto;
    max-width: 86.5%;
    letter-spacing: 1.6px;
    padding: 16px 0 21px 0;
  }
  .power_row_1,
  .power_row_2,
  .power_row_3,
  .power_row_4,
  .power_row_5,
  .power_row_6 {
    position: relative;
    top: 23px;
  }
  .power_numeric_1,
  .power_numeric_2,
  .power_numeric_3,
  .power_numeric_4,
  .power_numeric_5,
  .power_numeric_6 {
    display: table-cell;
    letter-spacing: 8.5px;
    text-align: left;
    font-size: 17px;
    font-family: "Gilroy-Bold", sans-serif;
    padding-left: 28px;
    vertical-align: middle;
    width: 54.5px;
  }
  .power_paragraph_1_1,
  .power_paragraph_1_2,
  .power_paragraph_1_3,
  .power_paragraph_1_4,
  .power_paragraph_1_5,
  .power_paragraph_1_6 {
    display: table-cell;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    padding: 8px 16px 8px 37px;
    line-height: 2;
  }
  .power_foto_1 {
    display: list-item;
    position: relative;
    top: 23px;
    display: block;
    max-width: 100%;
    height: auto;
    padding-top: 18.5px;
  }
  .power_light_2 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .power_paragraph_2_1 {
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    letter-spacing: 1.6px;
    max-width: 85.4%;
    margin: 0 auto;
    padding-top: 39px;
    line-height: 2;
  }
  .power_paragraph_2_2 {
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Medium", sans-serif;
    letter-spacing: 1.6px;
    max-width: 85.4%;
    margin: 0 auto;
    padding-top: 42px;
    line-height: 2;
  }
  .power_paragraph_2_3 {
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Medium", sans-serif;
    letter-spacing: 1.6px;
    max-width: 64.5%;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 34px;
    line-height: 2;
  }
  .power_foto_2 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .power_light_3 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .power_paragraph_3_1 {
    padding-top: 45px;
    padding-bottom: 45px;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
    text-align: center;
  }
  .power_foto_3 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .power_paragraph_3_2 {
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    max-width: 85.5%;
    margin: 0 auto;
    padding-top: 65px;
    padding-bottom: 93px;
    line-height: 2;
  }
  .power_dark_4 {
    display: list-item;
    position: relative;
  }
  .power_foto_4 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .power_paragraph_4 {
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    max-width: 85.5%;
    margin: 0 auto;
    padding-top: 25px;
    line-height: 2;
  }

  @media (min-width: 430px) {
    .power_palochka {
      left: 38px;
    }
    .power_text_chapter {
      left: 66px;
    }
  }

  @media (min-width: 768px) {
    .power {
      overflow: hidden;
    }
    .power_dark_1 {
      height: 600px;
    }
    .power_palochka {
      top: 120px;
      left: 84px;
      width: 5px;
      height: 100px;
    }
    .power_text_chapter {
      top: 122px;
      left: 125px;
      letter-spacing: 13.5px;
      font-size: 27px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .power_describe_chapter {
      padding-top: 306px;
      max-width: 85.5%;
      padding-bottom: 0px;
    }
    .power_light_1 {
      display: grid;
      grid-template-columns: [line1] 60.625% [line2] 39.375% [line_end];
      grid-template-rows: [row_start] 190px [row_start1] 45px [row1] 48px [row1_end] 32px [row2] 48px [row2_end] 32px [row3] 40px [row3_end] 32px [row4] 72px [row4_end] 32px [row5] 40px [row5_end] 32px [row6] 96px [row6_end] 61px [row_end];
    }
    .power_dark_palochka_1 {
      grid-column: line1 / line_end;
      grid-row: row_start / row_start1;
      max-width: 100%;
      height: 4px;
      margin-left: 80px;
      margin-right: 80px;
      top: 64px;
    }
    .power_article_1 {
      top: 64px;
      max-width: 67%;
      letter-spacing: 5.6px;
      grid-column: line1 / line_end;
      grid-row: row_start / row_start1;
      font-size: 16px;
      font-family: "Gilroy-Bold", sans-serif;
      padding-bottom: 0px;
    }
    .power_row_1 {
      top: 0px;
      grid-column: line1 / line2;
      grid-row: row1 / row1_end;
    }
    .power_row_2 {
      top: 0px;
      grid-column: line1 / line2;
      grid-row: row2 / row2_end;
    }
    .power_row_3 {
      top: 0px;
      grid-column: line1 / line2;
      grid-row: row3 / row3_end;
    }
    .power_row_4 {
      top: 0px;
      grid-column: line1 / line2;
      grid-row: row4 / row4_end;
    }
    .power_row_5 {
      top: 0px;
      grid-column: line1 / line2;
      grid-row: row5 / row5_end;
    }
    .power_row_6 {
      top: 0px;
      grid-column: line1 / line2;
      grid-row: row6 / row6_end;
    }
    .power_numeric_1,
    .power_numeric_2,
    .power_numeric_3,
    .power_numeric_4,
    .power_numeric_5,
    .power_numeric_6 {
      padding-left: 43px;
      font-size: 17px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 8.5px;
    }
    .power_paragraph_1_1,
    .power_paragraph_1_2,
    .power_paragraph_1_3,
    .power_paragraph_1_4,
    .power_paragraph_1_5,
    .power_paragraph_1_6 {
      padding: 0px 35px 0px 25px;
      vertical-align: middle;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
    }
    .power_foto_1 {
      width: 563px;
      height: 510px;
      top: 0px;
      padding-top: 20px;
      grid-column: line2 / line_end;
      grid-row: row1 / row6_end;
      align-self: center;
      object-fit: cover;
    }
    .power_light_2 {
      display: grid;
      grid-template-columns: [line1] 46% [line2] 54% [line_end];
      grid-template-rows: [row1] 85px [row1_end] 26px [row2] 68px [row2_end] 20px [row3] 201px [row3_end];
    }
    .power_foto_2 {
      width: 500px;
      height: 400px;
      grid-column: line1 / line2;
      grid-row: row1 / row3_end;
      padding: 0px;
      object-fit: cover;
      justify-self: center;
      align-self: center;
    }
    .power_paragraph_2_1 {
      grid-column: line2 / line_end;
      grid-row: row1 / row1_end;
      padding: 0 32px 0 64px;
      max-width: 100%;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
    }
    .power_paragraph_2_2 {
      grid-column: line2 / line_end;
      grid-row: row2 / row2_end;
      padding: 10px 32px 0 64px;
      max-width: 100%;
    }
    .power_paragraph_2_3 {
      grid-column: line2 / line_end;
      grid-row: row3 / row3_end;
      padding: 20px 133px 0 72px;
      vertical-align: middle;
      max-width: 100%;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.4px;
    }
    .power_paragraph_3_1 {
      padding-top: 95px;
      font-size: 18px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
    }
    .power_paragraph_3_2 {
      padding-top: 64px;
      padding-bottom: 20px;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
    }
    .power_foto_3 {
      max-width: 92%;
      margin: 0 auto;
    }
    .power_dark_4 {
      display: grid;
      grid-template-columns: [line1] 55.625% [line2] 44.375% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .power_foto_4 {
      width: 650px;
      height: 470px;
      padding: 0px;
      grid-column: line2 / line_end;
      align-self: center;
      object-fit: cover;
    }
    .power_paragraph_4 {
      grid-column: line1 / line2;
      grid-row: dark1 / dark2;
      padding-top: 0px;
      padding-bottom: 0px;
      align-self: center;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.2px;
    }
  }

  @media (min-width: 1024px) {
    .power_light_1 {
      grid-template-rows: [row_start] 190px [row_start1] 45px [row1] 40px [row1_end] 32px [row2] 40px [row2_end] 32px [row3] 40px [row3_end] 32px [row4] 48px [row4_end] 32px [row5] 40px [row5_end] 32px [row6] 72px [row6_end] 61px [row_end];
    }
  }

  @media (min-width: 1150px) {
    .power_light_1 {
      grid-template-rows: [row_start] 190px [row_start1] 45px [row1] 40px [row1_end] 32px [row2] 40px [row2_end] 32px [row3] 40px [row3_end] 32px [row4] 48px [row4_end] 32px [row5] 40px [row5_end] 32px [row6] 48px [row6_end] 61px [row_end];
    }
  }

  @media (min-width: 1280px) {
    .power_dark_1 {
      height: 800px;
    }
    .power_palochka {
      top: 214px;
      left: 180px;
      height: 138px;
      width: 5px;
    }
    .power_text_chapter {
      top: 222px;
      left: 225px;
      letter-spacing: 20px;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .power_describe_chapter {
      padding-top: 480px;
      font-size: 20px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 85.5%;
    }
    .power_light_1 {
      grid-template-columns: [line1] 52.58% [line2] 47.42% [line_end];
      grid-template-rows: [row_start] 190px [row_start1] 65px [row1] 88px [row1_end] 12px [row2] 88px [row2_end] 12px [row3] 58px [row3_end] 12px [row4] 116px [row4_end] 12px [row5] 58px [row5_end] 12px [row6] 166px [row6_end] 85px [row_end];
    }
    .power_dark_palochka_1 {
      margin-left: 155px;
      margin-right: 155px;
    }
    .power_article_1 {
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 2.4px;
    }
    .power_numeric_1,
    .power_numeric_2,
    .power_numeric_3,
    .power_numeric_4,
    .power_numeric_5,
    .power_numeric_6 {
      padding-left: 71px;
      letter-spacing: 12.5px;
      font-size: 25px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .power_paragraph_1_1,
    .power_paragraph_1_2,
    .power_paragraph_1_3,
    .power_paragraph_1_4,
    .power_paragraph_1_5,
    .power_paragraph_1_6 {
      padding-left: 30px;
      padding-right: 45px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
    }
    .power_foto_1 {
      width: 662px;
      height: 650px;
      justify-self: center;
    }
    .power_light_2 {
      grid-template-columns: [line1] 49.063% [line2] 50.937% [line_end];
      grid-template-rows: 71px [row1] 112px [row1_end] 27px [row2] 92px [row2_end] 36px [row3] 232px [row3_end];
    }
    .power_paragraph_2_1 {
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
    }
    .power_paragraph_2_2 {
      font-size: 22px;
      font-family: "Gilroy-Medium", sans-serif;
      letter-spacing: 2px;
    }
    .power_paragraph_2_3 {
      font-size: 22px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2px;
      padding-left: 76px;
      padding-right: 137px;
      align-self: end;
    }
    .power_foto_2 {
      width: 653px;
      height: 490px;
      justify-self: center;
    }
    .power_paragraph_3_1 {
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 3.6px;
      padding-bottom: 56px;
    }
    .power_paragraph_3_2 {
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
      max-width: 87.5%;
      padding: 80px 0 80px 0;
    }
    .power_dark_4 {
      grid-template-columns: [line1]53.515% [line2] 46.485% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .power_foto_4 {
      width: 847px;
      height: 565px;
      justify-self: center;
    }
    .power_paragraph_4 {
      padding-left: 54px;
      padding-right: 54px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
    }
  }

  @media (min-width: 1920px) {
    .power_dark_1 {
      height: 1080px;
    }
    .power_palochka {
      top: 322px;
      left: 253px;
      height: 206px;
      width: 8px;
    }
    .power_text_chapter {
      top: 332px;
      left: 306px;
      letter-spacing: 32px;
      font-size: 64px;
      font-family: "Gilroy-Bold", sans-serif;
      max-width: 828px;
    }
    .power_describe_chapter {
      padding-top: 595px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      max-width: 83.5%;
    }
    .power_light_1 {
      grid-template-columns: [line1] 52.82% [line2] 47.18% [line_end];
      grid-template-rows: [row_start] 240px [row_start1] 150px [row1] 73px [row1_end] 20px [row2] 73px [row2_end] 20px [row3] 73px [row3_end] 20px [row4] 153px [row4_end] 20px [row5] 73px [row5_end] 20px [row6] 190px [row6_end] 85px [row_end];
    }
    .power_dark_palochka_1 {
      margin-left: 220px;
      margin-right: 220px;
      height: 8px;
      top: 88px;
    }
    .power_article_1 {
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 4px;
      max-width: 75%;
      top: 88px;
    }
    .power_numeric_1,
    .power_numeric_2,
    .power_numeric_3,
    .power_numeric_4,
    .power_numeric_5,
    .power_numeric_6 {
      padding-left: 109px;
      letter-spacing: 20x;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .power_paragraph_1_1,
    .power_paragraph_1_2,
    .power_paragraph_1_3,
    .power_paragraph_1_4,
    .power_paragraph_1_5,
    .power_paragraph_1_6 {
      padding: 0 66px 0 47px;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2px;
    }
    .power_foto_1 {
      width: 906px;
      height: 679px;
    }
    .power_light_2 {
      grid-template-columns: [line1] 45.73% [line2] 54.27% [line_end];
      grid-template-rows: 90px [row1] 132px [row1_end] 42px [row2] 82px [row2_end] 26px [row3] 388px [row3_end];
    }
    .power_paragraph_2_1 {
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2px;
      padding: 0 64px 0 158px;
      align-self: center;
    }
    .power_paragraph_2_2 {
      font-size: 24px;
      font-family: "Gilroy-Medium", sans-serif;
      letter-spacing: 2px;
      padding: 0 64px 0 158px;
      align-self: center;
    }
    .power_paragraph_2_3 {
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2px;
      padding: 0 257px 0 76px;
      align-self: start;
    }
    .power_foto_2 {
      width: 878px;
      height: 659px;
    }
    .power_paragraph_3_1 {
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;  
      letter-spacing: 4px;
      padding: 190px 0 26px;
    }
    .power_foto_3 {
      max-width: 94%;
    }
    .power_paragraph_3_2 {
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2px;
      max-width: 83%;
      padding: 36px 0 110px 0;
    }
    .power_dark_4 {
      grid-template-columns: [line1] 50% [line2] 50% [line_end];
      grid-template-rows: [dark1] 1314px [dark2];
    }
    .power_foto_4 {
      width: 960px;
      height: 874px;
    }
    .power_paragraph_4 {
      padding: 0 75px 0 100px;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
  }
}
