.root {
  width: 100vw;
  // position: absolute;
  // top: 2850px;
  // left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: black;
  opacity: 1;
  overflow-x: hidden;

  .titleGeneral {
    display: grid;
    grid-template-areas:
      "title"
      "subTitle";
    column-gap: 8vw;

    @media (min-width: 768px) {
      grid-template-areas: "title subTitle";
    }
    @media (min-width: 1100px) {
      column-gap: 15vw;
    }
    @media (min-width: 1280px) {
      column-gap: 4vw;
    }
    @media (min-width: 1500px) {
      column-gap: 14vw;
    }
    @media (min-width: 1920px) {
      column-gap: 2vw;
    }
    @media (min-width: 3000px) {
      column-gap: 12vw;
    }

    .title {
      width: 320px;
      height: 30px;
      text-align: center;
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 8.4px;
      color: #ffffff;
      text-transform: uppercase;
      opacity: 0.2;
      margin: 0 auto;
      margin-bottom: 31px;
      grid-area: title;
      white-space: pre-wrap;

      @media (min-width: 768px) {
        width: auto;
        font-size: 21px;
        letter-spacing: 10.5px;
        margin-bottom: 0px;
        margin-top: 8px;
      }
      @media (min-width: 1280px) {
        font-size: 35px;
        letter-spacing: 17.5px;
      }
      @media (min-width: 1920px) {
        font-size: 55px;
        letter-spacing: 27.5px;
      }
    }

    .subTitle {
      height: 21px;
      font-size: 17px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 8.5px;
      color: #ffffff;
      text-transform: uppercase;
      padding-bottom: 29px;
      margin-bottom: 39px;
      margin-left: 0px;
      grid-area: subTitle;

      @media (min-width: 768px) {
        font-size: 14px;
        letter-spacing: 7px;
      }
      @media (min-width: 1280px) {
        font-size: 24px;
        letter-spacing: 12px;
        padding-bottom: 39px;
        margin-bottom: 50px;
      }
      @media (min-width: 1920px) {
        font-size: 40px;
        letter-spacing: 20px;
        padding-bottom: 85px;
        margin-bottom: 62px;
      }

      .subTitle_first {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 6px;

        @media (min-width: 768px) {
          margin-bottom: 10px;
        }
        @media (min-width: 1280px) {
          margin-bottom: 12px;
        }
        @media (min-width: 1920px) {
          margin-bottom: 9px;
        }
      }
      .subTitle_second {
        text-align: center;
        white-space: pre-wrap;

        @media (min-width: 387px) {
          text-align: left;
        }
       
      }
    }
  }
  .prpsBtn {
    width: 100vw;
    height: 1043px;
    background-image: url("../../img/d8_site_offer_back_app.png");
    background-size: cover;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-areas:
      "btn1Prs"
      "btn2Prs"
      "btn3Prs"
      "btn4Prs"
      "btn5Prs"
      "btn6Prs"
      "btn7Prs"
      "btn8Prs";

    @media (min-width: 768px) {
      height: 345px;
      background-image: url("../../img/d8_site_offer_back.png");
      grid-template-areas:
        "btn1Prs btn5Prs"
        "btn2Prs btn6Prs"
        "btn3Prs btn7Prs"
        "btn4Prs btn8Prs";
    }


    @media (min-width: 1280px) {
      height: 542px;
      column-gap: 0px;
    }

    @media (min-width: 1920px) {
      height: 823px;
      column-gap: 0px;
    }

    .btn1Prps {
      grid-area: btn1Prps;
    }
    .btn2Prps {
      grid-area: btn2Prps;
    }
    .btn3Prps {
      grid-area: btn3Prps;
    }
    .btn4Prps {
      grid-area: btn4Prps;
    }
    .btn5Prps {
      grid-area: btn5Prps;
    }
    .btn6Prps {
      grid-area: btn6Prps;
    }
    .btn7Prps {
      grid-area: btn7Prps;
    }
    .btn8Prps {
      grid-area: btn8Prps;
    }
  }
}
