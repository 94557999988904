.root {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: black;
  opacity: 1;
  overflow-x: hidden;

  .titleGeneral {
    margin-left: 15px;
    display: grid;
    grid-template-areas:
      "title"
      "subTitle";

    @media (min-width: 768px) {
      grid-template-areas: "subTitle title";
      column-gap: 4vw;
    }
    @media (min-width: 1000px) {
      column-gap: 10vw;
    }

    .title {
      width: 410px;
      text-align: center;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 20px;
      color: #ffffff;
      text-transform: uppercase;
      opacity: 0.2;
      margin: 0 auto;
      margin-top: 80px;
      margin-bottom: 25px;
      grid-area: title;

      @media (min-width: 768px) {
        width: auto;
        font-size: 41px;
        letter-spacing: 20.5px;
        margin-bottom: 50px;
        margin-top: 115px;
        margin-left: 12px;
      }
      @media (min-width: 1280px) {
        height: 82px;
        font-size: 46px;
        letter-spacing: 33px;
        margin-top: 194px;
      }
      @media (min-width: 1920px) {
        height: 125px;
        font-size: 91px;
        letter-spacing: 50.5px;
        margin-top: 263px;
      }
    }

    .subTitle {
      height: 28px;
      text-align: center;
      font-size: 22px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 11px;
      color: #ffffff;
      text-transform: uppercase;
      margin: 0 auto;
      margin-bottom: 50px;
      grid-area: subTitle;

      @media (min-width: 768px) {
        font-size: 18px;
        letter-spacing: 9px;
        margin-top: 128px;
        margin-left: 44px;
      }
      @media (min-width: 1280px) {
        font-size: 24px;
        letter-spacing: 12px;
        margin-top: 220px;
        margin-left: 67px;
      }
      @media (min-width: 1920px) {
        height: 49px;
        font-size: 40px;
        letter-spacing: 20px;
        margin-top: 300px;
        margin-left: 100px;
      }

      .subTitle_first {
        text-align: center;
        margin: 0 auto;
        margin-bottom: 6px;

        @media (min-width: 768px) {
          margin-bottom: 10px;
        }
        @media (min-width: 1280px) {
          margin-bottom: 12px;
        }
        @media (min-width: 1920px) {
          margin-bottom: 9px;
        }
      }
      .subTitle_second {
        text-align: center;

        @media (min-width: 387px) {
          text-align: left;
        }
      }
    }
  }

  .carouselVideo {
    width: 100vw;
    padding-bottom: 70px;
    margin-bottom: 50px;
  }
}
