.root {
  width: 100vw;
  height: auto;
  display: grid;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: black;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 1;
  overflow-x: hidden;
  grid-template-areas:
    "headerImg"
    "fbForm";

  @media (min-width: 768px) {
    grid-template-areas: "headerImg fbForm";
    grid-template-columns: 40vw 48vw;
    grid-column-gap: 7vw;
  }

  @media (min-width: 1280px) {
    grid-column-gap: 3vw;
    grid-template-columns: 45vw 51vw;
  }

  .headerImg {
    margin-top: 74px;
    width: 100vw;
    height: 295px;
    background-image: url("../../img/d8_feedbakcform_pic4.jpg");
    background-size: cover;
    grid-area: headerImg;

    @media (min-width: 768px) {
      width: 40vw;
      height: 100vh;
      margin-top: 74px;
      background-image: url("../../img/laura-chouette-ezkLuQEY-Jo-unsplash.png");
    }
    @media (min-width: 1280px) {
      width: 45vw;
      height: 784px;
    }
    @media (min-width: 1920px) {
      height: 1200px;
    }
  }

  .fbForm {
    height: 808px;
    width: 100vw;
    background-color: black;
    margin-bottom: 91px;
    grid-area: fbForm;

    @media (min-width: 768px) {
      margin-top: 74px;
      margin-bottom: 65px;
      width: 48vw;
      height: 655px;
    }
    @media (min-width: 1280px) {
      width: 51vw;
      height: 800px;
    }
    @media (min-width: 1920px) {
      height: 1220px;
    }

    .fbForm_title {
      height: auto;
      text-align: center;
      font-size: 16px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 3.2px;
      color: #ffffff;
      text-transform: uppercase;
      opacity: 1;
      margin: 0 auto;
      margin-top: 32px;
      margin-bottom: 24px;

      @media (min-width: 768px) {
        margin-bottom: 16px;
        margin-top: 0px;
      }
      @media (min-width: 1280px) {
        font-size: 24px;
        letter-spacing: 4.8px;
        margin-top: 71px;
      }
      @media (min-width: 1920px) {
        font-size: 32px;
        letter-spacing: 6.4px;
        margin-top: 111px;
        margin-bottom: 24px;
      }
    }

    hr {
      display: flex;
      width: 85vw;
      border: 2px solid #ffffff;
      opacity: 1;
      margin: 0 auto;
      border-radius: 25px;
      margin-bottom: 28px;

      @media (min-width: 768px) {
        width: 48vw;
      }
      @media (min-width: 1280px) {
        margin-bottom: 37px;
      }
      @media (min-width: 1920px) {
        margin-bottom: 56px;
      }
    }

    button {
      width: 153px;
      height: 51px;
      margin-left: 33vw;
      font-size: 16px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 1.6px;
      color: #ffffff;
      opacity: 0.5;
      text-align: center;
      background-color: #131112;
      border-radius: 31px;
      border: none;

      @media (min-width: 580px) {
        margin-left: 38vw;
      }
      @media (min-width: 768px) {
        margin-left: 17vw;
        width: 96px;
        height: 40px;
        font-size: 10px;
        letter-spacing: 1px;
      }
      @media (min-width: 1280px) {
        margin-left: 20vw;
        width: 152px;
        height: 56px;
        font-size: 16px;
        letter-spacing: 1.6px;
      }
      @media (min-width: 1920px) {
        margin-left: 20vw;
        width: 224px;
        height: 80px;
        font-size: 24px;
        letter-spacing: 2.4px;
      }

      &:hover {
        filter: brightness(1);
        color: black;
        background-color: white;
        cursor: pointer;
      }
    }
  }
}
