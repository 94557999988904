.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: sticky;

  @media (min-width: 768px) {
    height: 94px;
    width: calc(100vw - 17px);
  }

  .headerLine {
    padding: 0;
    width: 100vw;
    height: 72px;
    background: #000000;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    font-family: sans-serif;
    opacity: 0.6;

    
  @media (min-width: 768px) {
    width: calc(100vw - 22px);
  }
  }

  .menuBtn {
    top: 5px;
    left: 24px;
    width: 60px;
    height: 60px;
    position: fixed;
    z-index: 0;
    cursor: pointer;

    @media (min-width: 768px) {
      left: 24px;
    }
    @media (min-width: 1280px) {
      left: 44px;
    }

    .lineFirst {
      position: absolute;
      top: 10px;
      width: 36px;
      height: 0px;
      border: 3px solid #ffffff;
      opacity: 1;
      z-index: 1;
      border-radius: 6px;

      @media (min-width: 768px) {
        top: 15px;
      }
      @media (min-width: 1280px) {
        top: 16px;
      }
    }

    .lineSecond {
      position: absolute;
      top: 23px;
      width: 36px;
      height: 0px;
      border: 3px solid #ffffff;
      opacity: 1;
      z-index: 1;
      border-radius: 6px;

      @media (min-width: 768px) {
        top: 26px;
      }
      @media (min-width: 1280px) {
        top: 27px;
      }
    }

    .lineThird {
      position: absolute;
      top: 36px;
      width: 36px;
      height: 0px;
      border: 3px solid #ffffff;
      opacity: 1;
      z-index: 1;
      border-radius: 6px;

      @media (min-width: 768px) {
        top: 37px;
      }
      @media (min-width: 1280px) {
        top: 38px;
      }
    }
  }

  .menuLanguage {
    position: fixed;
    top: 24px;
    right: 38px;

    @media (min-width: 768px) {
      top: 25px;
      right: 42px;
    }

    @media (min-width: 1280px) {
      right: 70px;
      top: 23px;
    }
    @media (min-width: 1920px) {
      top: 25px;
      right: 170px;
    }

    .menuLanguage_btn {
      border: none;
      background-color: transparent;
      cursor: pointer;
      width: 33px;
      height: 29px;
      text-align: left;
      font-size: 24px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      color: #ffffff;
      text-transform: uppercase;
      opacity: 1;

      @media (min-width: 768px) {
        width: 33px;
      }
      @media (min-width: 1280px) {
        font-size: 28px;
      }
      @media (min-width: 1920px) {
        font-size: 32px;
        width: 45px;
      }
    }
  }
}
