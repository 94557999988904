.engineRoot {
  position: absolute;
  top: 0;
  left: 0;

  .engineer_proj {
    height: auto;
    background-color: black;
  }

  .cinema {
    height: auto;
    padding-bottom: 79px;
    background-color: black;
  }
  .cinema_dark_1 {
    display: list-item;
    position: relative;
    margin: auto;
    padding-bottom: 100px;
  }
  .cinema_palochka {
    display: inline-block;
    position: relative;
    vertical-align: top;
    top: 140px;
    left: 18px;
    width: 3px;
    height: 48px;
    background-color: white;
    border-radius: 4px;
  }
  .cinema_text_chapter {
    display: inline-block;
    position: absolute;
    vertical-align: top;
    top: 146px;
    left: 45px;
    max-width: 388px;
    margin: 0px;
    color: white;
    letter-spacing: 12.5px;
    font-size: 25px;
    font-family: "Gilroy-Bold", sans-serif;
    align-items: center;
    justify-content: center;
    align-content: center;
  }
  .cinema_describe_chapter {
    padding-top: 197px;
    color: white;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    max-width: 72%;
    display: block;
    margin: 0 auto;
    border: none;
    line-height: 2;
  }
  .cinema_light_1 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .cinema_foto_1 {
    display: list-item;
    position: relative;
    display: block;
    max-width: 100%;
    height: auto;
  }
  .cinema_dark_palochka_1 {
    margin: 0 auto;
    margin-top: 48px;
    height: 3px;
    max-width: 81%;
    background-color: black;
    border-radius: 4px;
  }
  .cinema_article_1 {
    margin: 0 auto;
    padding-top: 10px;
    text-align: center;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 1.6px;
    max-width: 85%;
  }
  .cinema_paragraph_1 {
    padding-top: 41px;
    padding-bottom: 75px;
    max-width: 80%;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    display: block;
    margin: 0 auto;
    line-height: 2;
  }
  .cinema_light_2 {
    display: list-item;
    position: relative;
    background-color: white;
  }
  .cinema_foto_2 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .cinema_dark_palochka_2 {
    margin: 0 auto;
    margin-top: 56px;
    height: 3px;
    max-width: 81%;
    background-color: black;
    border-radius: 4px;
  }
  .cinema_article_2 {
    padding-top: 10px;
    text-align: center;
    font-size: 16px;
    font-family: "Gilroy-Bold", sans-serif;
    letter-spacing: 1.6px;
  }
  .cinema_paragraph_2 {
    padding-top: 41px;
    padding-bottom: 56px;
    display: block;
    max-width: 80%;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    margin: 0 auto;
    line-height: 2;
  }
  .cinema_light_3 {
    display: table;
    position: relative;
    background-color: white;
  }
  .cinema_foto_3 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .cinema_paragraph_3 {
    display: block;
    margin: 0 auto;
    max-width: 80%;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    padding-top: 56px;
    padding-bottom: 76px;
    line-height: 2;
  }
  .cinema_light_4 {
    display: table;
    position: relative;
    background-color: white;
  }
  .cinema_foto_4 {
    display: list-item;
    position: relative;
    max-width: 100%;
    height: auto;
  }
  .cinema_paragraph_4 {
    padding-bottom: 74px;
    display: block;
    margin: 0 auto;
    max-width: 80%;
    letter-spacing: 1.6px;
    text-align: left;
    font-size: 16px;
    font-family: "Gilroy-Regular", sans-serif;
    line-height: 2;
  }

  @media (min-width: 430px) {
    .cinema_palochka {
      left: 69px;
    }
    .cinema_text_chapter {
      left: 95px;
    }
  }

  @media (min-width: 768px) {
    .cinema {
      overflow: hidden;
    }
    .cinema_dark_1 {
      height: 600px;
      padding: 0px;
    }
    .cinema_palochka {
      top: 120px;
      left: 81px;
      width: 5px;
      height: 50px;
    }
    .cinema_text_chapter {
      top: 122px;
      left: 113px;
      letter-spacing: 13.5px;
      font-size: 27px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .cinema_describe_chapter {
      padding-top: 250px;
      max-width: 85.5%;
      font-size: 12px;
    }
    .cinema_light_1 {
      display: grid;
      grid-template-columns: [line1] 42.375% [line2] 57.625% [line_end];
      grid-template-rows: [row_start] 56px [row1] 90px [row1_end] 12px [row2] 336px [row2_end] 56px [row_end];
    }
    .cinema_foto_1 {
      width: 580px;
      height: 488px;
      padding-top: 0px;
      grid-column: line1 / line2;
      grid-row: row1 / row2_end;
      align-self: center;
      object-fit: cover;
      object-position: -240px;
    }
    .cinema_dark_palochka_1 {
      grid-column: line2 / line_end;
      grid-row: row1 / row1_end;
      width: 296px;
      height: 4px;
      margin-top: 0px;
    }
    .cinema_article_1 {
      grid-column: line2 / line_end;
      grid-row: row1 / row1_end;
      font-size: 18px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
      max-width: 100%;
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 24px;
    }
    .cinema_paragraph_1 {
      grid-column: line2 / line_end;
      grid-row: row2 / row2_end;
      padding: 0px;
      align-self: center;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      padding-left: 51px;
      padding-right: 39px;
    }
    .cinema_light_2 {
      display: grid;
      grid-template-columns: [line1] 55.75% [line2] 44.25% [line_end];
      grid-template-rows: [row_start] 113px [row1] 66px [row1_end] 300px [row2_end] 113px [row_end];
    }
    .cinema_foto_2 {
      width: 328px;
      height: 400px;
      grid-column: line2 / line_end;
      grid-row: row1 / row2_end;
      padding-top: 20px;
      justify-self: center;
      align-self: center;
    }
    .cinema_dark_palochka_2 {
      grid-column: line1 / line2;
      grid-row: row1 / row1_end;
      width: 336px;
      height: 4px;
      margin-top: 0px;
    }
    .cinema_article_2 {
      grid-column: line1 / line2;
      grid-row: row1 / row1_end;
      font-size: 18px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
      max-width: 100%;
      padding-top: 20px;
    }
    .cinema_paragraph_2 {
      grid-column: line1 / line2;
      grid-row: row1_end / row2_end;
      padding: 0px;
      align-self: center;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 100%;
      padding-left: 41px;
      padding-right: 39px;
      padding-top: 20px;
    }
    .cinema_light_3 {
      display: grid;
      grid-template-columns: [line1] 42.375% [line2] 57.625% [line_end];
      grid-template-rows: [dark1] 600px [dark2];
    }
    .cinema_foto_3 {
      width: 698px;
      height: 392px;
      padding-bottom: 0px;
      grid-column: line1 / line2;
      align-self: center;
      object-fit: cover;
      object-position: -260px;
    }
    .cinema_paragraph_3 {
      grid-column: line2 / line_end;
      grid-row: dark1 / dark2;
      padding: 0px;
      align-self: center;
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 100%;
      padding-left: 44px;
      padding-right: 46px;
    }
    .cinema_paragraph_4 {
      font-size: 14px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 89%;
      padding-bottom: 110px;
    }
  }

  @media (min-width: 1054px) {
    .cinema_foto_3 {
      object-position: -190px;
    }
  }
  @media (min-width: 1280px) {
    .cinema_dark_1 {
      height: 800px;
    }
    .cinema_palochka {
      top: 256px;
      left: 180px;
      height: 73px;
      width: 5px;
    }
    .cinema_text_chapter {
      top: 262px;
      left: 225px;
      max-width: 467px;
      letter-spacing: 20px;
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
    }
    .cinema_describe_chapter {
      padding-top: 447px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
      max-width: 81.5%;
    }
    .cinema_light_1 {
      grid-template-columns: [line1] 50% [line2] 50% [line_end];
      grid-template-rows: [row_start] 120px [row1] 116px [row1_end] 44px [row2] 400px [row2_end] 120px [row_end];
    }
    .cinema_foto_1 {
      width: 832px;
      height: 560px;
      justify-self: center;
      object-position: -160px;
      grid-row: row1 / row2_end;
    }
    .cinema_dark_palochka_1 {
      grid-column: line2 / line_end;
      grid-row: row1 / row1_end;
      width: 560px;
      height: 5px;
      margin-top: 15px;
    }
    .cinema_article_1 {
      grid-column: line2 / line_end;
      grid-row: row1 / row1_end;
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
      max-width: 100%;
      padding-left: 60px;
      padding-right: 60px;
      padding-top: 34px;
    }
    .cinema_paragraph_1 {
      padding-left: 64px;
      padding-right: 48px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .cinema_light_2 {
      grid-template-columns: [line1] 53.75% [line2] 46.25% [line_end];
      grid-template-rows: [row_start] 99px [row1] 152px [row1_end] 450px [row2_end] 99px [row_end];
    }
    .cinema_foto_2 {
      width: 528px;
      height: 602px;
      justify-self: center;
    }
    .cinema_dark_palochka_2 {
      grid-column: line1 / line2;
      grid-row: row1 / row1_end;
      width: 576px;
      height: 5px;
      margin-top: 30px;
    }
    .cinema_article_2 {
      grid-column: line1 / line2;
      grid-row: row1 / row1_end;
      font-size: 24px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 6.3px;
      max-width: 100%;
      padding-top: 58px;
    }
    .cinema_paragraph_2 {
      padding-left: 72px;
      padding-right: 40px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
      align-self: start;
    }
    .cinema_light_3 {
      grid-template-columns: [line1] 50% [line2] 50% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .cinema_foto_3 {
      width: 840px;
      height: 472px;
    }
    .cinema_paragraph_3 {
      padding-left: 65px;
      padding-right: 88px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
    }
    .cinema_light_4 {
      height: 324px;
    }
    .cinema_paragraph_4 {
      padding-left: 48px;
      padding-right: 48px;
      font-size: 18px;
      font-family: "Gilroy-Regular", sans-serif;
      padding-bottom: 0px;
      padding-top: 90px;
    }
  }
  @media (min-width: 1400px) {
    .cinema_dark_palochka_1 {
      width: 100vw;
    }
  }
  @media (min-width: 1920px) {
    .cinema_dark_1 {
      height: 1080px;
    }
    .cinema_palochka {
      top: 322px;
      left: 253px;
      height: 93px;
      width: 8px;
    }
    .cinema_text_chapter {
      top: 322px;
      left: 306px;
      letter-spacing: 32px;
      font-size: 64px;
      font-family: "Gilroy-Bold", sans-serif;
      max-width: 747px;
    }
    .cinema_describe_chapter {
      padding-top: 560px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      max-width: 83.5%;
    }
    .cinema_light_1 {
      grid-template-columns: [line1] 48.23% [line2] 51.77% [line_end];
      grid-template-rows: [row_start] 128px [row1] 180px [row1_end] 0px [row2] 490px [row2_end] 120px [row_end];
    }
    .cinema_foto_1 {
      width: 1080px;
      height: 720px;
      object-position: -160px;
    }
    .cinema_dark_palochka_1 {
      width: 100vw;
      height: 8px;
    }
    .cinema_article_1 {
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 14px;
      padding: 44px 60px 0px;
    }
    .cinema_paragraph_1 {
      padding: 0 110px 0 114px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .cinema_light_2 {
      grid-template-columns: [line1] 56.66% [line2] 43.34% [line_end];
      grid-template-rows: [row_start] 165px [row1] 152px [row1_end] 750px [row2_end] 103px [row_end];
    }
    .cinema_foto_2 {
      width: 787px;
      height: 897px;
    }
    .cinema_dark_palochka_2 {
      margin-top: 80px;
      width: 725px;
      height: 8px;
    }
    .cinema_article_2 {
      font-size: 40px;
      font-family: "Gilroy-Bold", sans-serif;
      letter-spacing: 14px;
      padding: 108px 151px 0 148px;
    }
    .cinema_paragraph_2 {
      padding: 0 128px 0 191px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
      align-self: center;
    }
    .cinema_light_3 {
      grid-template-columns: [line1] 48.23% [line2] 51.67% [line_end];
      grid-template-rows: [dark1] 800px [dark2];
    }
    .cinema_foto_3 {
      width: 1030px;
      height: 578px;
      object-position: -100px;
    }
    .cinema_paragraph_3 {
      padding: 0 110px 0 114px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
      letter-spacing: 2.4px;
    }
    .cinema_light_4 {
      height: auto;
    }
    .cinema_paragraph_4 {
      padding: 0px 150px 110px;
      font-size: 26px;
      font-family: "Gilroy-Regular", sans-serif;
    }
  }
}
